<template>
	<form @submit.prevent="submitForm" class="">
		<h1 class="page_title h2">Review Your Selections</h1>
		<div class="d-flex justify-content-end mb-3">
			<button id="print_benefit_selection_button" class="btn btn-outline-secondary btn-sm" @click="printSelection()" :disabled="selectionButtonDisable"><i class="fa fa-print"></i>&nbsp;Print Benefit Selection</button>
		</div>
		<div>
			<div v-if="medicalPlan" class="card mt-4">
				<div class="plan t_all" :price="medicalPlan.rate" :name="medicalPlan.name">
					<div class="card-header d-flex justify-content-between align-items-center">
						<h2 class="h4 col-24 m-0" v-html="medicalPlan.name"></h2>
					</div>
					<div class="card-body">
						<medical-plan :plan="medicalPlan" :allowSelect="false" :allowChange="true" />
					</div>
					<plan-footer :plan="medicalPlan" :showRx="true" />
				</div>
			</div>
			<div v-if="dentalPlan" class="card mt-4">
				<div class="plan t_all" :price="dentalPlan.rate" :name="dentalPlan.name">
					<div class="card-header d-flex justify-content-between align-items-center">
						<h2 class="h4 col-24 m-0" v-html="dentalPlan.name"></h2>
					</div>
					<div class="card-body">
						<dental-plan :plan="dentalPlan" :allowSelect="false" :allowChange="true" />
					</div>
					<plan-footer :plan="dentalPlan" :showRx="false" />
				</div>
			</div>
			<div v-if="visionPlan" class="card mt-4">
				<div class="plan t_all" :price="visionPlan.rate" :name="visionPlan.name">
					<div class="card-header d-flex justify-content-between align-items-center">
						<h2 class="h4 col-24 m-0" v-html="visionPlan.name"></h2>
					</div>
					<div class="card-body">
						<vision-plan :plan="visionPlan" :allowSelect="false" :allowChange="true" />
					</div>
					<plan-footer :plan="visionPlan" :showRx="false" />
				</div>
			</div>
		</div>

		<total-cost class="mt-4" :medicalPlan="medicalPlan" :dentalPlan="dentalPlan" :visionPlan="visionPlan">
			<template #footer>
				<a v-show="isAuthenticated" href="/dashboard/home" type="button" class="btn btn-secondary btn-lg m-2"><span class="fas fa-arrow-left"></span> Return to dashboard</a>
				<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg">Apply Now</button>
			</template>
		</total-cost>

		<!-- todo: router link needs to be dynamic based on whether user skipped dental and/or vision -->
		<router-link :to="{ name: previousPage }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>

	</form>
</template>

<script>

import apiPlan from "@/api/plan"
import apiReview from "@/api/review"
import planHelper from "@/helpers/planHelper"
import {useApplicationStore} from "@/stores/application"
import MedicalPlan from './modules/MedicalPlan'
import DentalPlan from './modules/DentalPlan'
import VisionPlan from './modules/VisionPlan'
import TotalCost from './modules/TotalCost'
import PlanFooter from './modules/PlanFooter'
import { useAgentInfoStore } from "@/stores/agentInfo"
import {mapState} from "pinia/dist/pinia"

export default {
	inject: ['startLoading', 'stopLoading'],
	components: {
		MedicalPlan,
		DentalPlan,
		VisionPlan,
		TotalCost,
		PlanFooter,
	},
	data() {
		return {
			medicalPlan: null,
			dentalPlan: null,
			visionPlan: null,
			selectionButtonDisable: false,
			previousPage: 'vision'
		}
	},

	created() {
		this.startLoading()
		useApplicationStore().init().then(async () => {
			await this.loadForm()
			this.stopLoading()
		})
	},

	mounted() {
	},

	methods: {
		async updateForm() {
			this.startLoading()
			try {
				await apiReview.update()
			} catch (err) {
				window.scrollTo(0, 0)
				this.errorMessage = 'Sorry, something went wrong'
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
			if (!this.errorMessage) {
				this.$router.push({ name: 'subInfo' })
			}
			this.stopLoading()
		},

		submitForm() {
			this.updateForm()
		},

		async loadForm() {
			try {
				const result = (await apiPlan.getPlanInfo()).data.data
				this.medicalPlan = result.medicalPlan
				this.dentalPlan = result.dentalPlan
				this.visionPlan = result.visionPlan
				this.previousPage = result.skipVision ? 'dental' : 'vision'
			} catch (error) {
				window.scrollTo(0, 0)
				this.errorMessage = error.message || 'Something went wrong!'
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},
		printSelection: planHelper.printSelection
	},
	computed: {
		...mapState(useAgentInfoStore, {
			isAuthenticated: 'isAuthenticated'
		}),
	}
}
</script>

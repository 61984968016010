<template>
	<div id="demo_notice" class="container-fluid text-center p-1" v-if="showDemoWarning">You are using a demo account.<br class="d-show d-md-none"> Submitted applications will not be processed.</div>
	<div id="top_nav_base_container">
		<nav class="navbar navbar-expand-md navbar-light bg-light">
			<div class="container">
				<router-link :to="{ name: homeRoute }" class="navbar-brand">
					<img src="/img/logos/CoverageForOneLogo_transparent.png" alt="CoverageForOne" style="max-height: 40px;" />
				</router-link>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav me-auto">
						<li class="nav-item px-2">
							<router-link :to="{ name: homeRoute }" class="nav-link" aria-current="page">Home</router-link>
						</li>
						<li v-if="agentInfoStore.isAuthenticated && agentInfoStore.isAdmin" class="nav-item dropdown px-2">
							<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Admin</a>
							<div class="dropdown-menu" aria-labelledby="navbarDropdown">
								<router-link :to="{ name: 'manage-messages' }" class="dropdown-item"><i class="far fa-message pe-2"></i>Manage Messages</router-link>
								<a class="dropdown-item" href="#" id="openFabricateModal" @click="$refs.loginAsAgentModal.show()"><i class="fas fa-sign-in-alt pe-2"></i>Log in as an agent</a>
							</div>
						</li>
					</ul>
					<div class="d-flex">
						<button v-if="agentInfoStore.isAuthenticated" type="button" class="btn btn-primary w-100 d-none d-md-block" @click="$refs.shareModal.show()">
							<i class="fa fa-share-from-square"></i>&nbsp;Share your custom site
						</button>
					</div>

					<AgentLoginNav />
				</div>
				<form class="navbar-nav" v-if="this.$route.name != 'root' && this.$route.name != 'dashboard-home'">
					<button type="button" class="agent_brand_button btn btn-outline-secondary py-1 px-1 mt-2 mt-md-0 ms-2" v-if="!agentInfoStore.isAuthenticated && agentInfoStore.fullName" @click="this.$refs.agentInfoModal.show()">
						<div class="d-flex align-items-center">
							<div class="d-flex">
								<img class="img-fluid" :alt="agentInfoStore.agencyName + ' logo'" :src="agentInfoStore.logo" onerror="this.style.display='none'" />
							</div>
							<div class="presented_by d-flex mx-3">
								Presented by<br>
								{{ agentInfoStore.fullName }}<br>
							</div>
							<div class="d-flex flex-row flex-md-column flex-lg-row me-2">
								<i class="fa fa-envelope mx-1"></i>
								<i class="fa fa-phone mx-1"></i>
								<i class="fa fa-ellipsis-vertical mx-1"></i>
							</div>
						</div>
					</button>
				</form>
			</div>
			<div v-if="agentInfoStore.isAuthenticated" class="container mt-2 d-md-none">
				<div class="d-flex w-100">
					<div class="flex-grow-1 flex-sm-grow-0">
						<button type="button" class="btn btn-primary w-100" @click="$refs.shareModal.show()">
							<i class="fa fa-share-from-square"></i>&nbsp;Share your custom site
						</button>
					</div>
				</div>
			</div>

		</nav>
	</div>

	<BsSimpleModal title="Presented By" sizeClass="" id="agentInfoModal" ref="agentInfoModal">
		<template #body>
			<div class="col-md-18 offset-md-9">
				<img class="img-fluid" :alt="agentInfoStore.agencyName + ' logo'" :src="agentInfoStore.logo" onerror="this.style.display='none'" />
			</div>
			<div class="mt-3">
				<strong>{{ agentInfoStore.fullName }}</strong><br>
				<span v-if="agentInfoStore.agencyName">
					<strong>{{ agentInfoStore.agencyName }}</strong><br>
				</span>
				<span v-if="agentInfoStore.address" v-html="agentInfoStore.address"></span>
				<br v-if="agentInfoStore.address">
				<span v-if="agentInfoStore.phone">Phone: <a :href="'tel:' + this.agentPhoneLink">{{ agentInfoStore.phone }}</a><br></span>
				<span v-if="agentInfoStore.fax">Fax: <a :href="'tel:' + this.agentFaxLink">{{ agentInfoStore.fax }}</a><br></span>
				<span v-if="agentInfoStore.email">Email: <a :href="'mailto:' + agentInfoStore.email">{{ agentInfoStore.email }}</a><br></span>
			</div>
		</template>
	</BsSimpleModal>

	<LoginAsAgent ref="loginAsAgentModal" />

	<ShareSite ref="shareModal"></ShareSite>

</template>

<script>
import { mapStores } from 'pinia';
import { useAgentInfoStore } from '@/stores/agentInfo';
import { useConfigStore } from "@/stores/config";
import BsSimpleModal from './bootstrap/BsSimpleModal.vue';
import generalHelper from '../helpers/generalHelper.js';
import AgentLoginNav from './modules/AgentLoginNav.vue'
import ShareSite from "@/components/modules/ShareSite.vue";
import LoginAsAgent from "@/components/modules/LoginAsAgent.vue";

export default {
	emits: ['is-loading'],
	components: {
		BsSimpleModal,
		AgentLoginNav,
		ShareSite,
		LoginAsAgent
	},

	data() {
		return {
			subdomain: null,
			marketplaceLink: '#'
		};
	},

	created() {
		const configStore = useConfigStore()
		configStore.initStore().then(() => {
			this.marketplaceLink = configStore.strideBaseUrl
		})
	},

	computed: {
		...mapStores(useAgentInfoStore),

		showDemoWarning() {
			return useAgentInfoStore().agentSubDomain == 'demoagent'
		},
		homeRoute() {
			return useAgentInfoStore().isAuthenticated ? 'dashboard-home' : 'root'
		},
		agentPhoneLink() {
			return generalHelper.formatClickableContactNumber(this.agentInfoStore.phone)
		},
		agentFaxLink() {
			return generalHelper.formatClickableContactNumber(this.agentInfoStore.fax)
		},
	},
};
</script>

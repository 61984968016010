import apiHelper from '@/helpers/apiHelper';

export default {
  find() {
	return apiHelper.appGet(`subinfo`);
  },

  update(data) {
	return apiHelper.appPut(`subinfo`, data);
  },
};

import apiHelper from '@/helpers/apiHelper';

export default {
	fabricateAgent(userId) {
		return apiHelper.get(`admin/fabricateAgent/${userId}`);
	},

	unFabricateAgent() {
		return apiHelper.get(`unFabricateAgent`);
	},

	getAgentList(options) {
		return apiHelper.get(`admin/agentList`, options);
	},

	getMessageList(options) {
		return apiHelper.get(`admin/messageList`, options);
	},

	saveMessage(options) {
		return apiHelper.put(`admin/manageMessage`, options);
	},

	deleteMessage(messageId, options) {
		return apiHelper.post(`admin/manageMessage/${messageId}`, options);
	},

	getUsersByNPN(npn) {
		return apiHelper.get(`admin/getUsersByNPN/${npn}`);
	}
}

import apiHelper from '@/helpers/apiHelper';

export default {
	find() {
		return apiHelper.appGet('other');
	},

	update(data) {
		return apiHelper.appPut(`other`, data);
	}
};

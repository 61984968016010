import axios from 'axios';
import apiHelper from "@/helpers/apiHelper";

const client = axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URL
});

export default {
	find(id) {
		return client.get(`application/${id}/review`);
	},

	update() {
		return apiHelper.appPut(`review`);
	}
};

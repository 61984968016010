
export default {
	data: function() {
		return {
			Networks: {
				PPO: 1,
				HMO: 2,
				EPO: 3,
			}
		}
	}
}

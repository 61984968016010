import axios from "axios";
import { useApplicationStore } from '@/stores/application';

var _client = axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URL,
	withCredentials: true
});

_client.interceptors.response.use(function (response) {
	useApplicationStore().updateLastAccessTime();
	return response;
}, function (error) {
	// note: 419 is a non-standard status defined by Laravel (CSRF mismatch)
	// if user waits too long to log in they will get the CSRF mismatch
	// and reloading the page will refresh the CSRF token
	if (error.response.status === 403 || error.response.status === 419) {
		// session timed out - messaging should be handled by session timeout component
		window.location.href = '/';
	}
	return Promise.reject(error)
})

var _getAppPath = function() {
	return '/application/' + useApplicationStore().applicationId;
}

var get = function(path, options = {}) {
	return _client.get(path, options);
}

var appGet = function(appResource, options = {}) {
	return _client.get(_getAppPath() + '/' + appResource, options);
}

var put = function(path, data) {
	return _client.put(path, data);
}

var appPut = function(appResource, data) {
	return _client.put(_getAppPath() + '/' + appResource, data)
}

var post = function(path, data) {
	return _client.post(path, data);
}

var appPost = function(appResource, data) {
	return _client.post(_getAppPath() + '/' + appResource, data)
}

var getUrl = function(path) {
	return process.env.VUE_APP_BASE_API_URL + '/' + path;
}

var getAppUrl = function(resource) {
	return process.env.VUE_APP_BASE_API_URL + _getAppPath() + '/' + resource;
}

export default { get, appGet, put, appPut, post, appPost, getUrl, getAppUrl };

<style>
.img_border {
	border: 2px solid #aaa;
}
</style>

<template>

<form @submit.prevent="submitForm" class="">
	<p class="alert alert-success" v-show="successMessage">{{ successMessage }}</p>

	<validation-error-msg v-if="Object.keys(validationErrors).length !== 0 || Object.keys(dependentErrorsGrouped).length !== 0"></validation-error-msg>

	<h1 class="page_title h2">First tell us a little about yourself</h1>
	<div class="row">
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('sub_first_name')">
			<label for="sub_first_name" class="form-label">First Name</label>
			<input type="text" id="sub_first_name" class="form-control form-control-sm" v-model.trim="subFirstName" maxlength="30">
			<span id="help_block_sub_first_name" v-for="(msg, index) in validationErrors['sub_first_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('sub_last_name')">
			<label for="sub_last_name" class="form-label">Last Name</label>
			<input type="text" id="sub_last_name" class="form-control form-control-sm" v-model.trim="subLastName" maxlength="30">
			<span id="help_block_sub_last_name" v-for="(msg, index) in validationErrors['sub_last_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('email')">
			<label for="email" class="required_field form-label">Email Address</label>
			<input type="email" id="email" class="form-control form-control-sm" v-model.trim="email">
			<span id="help_block_email" v-for="(msg, index) in validationErrors['email']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('sub_gender')">
			<label for="sub_gender" class="form-label">Gender</label>
			<br>
			<div class="form-check form-check-inline" v-for="item in genderOptions" :key="item.id">
				<input class="form-check-input" type="radio" :id="`sub_gender_${item.value}`" name="sub_gender" v-model="subGender" :value="item.value">
				<label class="form-check-label" :for="`sub_gender_${item.value}`">{{ item.display }}</label>
			</div>
			<span id="help_block_sub_gender" v-for="(msg, index) in validationErrors['sub_gender']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('sub_birth_date')">
			<label for="sub_birth_date" class="form-label">Birth Date</label>
			<input id="sub_birth_date" name="sub_birth_date" type="text" class="form-control" v-maska="'##/##/####'" placeholder="mm/dd/yyyy" v-model="subBirthDate" />
			<span v-if="subBirthDate" class="form-text">Format: mm/dd/yyyy</span>
			<span id="help_block_sub_birth_date" v-for="(msg, index) in validationErrors['sub_birth_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('sub_tobacco')">
			<label for="sub_tobacco" class="form-label">Tobacco Use?</label> <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttTobaccoText"></i>
			<br>
			<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
				<input class="form-check-input" type="radio" :id="`sub_tobacco_${item.value}`" name="sub_tobacco" v-model="subTobacco" :disabled="disableTobacco(this.subBirthDate)" :value="item.id">
				<label class="form-check-label" :for="`sub_tobacco_${item.value}`">{{ item.value }}</label>
			</div>
			<span id="help_block_sub_tobacco" v-for="(msg, index) in validationErrors['sub_tobacco']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('residential_zip')">
			<label for="residential_zip" class="form-label">ZIP Code</label>
			<input id="residential_zip" name="residential_zip" type="text" class="form-control" v-maska="'#####'" v-model.trim="residentialZip" @keyup="updateCountyLists">
			<span id="help_block_residential_zip" v-for="(msg, index) in validationErrors['residential_zip']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
		<div class="col-sm-18 col-md-12 col-xl-9 mb-3" v-show="this.residentialCountyOptions.length > 1" :class="hasError('residential_county_id')">
			<label for="residential_county_id" class="form-label">County</label>
			<select id="residential_county_id" class="form-select" v-model="residentialCountyId" :disabled="residentialCountyOptions.length <= 1">
				<option v-for="item in residentialCountyOptions" :value="item.id" :key="item.id">{{ item.description }}</option>
			</select>
			<span id="help_block_sub_residential_county_id" v-for="(msg, index) in validationErrors['residential_county_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
		</div>
	</div>

	<div id="spouseSection" class="mt-4" v-show="this.showSpouse">
		<h2 class="d-inline-block">Your Spouse</h2><button type="button" class="btn btn-secondary btn-sm ms-3 d-inline-block align-top" @click="removeSpouse()">Remove</button>
		<div class="row">
			<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('spouse_gender')">
				<label for="spouse_gender" class="form-label">Gender</label>
				<br>
				<div class="form-check form-check-inline" v-for="item in genderOptions" :key="item.id">
					<input class="form-check-input" type="radio" :id="`spouse_gender_${item.value}`" name="spouse_gender" v-model="spouseGender" :value="item.value">
					<label class="form-check-label" :for="`spouse_gender_${item.value}`">{{ item.display }}</label>
				</div>
				<span id="help_block_spouse_gender" v-for="(msg, index) in validationErrors['spouse_gender']" :key="index" class="invalid-feedback">{{ msg }}</span>
			</div>
			<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('spouse_birth_date')">
				<label for="spouse_birth_date" class="form-label">Birth Date</label>
				<input id="spouse_birth_date" name="spouse_birth_date" type="text" class="form-control" v-maska="'##/##/####'" placeholder="mm/dd/yyyy" v-model="spouseBirthDate" />
				<span v-if="spouseBirthDate" class="form-text">Format: mm/dd/yyyy</span>
				<span id="help_block_spouse_birth_date" v-for="(msg, index) in validationErrors['spouse_birth_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
			</div>
			<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError('spouse_tobacco')">
				<label for="spouse_tobacco" class="form-label">Tobacco Use?</label> <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttTobaccoText"></i>
				<br>
				<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
					<input class="form-check-input" type="radio" :id="`spouse_tobacco_${item.value}`" name="spouse_tobacco" v-model="spouseTobacco" :value="item.id" :disabled="disableTobacco(this.spouseBirthDate)">
					<label class="form-check-label" :for="`spouse_tobacco_${item.value}`">{{ item.value }}</label>
				</div>
				<span id="help_block_spouse_tobacco" v-for="(msg, index) in validationErrors['spouse_tobacco']" :key="index" class="invalid-feedback">{{ msg }}</span>
			</div>
		</div>
	</div>

	<div id="dependentSection" v-show="this.dependents.length > 0">
		<div class="mt-4" v-for="(dep, index) in dependents" :key="index">
			<h2 class="d-inline-block">Dependent {{ index + 1 }}</h2><button type="button" class="btn btn-secondary btn-sm ms-3 d-inline-block align-top" @click="removeDependent(dep.depId)">Remove</button>
			<div class="row">
				<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError(`dependent${dep.depId}_gender`)">
					<label :for="`dependent${dep.depId}_gender`" class="form-label">Gender</label>
					<br>
					<div class="form-check form-check-inline" v-for="item in genderOptions" :key="item.id">
						<input class="form-check-input" type="radio" :id="`dependent${dep.depId}_gender_${item.value}`" :name="`dependent${dep.depId}_gender`" v-model="dep.gender" :value="item.value">
						<label class="form-check-label" :for="`dependent${dep.depId}_gender_${item.value}`">{{ item.display }}</label>
					</div>
					<span :id="`help_block_dependent${dep.depId}_gender`" v-for="(msg, index) in validationErrorsDependent[`dependent${dep.depId}_gender`]" :key="index" class="invalid-feedback">{{ msg }}</span>
				</div>
				<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError(`dependent${dep.depId}_birth_date`)">
					<label :for="`dependent${dep.depId}_birth_date`" class="form-label">Birth Date</label>
					<input :id="`dependent${dep.depId}_birth_date`" :name="`dependent${dep.depId}_birth_date`" type="text" class="form-control" v-maska="'##/##/####'" placeholder="mm/dd/yyyy" v-model="dep.birthDate">
					<span v-if="dep.birthDate" class="form-text">Format: mm/dd/yyyy</span>
					<span :id="`help_block_dependent${dep.depId}_birth_date`" v-for="(msg, index) in validationErrorsDependent[`dependent${dep.depId}_birth_date`]" :key="index" class="invalid-feedback">{{ msg }}</span>
				</div>
				<div class="col-sm-18 col-md-12 col-xl-9 mb-3" :class="hasError(`dependent${dep.depId}_tobacco`)" :disabled="this.dependents.length >= 15">
					<label :for="`dependent${dep.depId}_tobacco`" class="form-label">Tobacco Use?</label> <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttTobaccoText"></i>
					<br>
					<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
						<input class="form-check-input" type="radio" :id="`dependent${dep.depId}_tobacco_${item.value}`" :name="`dependent${dep.depId}_tobacco`" v-model="dep.tobacco" :value="item.id" :disabled="disableTobacco(dep.birthDate)">
						<label class="form-check-label" :for="`dependent${dep.depId}_tobacco_${item.value}`">{{ item.value }}</label>
					</div>
					<span :id="`help_block_dependent${dep.depId}_tobacco`" v-for="(msg, index) in validationErrorsDependent[`dependent${dep.depId}_tobacco`]" :key="index" class="invalid-feedback">{{ msg }}</span>
				</div>
			</div>
		</div>
	</div>

	<h2 class="mt-4">Do you need to add anyone else?</h2>
	<div class="mt-4">
		<button type="button" class="btn btn-secondary me-3" @click="addSpouse()" v-show="!this.showSpouse">Add a spouse</button>
		<button type="button" class="btn btn-secondary" @click="addDependent()" :disabled="this.dependents.length >= 15">Add a dependent</button>
	</div>

	<hr>
	<div class="d-flex justify-content-between">
		<router-link :to="{ name: 'whyApplying' }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>
		<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg" :disabled="! allowContinue">Continue <i class="fa fa-arrow-right"></i></button>
	</div>
</form>

<div id="medicare_eligible_modal" ref="medicareEligibleModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="medicareEligibleModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="medicareEligibleModalLabel">Medicare Eligible?</h3>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>Are you or someone that you entered information for eligible for Medicare? People who are eligible for Medicare can't apply for individual medical coverage.</p>
				<div class="text-center">
					<fieldset><legend class="sr-only">Medicare eligible?</legend>
						<div class="" data-toggle="buttons">
							<label class="btn btn-secondary btn-lg me-2">
								<input type="radio" class="me-2" name="medicare_yes_no" value="1" id="medicare_yes" v-model="medicareEligible"> Yes
							</label>
							<label class="btn btn-secondary btn-lg">
								<input type="radio" class="me-2" name="medicare_yes_no" value="0" id="medicare_no" v-model="medicareEligible"> No
							</label>
						</div>
					</fieldset>
				</div>
				<div class="card bg-light mb-0 mt-3">
					<div class="card-body">You may be eligible for Medicare if you:<br>
						<ul>
							<li>Are 65 years old</li>
							<li>Have a long-term disability and have been on Social Security for more than 24 months</li>
							<li>Have end-stage renal disease, or Lou Gehrig's disease</li>
						</ul>
						<a href="https://www.medicare.gov/basics/get-started-with-medicare" target="_blank">Find out more about how Medicare works</a>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button id="medicare_eligible_modal_cancel" class="btn btn-outline-secondary float-left" data-bs-dismiss="modal" aria-hidden="true">Cancel</button>
				<button id="medicare_no_continue" class="btn btn-primary" v-show="medicareEligible == 0" @click="medicareNoContinue()">Continue</button>
				<button id="medicare_yes_continue" class="btn btn-primary" v-show="medicareEligible == 1" @click="medicareYesContinue()" :disabled="priorityOnly">Continue with Dental/Vision Only</button>
				<p class="alert alert-danger" v-show="medicareEligible == 1 && priorityOnly">We&rsquo;re sorry &ndash; this agent does not support Dental/Vision Only coverage at this time.</p>
			</div>
		</div>
	</div>
</div>

<div id="marketplace_modal" ref="marketplaceModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="marketplaceModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="marketplaceModalLabel">Subsidy Notice</h3>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>
					<span v-if="agentInfoStore.isAuthenticated">
						Depending on your customer’s income, they may be eligible for a subsidy to help pay for health insurance.
					</span>
					<span v-else>
						Depending on your income, you may be eligible for a subsidy to help pay for health insurance.
					</span>
					Subsidies include the Advance Premium Tax Credit <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttAptcText"></i> and Cost Sharing Reduction <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-html="true" data-bs-toggle="tooltip" :title="ttCsrText"></i>. Plans offered through CoverageForOne are not eligible for subsidies.
				</p>

				<p>Would you like to check for subsidy-eligibility?</p>
				<div class="text-center">
					<fieldset><legend class="sr-only">Subsidy Eligibility</legend>
						<div class="" data-toggle="buttons">
							<button class="btn btn-lg btn-primary" @click="marketplaceYesContinue()">Yes</button>
							&nbsp;&nbsp;
							<button type="button" class="btn btn-lg btn-primary" @click="marketplaceNoContinue()">No</button>
						</div>
					</fieldset>
				</div>

			</div>
			<div class="modal-footer">
				<button id="marketplace_cancel" class="btn btn-outline-secondary float-left" data-bs-dismiss="modal" aria-hidden="true">Cancel</button>
			</div>
		</div>
	</div>
</div>

<div id="subsidy_modal" ref="subsidyModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="subsidyModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="subsidyModalLabel">Subsidy Eligibility</h3>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<form @submit.prevent="subsidyCheckFormSubmit" class="">
					<validation-error-msg v-if="Object.keys(validationErrorsSubsidy).length !== 0"></validation-error-msg>
					<div class="alert alert-danger" v-else-if="subsidyCheckFailed">There was a problem checking for subsidy eligibility. Please try again or use the links below to shop for subsidy-eligible plans.</div>

					<label for="subsidy_tax_count" class="form-label text-start">How many people will be listed on the applicant&rsquo;s {{ new Date().getFullYear() }} tax return?</label>
					<div class="row mb-3 text-center" :class="hasError('subsidy_tax_count')">
						<div class="col-16 offset-10 col-sm-12 offset-sm-12">
							<div class="input-group">
								<button class="btn btn-outline-secondary" type="button" id="subsidy_tax_count_decrement" @click="subsidyTaxCount > 1 ? subsidyTaxCount-- : null" :disabled="subsidyTaxCount == 1"><i class="fas fa-minus"></i></button>
								<input type="text" id="subsidy_tax_count" class="form-control form-control-sm text-center" v-model.trim="subsidyTaxCount" maxlength="2">
								<button class="btn btn-outline-secondary" type="button" id="subsidy_tax_count_increment" @click="subsidyTaxCount++"><i class="fas fa-plus"></i></button>
							</div>
						</div>
						<span id="help_block_subsidy_tax_count" v-for="(msg, index) in validationErrorsSubsidy['subsidy_tax_count']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>

					<label for="subsidy_household_income" class="form-label text-start">How much income will the household make in {{ new Date().getFullYear() }}, before taxes?</label>
					<div class="row mb-2 text-center" :class="hasError('subsidy_household_income')">
						<div class="col-16 offset-10 col-sm-12 offset-sm-12">
							<div class="input-group">
								<span class="input-group-text">$</span>
								<input type="text" id="subsidy_household_income" class="form-control form-control-sm" v-model.trim="subsidyHouseholdIncome" maxlength="7">
							</div>
						</div>
						<span id="help_block_subsidy_household_income" v-for="(msg, index) in validationErrorsSubsidy['subsidy_household_income']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>
					<a class="small" href="https://www.healthcare.gov/income-and-household-information/how-to-report/" target="_blank">How do I estimate my expected income?<i class="fa fa-external-link ms-2"></i></a><br>
					<a class="small" href="https://www.healthcare.gov/income-and-household-information/income/" target="_blank">What income should I include?<i class="fa fa-external-link ms-2"></i></a>

					<div class="mt-3 text-center" data-toggle="buttons">
						<button class="btn btn-lg btn-primary">Calculate Subsidy</button>
					</div>
				</form>

				<div v-if="this.subsidyHasResults">
					<div v-if="this.subsidyEstimatedMonthlyAmount > 0" class="alert alert-info my-3">
						You may be eligible for a subsidy! The estimated monthly subsidy amount is {{ formatUSD(this.subsidyEstimatedMonthlyAmount) }}
					</div>
					<div v-else-if="this.subsidyEstimatedMonthlyAmount < 0 && this.subsidyFederalPovertyLevel < 1" class="alert alert-info my-3">
						You may be eligible for a subsidy! Shop for subsidy-eligible plans for details.
					</div>
					<div v-else class="alert alert-info my-3">
						Based on the applicant information provided, it is not likely that a subsidy is available.
					</div>
				</div>

				<div v-if="this.subsidyHasResults || this.subsidyCheckFailed" class="mt-3">
					<p>Would you like to shop for subsidy-eligible plans?</p>
					<div class="text-center">
						<fieldset><legend class="sr-only">Marketplace Insurance</legend>
							<div class="" data-toggle="buttons">
								<button class="btn btn-lg btn-primary" @click="subsidyMarketplaceYesContinue()">Yes</button>
								&nbsp;&nbsp;
								<button type="button" class="btn btn-lg btn-primary" @click="subsidyMarketPlaceNoContinue()">No</button>
							</div>
						</fieldset>
					</div>
				</div>

			</div>
			<div class="modal-footer">
				<button id="subsidy_modal_cancel" class="btn btn-outline-secondary float-left" data-bs-dismiss="modal" aria-hidden="true">Cancel</button>
			</div>
		</div>
	</div>
</div>

<div id="marketplace_redirect_modal" ref="marketplaceRedirectModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="marketplace_redirect_modal_label" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="marketplace_redirect_modal_label">Directing you to {{ marketplaceName }}</h3>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<template v-if="! agentInfoStore.isAuthenticated && agentInfoStore.npn">
					<div class="text-center mb-3" v-if="marketplaceInProgress && !showNpnInfo">
						<b>What is my agent&rsquo;s information and how do I enter it?</b><button class="btn btn-secondary ms-2" @click="showNpnInfo = true">Show Again</button>
					</div>
					<div v-if="showNpnInfo" class="mb-3">
						<p>
							<template v-if="! marketplaceInProgress">Please note your agent information and continue using the button below:<br></template>
							<b>Agent Name: {{ agentInfoStore.fullName }}</b><br>
							<b>National Producer Number (NPN): {{ agentInfoStore.npn }}</b><br>
						</p>
						<p>On your HealthCare.gov application, it is important to fill out the Application Help section as follows and enter your agent&rsquo;s information. Here is how it will appear:</p>
						<div class="row g-3 mb-3">
							<div class="col-sm-18">
								<img src="/img/app-help-1.png" class="img-fluid img_border">
							</div>
							<div class="col-sm-18">
								<img src="/img/app-help-2.png" class="img-fluid img_border">
							</div>
						</div>
					</div>
				</template>
				<p class="alert alert-info m-0">A new browser tab {{ marketplaceInProgress ? 'was opened' : 'will open' }} to shop for Medical plans. <span v-if="! priorityOnly">When you are finished, feel free to return here to continue shopping for Dental/Vision plans.</span></p>
				<p class="alert alert-danger mt-3" v-if="marketplaceInProgress && priorityOnly">We&rsquo;re sorry &ndash; this agent does not support Dental/Vision Only coverage at this time.</p>
				<div class="text-center" v-if="! marketplaceInProgress">
					<a :href="agentInfoStore.isAuthenticated ? agentMarketplaceLink : consumerMarketplaceLink" type="button" class="btn btn-lg btn-primary text-center mt-3" target="_blank" @click="goToMarketplace()">Take me to {{ marketplaceName }}</a>
				</div>
			</div>
			<div class="modal-footer" v-if="marketplaceInProgress">
				<button v-if="priorityOnly" id="possible_child_only_modal_cancel" class="btn btn-outline-secondary float-left" data-bs-dismiss="modal" aria-hidden="true">Cancel</button>
				<button v-else id="marketplace_redirect_continue" class="btn btn-primary"  @click="postMarketplaceContinue()">{{ this.enrollment_type === 'vision' ? 'Continue with Vision Only' : 'Continue with Dental/Vision Only'}}</button>
			</div>
		</div>
	</div>
</div>

<div id="possible_child_only_modal" ref="possibleChildOnlyModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="possibleChildOnlyAppModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="possibleChildOnlyAppModalLabel">Child Only Application?</h3>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>If you are a parent or legal guardian applying for coverage for a child, please contact your agent:</p>
				<p class="text-center">
					<b>
						{{ agentInfoStore.fullName }}<br>
						{{ agentInfoStore.phone }}<br>
						<a :href="'mailto:' + agentInfoStore.email">{{ agentInfoStore.email }}</a>
					</b>
				</p>
				<p>Otherwise, you may continue.</p>
			</div>
			<div class="modal-footer">
				<button id="possible_child_only_modal_cancel" class="btn btn-outline-secondary float-left" data-bs-dismiss="modal" aria-hidden="true">Cancel</button>
				<button id="possible_child_only_continue" class="btn btn-primary"  @click="possibleChildOnlyContinue()">Continue</button>
			</div>
		</div>
	</div>
</div>

<div id="child_only_modal" ref="childOnlyModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="childOnlyAppModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="childOnlyAppModalLabel">Child Only Application?</h3>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>If you are a parent or legal guardian applying for coverage for a child, please contact your agent:</p>
				<p class="text-center fw-bold">
					{{ agentInfoStore.fullName }}<br>
					{{ agentInfoStore.phone }}<br>
					<a :href="'mailto:' + agentInfoStore.email">{{ agentInfoStore.email }}</a>
				</p>
			</div>
			<div class="modal-footer">
				<button id="child_only_modal_cancel" class="btn btn-lg btn-secondary mx-auto" data-bs-dismiss="modal" aria-hidden="true">Close</button>
			</div>
		</div>
	</div>
</div>

<div id="outOfStateModal" ref="outOfStateModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="outOfStateModalLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="h3 modal-title" id="outOfStateModalLabel">Residency Notice</h1>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p><b>{{ residentialZip }}</b> is not a valid Michigan ZIP code. You must be a Michigan resident to apply for coverage on <b>CoverageForOne&reg;</b>. Please try again or contact your agent at:</p>
				<p class="text-center fw-bold">
					{{ agentInfoStore.fullName }}<br>
					{{ agentInfoStore.phone }}<br>
					<a :href="'mailto:' + agentInfoStore.email">{{ agentInfoStore.email }}</a>
				</p>
			</div>
			<div class="modal-footer">
				<button class="btn btn-secondary btn-lg  mx-auto" data-bs-dismiss="modal" aria-hidden="true">Close</button>
			</div>
		</div>
	</div>
</div>

</template>

<script>
import api from '../api/census'
import displayHelper from '@/helpers/displayHelper'
import generalHelper from '../helpers/generalHelper'
import { Modal } from 'bootstrap'
import { DateTime } from "luxon";
import apiGeography from '@/api/geography'
import {useApplicationStore} from "@/stores/application"
import { mapStores} from "pinia/dist/pinia"
import {useAgentInfoStore} from "@/stores/agentInfo"
import ValidationErrorMsg from './snippets/ValidationErrorMsg'
const OUT_OF_STATE_COUNTY_ID = 999;

export default {
	inject: ['startLoading', 'stopLoading'],
	components: {
		ValidationErrorMsg,
	},
	data() {
		return {
			validationErrors: {},
			validationErrorsDependent: {},
			dependentErrorsGrouped: {},
			error: null,
			errorMessage: '',
			successMessage: '',
			allowContinue: true, // used for disabling continue while waiting on county lookup
			isLoading: false,
			medicareModal: null,
			marketplaceModal: null,
			subsidyModal: null,
			marketplaceRedirectModal: null,
			consumerMarketplaceLink: 'https://www.healthcare.gov',
			agentMarketplaceLink: 'https://www.healthcare.gov',
			isAgentBroker: false,
			possibleChildOnlyModal: null,
			childOnlyModal: null,
			outOfStateModal: null,
			enrollment_type: '',

			// Select options
			noYesOptions: displayHelper.getNoYesArray(),
			residentialCountyOptions: [], //needs to be filled in after filling in residential zip
			genderOptions: displayHelper.getGenderArray(),

			// Subscriber
			subFirstName: '',
			subLastName: '',
			email: '',
			subGender: null,
			subBirthDate: '',
			subTobacco: null,

			// Address
			residentialZip: '',
			residentialCountyId: '',

			// Spouse
			showSpouse: false,
			spouseBirthDate: '',
			spouseGender: null,
			spouseTobacco: null,

			// Dependents (fill in on created)
			dependents: [],
			// numDependents is incremented for each dependent added and does not change when they are removed
			// used for creating unique field names that always stay with that dependent
			numDependents: 0,
			depTobaccoAgeLimit: 18,

			// Subsidy Calculator
			validationErrorsSubsidy: {},
			subsidyTaxCount: 1,
			subsidyHouseholdIncome: 0,
			subsidyEstimatedMonthlyAmount: 0,
			subsidyFederalPovertyLevel: 1,
			subsidyHasResults: false,
			subsidyCheckFailed: false,

			// Other
			nextPage: 'medical',
			skipMedical: 0,
			medicareEligible: null,
			marketplaceCertified: null,
			marketplaceInProgress: false,
			showNpnInfo: true,
			census: [],

			ttTobaccoText: 'During the past six months, have you been a regular tobacco user (four or more times per week, excluding religious or ceremonial use)?',
			ttAptcText: 'A tax credit that lowers what you pay per month for health insurance. You can only get this subsidy through a health insurance marketplace.',
			ttCsrText: 'This subsidy limits how much you&rsquo;ll have to pay out of your pocket for health care services you receive. It could also reduce other amounts insurance companies ask you to pay, like deductibles and copayments.',
		}
	},

	watch: {
		residentialCountyId(newId) {
			if (newId === OUT_OF_STATE_COUNTY_ID){
				this.outOfStateModal.show();
			}
		}
	},

	mounted() {
		this.medicareModal = new Modal(this.$refs.medicareEligibleModal)
		this.marketplaceModal = new Modal(this.$refs.marketplaceModal)
		this.subsidyModal = new Modal(this.$refs.subsidyModal)
		this.marketplaceRedirectModal = new Modal(this.$refs.marketplaceRedirectModal)
		this.possibleChildOnlyModal = new Modal(this.$refs.possibleChildOnlyModal)
		this.childOnlyModal = new Modal(this.$refs.childOnlyModal)
		this.outOfStateModal = new Modal(this.$refs.outOfStateModal)

		this.$refs.outOfStateModal.addEventListener('hidden.bs.modal', () => {
			this.residentialZip = null
			this.residentialCountyId = null;
		})

		this.$refs.marketplaceRedirectModal.addEventListener('hidden.bs.modal', () => {
			this.marketplaceInProgress = false
		})
	},

	created() {
		this.startLoading()
		const applicationStore = useApplicationStore()
		applicationStore.init(true).then(() => {
			this.stopLoading()
			this.consumerMarketplaceLink = api.getMarketplaceUrl();
			this.agentMarketplaceLink = api.getAgentMarketplaceUrl();
			this.skipMedical = applicationStore.skipMedical
			this.loadForm()
		})
	},

	methods: {
		disableTobacco(valBirthDate) {
			let personYear = new Date(valBirthDate).getFullYear();
			const milYear = (365.25 * 24 * 60 * 60 * 1000);

			if(!isNaN(personYear) && String(personYear).length === 4) {
				const personTime = new Date(valBirthDate).getTime();
				const curTime = Date.now();
				const personAge = Math.floor((curTime - personTime) / milYear);

				if(personAge >= this.depTobaccoAgeLimit) {
					return false;
				}
			}
			return true;
		},

		hasError(field) {
			return {
				'has-error': this.validationErrors[field] || this.validationErrorsDependent[field] || this.validationErrorsSubsidy[field]
			}
		},

		addSpouse() {
			this.showSpouse = true
		},

		removeSpouse() {
			this.showSpouse = false
			this.spouseGender = null
			this.spouseBirthDate = ''
			this.spouseTobacco = ''
		},

		addDependent() {
			const depId = this.numDependents
			this.dependents.push({ 'depId': depId, 'gender': "", 'birthDate': "", 'tobacco': "" })
			this.numDependents++
		},

		removeDependent(depId) {
			this.dependents = this.dependents.filter(dep => dep.depId != depId)

			delete this.dependentErrorsGrouped[depId]
		},

		async updateForm() {
			this.validationErrors = {};
			this.validationErrorsDependent = {};
			this.dependentErrorsGrouped = {};
			this.errorMessage = null

			this.submitData(true) //validation needs to happen before modals
		},

		async submitData(showModal = false) {
			this.startLoading()
			try {
				let formData = this.getCensusFormData()

				await api.update(formData)

				if (showModal) {
					this.showChildOnlyModal()
				} else {
					this.$router.push({ name: this.nextPage })
				}
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrors = err.response.data.errors
				this.moveDependentValidationErrors()

				this.errorMessage = 'Sorry, something went wrong';
			}
			this.stopLoading()
		},

		getCensusFormData() {
			let formData = {
				'sub_first_name': this.subFirstName,
				'sub_last_name': this.subLastName,
				'email': this.email,
				'sub_gender': this.subGender,
				'sub_birth_date': this.subBirthDate,
				'sub_tobacco': this.subTobacco,

				'residential_zip': this.residentialZip,
				'residential_county_id': this.residentialCountyId,

				dependent_ids: this.dependents.map(({ depId }) => depId),

				'skip_medical': this.skipMedical
			}

			if (this.showSpouse) {
				formData['spouse_gender'] = this.spouseGender
				formData['spouse_birth_date'] = this.spouseBirthDate
				formData['spouse_tobacco'] = this.spouseTobacco
			}

			this.dependents.forEach((dep) => {
				formData['dependent' + dep.depId + '_gender'] = dep.gender
				formData['dependent' + dep.depId + '_birth_date'] = dep.birthDate
				formData['dependent' + dep.depId + '_tobacco'] = dep.tobacco
			})

			return formData
		},

		submitForm() {
			this.updateForm()
		},

		async loadForm() {
			this.startLoading()
			try {
				const result = (await api.find()).data.data

				this.marketplaceCertified = result.agentMarketplaceCertified
				this.enrollment_type = result.enrollmentType

				this.subFirstName = result.subscriber.first_name
				this.subLastName = result.subscriber.last_name
				this.email = result.subscriber.email
				this.subGender = result.subscriber.gender
				this.subBirthDate = DateTime.fromISO(result.subscriber.birth_date).toFormat('LL/dd/yyyy')
				this.subTobacco = result.subscriber.tobacco
				this.residentialZip = result.subscriber.residential_address.zip
				this.residentialCountyId = result.subscriber.residential_address.county_id

				if (result.spouse.birth_date) {
					this.showSpouse = true
					this.spouseGender = result.spouse.gender
					this.spouseBirthDate = DateTime.fromISO(result.spouse.birth_date).toFormat('LL/dd/yyyy')
					this.spouseTobacco = result.spouse.tobacco
				} else {
					this.showSpouse = false
				}

				result.dependents.forEach((dependent, index) => {
					this.dependents.push(
						{
							gender: dependent.gender,
							birthDate: DateTime.fromISO(dependent.birth_date).toFormat('LL/dd/yyyy'),
							tobacco: dependent.tobacco,
							depId: index,
							firstName: dependent.first_name,
							middle: dependent.middle_initial,
							lastName: dependent.last_name,
							relationship: dependent.relationship,
							ssn: dependent.ssn,
							usCitizen: dependent.us_citizen,
							pcpFirstName: dependent.pcp_first_name,
							pcpLastName: dependent.pcp_last_name,
							pcpNPI: dependent.pcp_npi,
							pcpSeenInLastYear: dependent.pcp_seen_in_last_year
						}
					)

					this.numDependents = index + 1
				})
				this.residentialCountyOptions = (await apiGeography.getCountySelectList(this.residentialZip)).data
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
			}
			this.stopLoading()
		},

		async updateCountyLists() {
			if (this.residentialZip.length === 5) {
				this.allowContinue = false
				this.residentialCountyId = null

				this.residentialCountyOptions = (await apiGeography.getCountySelectList(this.residentialZip)).data
				this.allowContinue = true
				if (this.residentialCountyOptions.length === 1) {
					this.residentialCountyId = this.residentialCountyOptions[0].id
				}
			}
		},

		handleError() {
			this.error = null
		},

		possibleChildOnlyContinue() {
			this.possibleChildOnlyModal.hide()
			this.showMedicareModal()
		},

		medicareYesContinue() {
			this.nextPage = 'dental'
			this.marketplaceInProgress = false;
			this.skipMedical = 1
			this.medicareModal.hide();
			this.submitData();
		},

		medicareNoContinue() {
			this.nextPage = 'medical'
			this.marketplaceInProgress = false;
			this.skipMedical = 0
			this.medicareModal.hide()
			this.showMarketplaceModal()
		},

		marketplaceYesContinue() {
			this.marketplaceModal.hide()
			this.subsidyModal.show()
		},

		marketplaceNoContinue() {
			this.marketplaceInProgress = false;
			this.marketplaceModal.hide()
			this.submitData()
		},

		subsidyCheckFormSubmit() {
			this.subsidyHasResults = false
			this.subsidyCheckFailed = false
			this.validationErrorsSubsidy = {}

			this.startLoading()
			try {

				let formData = this.getCensusFormData()

				// add relevant fields from subsidy calc
				formData.subsidy_tax_count = this.subsidyTaxCount
				formData.subsidy_household_income = this.subsidyHouseholdIncome

				api.checkSubsidy(formData)
					.then((response) => {
						this.subsidyEstimatedMonthlyAmount = response.data.estimatedMonthlySubsidyAmount
						this.subsidyFederalPovertyLevel = response.data.federalPovertyLevel
						this.subsidyHasResults = true
						this.stopLoading()
					})
					.catch((error) => {
						if (error.response.data.errors) {
							this.validationErrorsSubsidy = error.response.data.errors
						} else {
							this.subsidyCheckFailed = true
						}
						this.stopLoading()
					})

			} catch (error) {
				this.subsidyCheckFailed = true
				this.stopLoading()
			}
		},

		subsidyMarketplaceYesContinue() {
			this.subsidyModal.hide()
			this.marketplaceRedirectModal.show()
		},

		subsidyMarketPlaceNoContinue() {
			this.marketplaceInProgress = false;
			this.subsidyModal.hide()
			this.submitData()
		},

		goToMarketplace() {
			this.nextPage = 'dental'
			this.skipMedical = 1
			this.marketplaceInProgress = true
			this.showNpnInfo = false
		},

		postMarketplaceContinue() {
			this.marketplaceRedirectModal.hide();
			this.submitData();
		},

		showChildOnlyModal() {
			let eff_date = Date.parse(this.applicationStore.effectiveDate + ' ')
			let primary_bd = Date.parse(this.subBirthDate + ' ')

			// if subscriber is only person entered, and age 18-20 at effective date
			if (this.subBirthDate != null) {
				// Logic is changed so that 17+ is now possible child only
				let primary_bd_plus16 = new Date(primary_bd)
				primary_bd_plus16.setFullYear(primary_bd_plus16.getFullYear() + 16)
				let primary_bd_plus17 = new Date(primary_bd)
				primary_bd_plus17.setFullYear(primary_bd_plus17.getFullYear() + 17)
				let primary_bd_plus20 = new Date(primary_bd)
				primary_bd_plus20.setFullYear(primary_bd_plus20.getFullYear() + 20)

				if (primary_bd_plus17 > eff_date) { // if age < 17
					this.childOnlyModal.show()
					return null
				} else if (!this.spouseBirthDate && Object.keys(this.dependents).length === 0) {
					if (primary_bd_plus16 <= eff_date && primary_bd_plus20 >= eff_date) { // if age 16-20
						this.possibleChildOnlyModal.show()
						return null
					}
				}
			}

			this.showMedicareModal()
		},

		showMedicareModal() {
			const agentInfoStore = useAgentInfoStore()
			if(!this.validationErrors.length) {
				if (this.enrollment_type === 'dental' || this.enrollment_type === 'vision') { //if enrollment is dental/vision only, no need for medicare modal
					this.nextPage = this.enrollment_type
					this.submitData()
				} else if(agentInfoStore.isAuthenticated) { //agents are aware of medicare options
					this.showMarketplaceModal()
				} else {
					this.medicareModal.show()
				}
			}
		},

		showMarketplaceModal() {
			if (this.marketplaceCertified) {
				this.marketplaceModal.show()
			} else {
				this.submitData()
			}
		},

		// todo: this is kind of a big hack, so we can treat dependents differently for displaying validation errors.
		// The reason for this is we want their field label names updated dynamically (e.g., Dependent 1, Dependent 2)
		// if a dependent is removed that has a validation error displayed.
		moveDependentValidationErrors() {
			this.dependentErrorsGrouped = {}

			Object.keys(this.validationErrors).forEach((fieldName) => {
				const regex = /dependent(\d+)/
				let matches = regex.exec(fieldName)

				if (matches) {
					let dependentId = matches[1]

					if (! Object.hasOwn(this.dependentErrorsGrouped, dependentId)) {
						this.dependentErrorsGrouped[dependentId] = {}
					}

					this.dependentErrorsGrouped[dependentId][fieldName] = this.validationErrors[fieldName]
					this.validationErrorsDependent[fieldName] = this.validationErrors[fieldName]

					delete this.validationErrors[fieldName]
				}
			})
		},

		formatUSD: generalHelper.formatUSD,
	},
	computed: {
		priorityOnly() {
			return this.agentInfoStore.displayPriority && !this.agentInfoStore.displayBCBSM
		},

		marketplaceName() {
			return this.agentInfoStore.isAuthenticated ? 'subsidy-eligible plans' : 'HealthCare.gov'
		},

		// map dependent Ids to a number indicating the sequence in the list of dependents
		// e.g., { 2: 1 } means dependent Id 2 is first in the dependent list
		depIdNameMap() {
			let nameMap = {}

			let errorDepIds = Object.keys(this.dependentErrorsGrouped)

			this.dependents.forEach((dependent, index) => {

				const depId = dependent.depId.toString()

				if (errorDepIds.includes(depId)) {
					nameMap[depId] = index + 1
				}

			})

			return nameMap
		},
		...mapStores(useApplicationStore, useAgentInfoStore)
	}
}
</script>

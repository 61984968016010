<template>
	<h4>Blue Cross Blue Shield Of Michigan Or Blue Care Network Of Michigan Plans</h4>
	<hr>
	<h5 class='fw-bold'>ELIGIBILITY</h5>
	<p>I understand that I&apos;m eligible for this coverage if I, my spouse and my dependents listed on this application are residents of Michigan on the effective date of the policy and live in Michigan at least 180 days each year, and that I, my spouse and my dependents listed on this application aren&apos;t eligible for or enrolled in Medicare. If anyone on this application is eligible for or enrolled in Medicare, they&apos;re eligible for a Dental, Dental with Vision, or Adult Vision Only plan. I certify that I, my spouse and my dependents listed on this application are U.S. citizens or legally present in the U.S. I understand that I must notify BCBSM or BCN immediately if my address changes.</p>
	<p>If I am applying for coverage outside of the open enrollment period, I certify that I meet one of the qualifying events defined by the Affordable Care Act (ACA), including but not limited to, birth, adoption, change in marital status, loss of job or loss of group coverage. I am applying within the appropriate special enrollment period (SEP) as determined by my life event, and have provided appropriate documentation of my life event. I understand full details on qualifying events and special enrollment periods can be found at healthcare.gov. I am applying for health coverage through BCBSM or BCN, based on the specific plans I selected, and understand that I&apos;ll be subject to the terms and conditions of this application, and I agree that I&apos;ll also be bound by all provisions in the applicable plan certificates and riders. Approval of this application and coverage effective date will be determined by BCBSM or BCN, as applicable. Additional information may be required of me. Coverage is contingent on payment of first premium being received within 31 days of assigned effective date.</p>
	<p>BCBSM or BCN, as applicable, have the right to test for tobacco usage to determine applicable rates, and BCBSM or BCN, as applicable, can retroactively adjust premium rates back to the effective date based on results of tobacco (cotinine) testing. Regular tobacco use is defined as four or more times per week excluding religious or ceremonial use. I know that under federal law, discrimination isn&apos;t permitted on the basis of race, color, national origin, sex, age, sexual orientation, gender identity or disability. I can file a complaint of discrimination by visiting <a href="https://hhs.gov/ocr/office/file" target="_blank">hhs.gov/ocr/office/file.</a></p>
	<p>This coverage isn&apos;t an employer group health plan and isn&apos;t intended in any way to be an employer-sponsored health insurance plan. I certify that neither my or my spouse&apos;s employer will contribute any part of the premium, nor will I or my spouse be reimbursed for any part of the premium by the employer now, or in the future. Premium payments will be accepted from myself, my spouse or, when applicable, the parent, blood relative, legal guardian, or other person or entity authorized under the law to pay the premium on the subscriber&apos;s behalf.</p>
	<p>I may enroll my eligible spouse and eligible dependents. An eligible spouse is the legal husband or wife of the subscriber, as recognized as legal in the jurisdiction where the marriage occurred. An eligible dependent child is related to the subscriber by birth, marriage, legal adoption, legal guardianship, or foster child placement and under age 26 on the coverage effective date. I understand that coverage for my dependent children will end on the last day of the year in which they reach age 26. These dependent children may apply for their own individual coverage. Disabled, unmarried children may remain covered after they turn 26 if certain requirements are met (not available for pediatric dental). A physician&apos;s certification of the dependent child&apos;s disability must be received 31 days after the end of the year in which they turned 26 for determination of continuing coverage under my plan.</p>
	<p>With regard to costs of hospital and medical services delivered by or paid for by BCBSM or BCN, as applicable, I agree to assign my entire right to recovery of those costs against any person or organization as a result of accident or disease including injuries or disease claimed under worker&apos;s compensation laws or acts whether by redemption award or voluntary payment or otherwise to BCBSM or BCN, as applicable.</p>
	<p>I certify that the requirements of eligibility are met and that all of the information supplied on this application is true, correct, and complete to the best of my knowledge. Detailed information regarding eligibility is available for viewing in the BCBSM or BCN certificate and at <a href="https://bcbsm.com" target="_blank">bcbsm.com</a>. I understand that the information will be used in reviewing my application and administering coverage and that any misrepresentation or false or misleading information may result in termination or rescission of coverage.</p>
	<h5 class='fw-bold'>TERMINATION OF EXISTING BCBSM OR BCN COVERAGE OR PRIOR APPLICATIONS</h5>
	<p>In applying for coverage, I am requesting termination of any other Off-Marketplace BCBSM and BCN individual policy or prior application for BCBSM or BCN Off-Marketplace coverage for which I&apos;m a contract holder and lists the same covered members (if any) for which I have requested coverage with this application. I also request that the prior policy termination be effective as of the effective date of this coverage and prior BCBSM or BCN Off-Marketplace applications be terminated immediately. If I want to maintain my existing coverage when the coverage for which I&apos;m applying becomes effective, I will contact BCBSM/BCN directly. On-Marketplace individual policies need to be terminated by contacting the Marketplace.</p>
	<h5 class='m=0 fw-bold'>RENEWABILITY - MEDICARE</h5>
	<p>Blue Cross Blue Shield of Michigan and Blue Care Network are prohibited from renewing individual market coverage for an enrollee known to be entitled to Medicare Part A or enrolled in Medicare Part B if it would duplicate benefits to which the enrollee is entitled, unless the renewal is effectuated under the same policy or contract of insurance.</p>
	<h5 class='fw-bold'>TERMINATION OF COVERAGE</h5>
	<p>I understand that voluntary termination of my policy, including non-payment of premium, does not qualify as a life event to enroll outside of the annual open enrollment period for myself or my dependents on the policy.</p>
	<p>I understand BCBSM or BCN may terminate my coverage, if, including but not limited to, we no longer qualify for coverage under the certificate, we can&apos;t provide proof of residency in Michigan for a minimum of 180 days a year, or for misuse of coverage.</p>
	<h5 class='fw-bold'>HEALTH SAVINGS ACCOUNT OFFERED THROUGH HEALTHEQUITY&reg;</h5>
	<p>Customers enrolled in HSA eligible plans can pair their plan with a health savings account (HSA) offered through HealthEquity. HealthEquity is an independent company partnering with Blue Cross Blue Shield of Michigan and Blue Care Network to provide health care spending account administration services. An independent and FDIC-insured bank holds the health savings account dollars.</p>
	<p>HSA accounts will have no charge per month for administrative fees per funded account. Members with Native American cost-sharing subsidies on any plan can&apos;t open an HSA. Likewise, Blue Cross plans that aren&apos;t high-deductible health plans (HDHP) aren&apos;t eligible to open an HSA account, this includes Blue Cross Plans with “extra” benefits, as some benefits are covered before the deductible is met. If you&apos;ve already established an HSA and begin to receive these cost-sharing subsidies, or if you switch to a non-HDHP with BCBSM, BCN, or another insurer, you will continue to own the funds in your HSA and may continue to spend from your HSA but you will no longer be able to contribute to and manage your HSA through BCBSM&apos;s/BCN&apos;s member portal at <a href="https://bcbsm.com" target="_blank">bcbsm.com</a>. BCBSM/BCN will notify HealthEquity of your ineligibility and you&apos;ll receive information within one month of the date of ineligibility on how to continue managing your health savings account.</p>
	<p>Customers who have an HSA with HealthEquity through their current BCBSM or BCN HDHP and apply for another HDHP with either BCBSM or BCN can continue to manage their HSA through the BCBSM/BCN member portal. If you want to discontinue management of your HSA with HealthEquity through the BCBSM/BCN member portal, you must contact BCBSM/BCN customer service directly to decouple management of your HSA from your Blue Cross Blue Shield of Michigan or Blue Care Network plan.</p>
	<h5 class='fw-bold'>CATASTROPHIC (VALUE) PLANS</h5>
	<p>Catastrophic plans including Blue Cross® Premier PPO Value and Blue Cross® Select HMO Value are available to individuals under age 30 or those who&apos;ve received a certification of exemption from the individual mandate due to affordability or hardship from the Health Insurance Marketplace. All members on the plan, including your spouse and dependents, must be under age 30 before the plan effective date, to be eligible to enroll in a value plan. If you meet this eligibility requirement, you can stay in a catastrophic plan for the duration of the calendar year in which you turn age 30.</p>
	<h5 class='fw-bold'>AUTHORIZATION FOR THE USE AND DISCLOSURE OF PROTECTED HEALTH INFORMATION (PHI)</h5>
	<p>I understand that information collected about me as provided by this authorization will be used for the purposes noted below as well as to determine my eligibility for health coverage. BCBSM or BCN may collect personal and protected health information (PHI) about me to process my application for coverage. BCBSM or BCN will use and disclose this information only in accordance with their Notice of Privacy Practices, which is available on <a href="https://bcbsm.com" target="_blank">bcbsm.com</a> or by calling 313-225-9000.</p>
	<p>I authorize:</p>
	<ul>
	<li>Use and disclosure of my PHI, including membership, eligibility and claims data stored on BCBSM&apos;s and its subsidiaries&apos; computer systems.</li>
	<li>Physicians, health care professionals, hospitals, clinics, laboratories, pharmacies or pharmacy benefit managers, or other health care providers that have provided treatment or services to me or any of my dependents who are also applying for coverage to disclose medical records, prescription history, medications prescribed and other PHI as requested to BCBSM or BCN.</li>
	<li>Health plans, governmental agencies or prescription drug profiling companies that have a previous relationship with me or who have knowledge of my medical information or the medical information of any of my dependents who are also applying for coverage to disclose medical records information, prescription history, medications prescribed and other PHI as requested to BCBSM or BCN. My authorization includes disclosure of information on the diagnosis and treatment of human immunodeficiency virus (HIV) infection and treatment of mental illness and the use of alcohol, drugs and tobacco, but excludes disclosure of psychotherapy notes.</li>
	</ul>
	<p>This PHI is to be disclosed so that BCBSM or BCN may: (1) perform case, care and disease management, (2) validate rating factors allowable under the Patient Protection and Affordable Care Act (PPACA), (3) administer claims and determine or fulfill responsibility for coverage and provision of benefits, and (4) for other legally permissible purposes, including but not limited to, health care operations.</p>
	<p>If BCBSM rediscloses this information, the recipient must obtain an additional authorization from me before it may redisclose the information and, if I provide this authorization, information may be redisclosed by the recipient and is no longer protected. I understand and acknowledge that if I&apos;m applying for coverage from BCN that this restriction on redisclosure doesn&apos;t apply, but if BCN does redisclose my information, it may no longer be protected.</p>
	<p>I understand that my enrollment with BCBSM or BCN is conditioned upon my authorization to release PHI for the purposes stated above and that if I don&apos;t provide authorization, I may not be eligible for enrollment. My signature on this form indicates my approval for the release of PHI from BCBSM or BCN and its subsidiaries and from any of the parties listed above to BCBSM or BCN. A copy or other reproduction of this authorization shall be valid as the original. My authorization expires upon the later of (i) rescission or rejection of coverage by BCBSM or BCN; or (ii) I cause my coverage to terminate or it otherwise expires. I understand that I&apos;m entitled to receive a copy of this authorization upon request. I may revoke this authorization at any time by sending a written request on a standard form available online at <a href="https://bcbsm.com" target="_blank">bcbsm.com</a> or by contacting my Blue Cross or BCN agent. I understand that revocation won&apos;t affect actions taken before BCBSM or BCN or any of the parties identified above receive my request.</p>
	<h5 class='fw-bold'>REFUND POLICY</h5>
	<p>I understand that requests to terminate coverage will be accepted by me, the card holder, and if I terminate coverage, BCBSM or BCN will refund the unused portion of the monthly premium that was paid, if applicable. BCBSM or BCN will mail me a check within 30 days from the date of my termination. Details about terminating coverage can be found in the certificate or by contacting the number on the back of my BCBSM or BCN card.</p>
	<p>I may terminate my coverage by notifying BCBSM or BCN within 10 days of the effective date of my coverage. I will receive a full refund of my premium. If I terminate my coverage after 10 days, I will receive a prorated refund on the unused portion of my premium.</p>
	<p>Refunds for Blue Cross® Vision for Adults will only be granted to those members that have elected to pay annually and have no benefit utilization by anyone on the contract for the given year for which premium has been paid in advance. These refunds will be processed by request as of the first of the following month (no partial month prorated refunds).</p>
</template>

<template>
	<footer id="footer">
		<div class="container-fluid my-3">
			<div class="container text-center">
				CoverageForOne® is an enrollment tool owned and provided to Action Benefits by Interserv LLC.<br>
				Action Benefits is an authorized independent General Agent.<br>
				&copy; {{ new Date().getFullYear() }} Interserv LLC&nbsp;
				<a href="javascript:;" class="link-primary" @click="$refs.privacyModal.show()">Privacy Policy</a>&nbsp;
				<a href="javascript:;" class="link-primary" @click="showTermsOfUseModal()">Terms of Use</a>

				<img src="/img/interserv_logo_white.png" id="interserv_logo" class="d-block mx-auto mt-2" alt="" role="presentation" style="max-width:85px;"><br>
			</div>
		</div>
	</footer>

	<BsSimpleModal title="Privacy Policy" sizeClass="modal-xl" id="privacyModal" ref="privacyModal">
		<template #body>
			<privacy-policy />
		</template>
	</BsSimpleModal>
</template>

<script>
import BsSimpleModal from './bootstrap/BsSimpleModal.vue';
import PrivacyPolicy from './PrivacyPolicy.vue';

export default {
	inject: ['showTermsOfUseModal'],
	components: {
		BsSimpleModal,
		PrivacyPolicy,
	},
};
</script>

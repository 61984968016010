<style>
	.alert {
		margin-bottom: 0.5rem;
	}
	.alert:last-child {
		margin-bottom: 0;
	}
</style>

<div class="vld-parent"></div>

<template>
	<div class="card vld-parent">
		<loading v-model:active="isLoading" :is-full-page="false"></loading>
		<div class="card-header">
			<div class="d-flex flex-column flex-md-row justify-content-between">
				<div class="d-flex flex-column flex-md-row justify-content-start align-items-center">
					<h2 class="h4 mb-0">Notifications</h2>
					<span class="badge rounded-pill ms-2" :class="messages.length === 0 ? 'text-bg-secondary' : 'text-bg-danger'">{{ messages.length }}</span>
				</div>
				<router-link :to="{ name: 'dashboard-recent-notifications' }" class="">View All Recent Notifications</router-link>
			</div>
		</div>
		<div class="card-body p-2">
			<div v-for="message in messages" :key="message.id" class="alert alert-info d-flex flex-column flex-md-row justify-content-between">
				<div v-html="message.content"></div>
				<div v-if="!message.is_sticky" class="pt-3 pt-md-0 ps-md-3">
					<button type="button" class="btn btn-outline-secondary" @click="dismissMessage(message.id)">Dismiss</button>
				</div>
			</div>

			<div class="text-center p-3" v-if="messages.length === 0">
				You don&rsquo;t have any new notifications
			</div>
		</div>
	</div>
</template>

<script>
import apiDashboard from "@/api/dashboard";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	emits: [],
	props: {
	},
	components: {
		Loading,
	},

	data() {
		return {
			isLoading: true,
			messages: [],
		}
	},

	mounted() {
		this.loadData()
	},

	created() {
	},

	computed: {
	},

	methods: {
		async loadData() {
			let options = null

			try {
				this.messages = (await apiDashboard.getMessages(options)).data

				this.isLoading = false
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},

		async dismissMessage(xrefId) {
			let options = null

			// remove from UI immediately for better UX even if it's possible the delete could fail
			this.messages = this.messages.filter(xref => xref.id != xrefId)

			try {
				await apiDashboard.dismissMessage(xrefId, options)
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}

		}
	}
}
</script>

<template>
	<div class="modal fade" ref="modalElem" :id="id" tabindex="-1" :aria-labelledby="'modalLabel' + id" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<form @submit.prevent="submitForm">
				<div class="modal-header">
					<h5 class="modal-title" :id="'modalLabel' + id">{{ title }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<slot name="body">
						This is the default body!
					</slot>
				</div>
				<div class="modal-footer">
					<slot name="footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						<button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
					</slot>
				</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
	props: {
		id: {
			required: true
		},
		title: {
			required: true
		},
	},
	data() {
		return {
			modalObj: null,
		}
	},
	mounted() {
		this.modalObj = new Modal(this.$refs.modalElem)
	},
	expose: ['show'],
	methods: {
		show() {
			this.modalObj.show();
		}
	}
}
</script>

// import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./style/custom.scss";

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Maska from 'maska';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import Home from './components/Home.vue';
import Census from './components/Census.vue';
import Medical from './components/Medical.vue';
import Dental from './components/Dental.vue';
import Vision from './components/Vision.vue';
import Review from './components/Review.vue';
import Pcp from './components/Pcp.vue';
import Playground from './components/Playground.vue';
import SubInfo from './components/SubInfo.vue';
import Other from './components/Other.vue';
import WhyApplying from './components/WhyApplying.vue';
import RaP from './components/RaP.vue';
import NotFound from './components/NotFound.vue';
import AgentDashboard from './components/AgentDashboard.vue';
import DashboardQuotes from './components/modules/dashboard/DashboardQuotes.vue';
import DashboardEnrollees from './components/modules/dashboard/DashboardEnrollees.vue';
import DashboardHome from './components/modules/dashboard/DashboardHome.vue';
import MessagesHistory from './components/modules/dashboard/MessagesHistory.vue';
import ManageMessages from './components/modules/ManageMessages.vue';

import stepHelper from './helpers/stepHelper.js';
import apiStep from './api/step.js';

import { useStepStore } from './stores/step';
import { useApplicationStore } from './stores/application';
import { useAgentInfoStore } from '@/stores/agentInfo'

import { tooltip } from '@/directives/tooltip.js'

function checkAuth(to, from, next) {

	if (useAgentInfoStore().isAuthenticated) {
		next()
	} else {
		next({
			name: "login",
			query: { redirectFrom: to.fullPath } //todo: redirect with path currently untested, may be more work to implement this in the future
		})
	}
}

const router = createRouter({
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'root',
			component: Home,
			meta: {
				layout: 'full_page',
			},
		},
		{
			path: '/census',
			name: 'census',
			component: Census,
		},
		{
			path: '/medical',
			name: 'medical',
			component: Medical,
		},
		{
			path: '/dental',
			name: 'dental',
			component: Dental,
		},
		{
			path: '/vision',
			name: 'vision',
			component: Vision,
		},
		{
			path: '/review',
			name: 'review',
			component: Review,
		},
		{
			path: '/subInfo',
			name: 'subInfo',
			component: SubInfo,
		},
		{
			path: '/pcp',
			name: 'pcp',
			component: Pcp,
		},
		{
			path: '/other',
			name: 'other',
			component: Other,
		},
		{
			path: '/whyapplying',
			name: 'whyApplying',
			component: WhyApplying,
		},
		{
			path: '/rap',
			name: 'rap',
			component: RaP,
		},
		{
			path: '/playground',
			name: '',
			component: Playground,
		},
		{
			path: '/reset-password/email/:email/token/:token',
			name: 'reset-password',
			component: Home,
			meta: {
				layout: 'full_page',
			},
		},
		{
			path: '/register/token/:token/',
			name: 'register',
			component: Home,
			meta: {
				layout: 'full_page',
			},
		},
		{
			path: '/login',
			name: 'login',
			component: Home,
			meta: {
				layout: 'full_page',
				login: true,
			},
		},
		{
			path: '/manageMessages',
			component: ManageMessages,
			name: 'manage-messages',
			meta: {
				layout: 'full_page',
			},
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			component: AgentDashboard,
			beforeEnter: [checkAuth],
			meta: {
				layout: 'full_page',
				containerFluid: true
			},
			children: [
				{
					path: 'home',
					component: DashboardHome,
					name: 'dashboard-home',
				},
				{
					path: 'quoting',
					name: 'dashboard-quoting',
					component: DashboardQuotes,
					props: { resultsPerPage: 12 },
				},
				{
					path: 'enrolled',
					name: 'dashboard-enrolled',
					component: DashboardEnrollees,
					props: { resultsPerPage: 12 },
				},
				{
					path: 'recentNotifications',
					component: MessagesHistory,
					name: 'dashboard-recent-notifications',
				},
			],
		},
		{
			path: '/404',
			name: '404',
			component: NotFound,
		},
		{
			path: '/:notFound(.*)',
			name: '404redirect',
			redirect: '/404',
		},
	],
	linkActiveClass: 'active',
});

router.beforeEach(async (to) => {
	const routeStepMap = stepHelper.getRouteStepMap()
	const applicationStore = useApplicationStore()

	await applicationStore.init()

	// always return the user to Review And Purchase if appplication was submitted, to force
	// user to click 'return to home' to clear application; this needs to be outside of the quote/application route check 
	// so we catch any route navigation, including to root (/)
	if (to.name != 'rap' && applicationStore.isSubmitted()) {
		return { name: 'rap' }
	}

	// prevent invalid step transitions within a quote or application
	if (to.name in routeStepMap) { // is this a quoting/application route?

		//whyApplying is the only quoting/application route allowed for non-existent apps. Always allow it.
		if (to.name == 'whyApplying') {
			return true
		}

		if (applicationStore.isExisting()) {
			let result = await apiStep.getValidStep(routeStepMap[to.name])

			if (!result.data) {
				return { name: 'root' }
			}
		} else {
			return { name: 'root' }
		}
	}
})

router.afterEach(async (to, from, failure) => {
	// for google analytics
	if (! failure) {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			'event': 'virtualPageview',
			'pageURL': to.fullPath,
			'pageTitle': to.name //some arbitrary name for the page/state
		})
	}

	const routeStepMap = stepHelper.getRouteStepMap()

	if (to.name in routeStepMap) { // is this a quoting/application route?
		const stepStore = useStepStore()
		const applicationStore = useApplicationStore()
		await applicationStore.init()

		if (applicationStore.isExisting()) {
			await stepStore.initStore()
			let result = await apiStep.getLastStep()
			stepStore.lastStep = result.data
		}
	}
})

const app = createApp(App);
const pinia = createPinia();
app.use(router);
app.use(pinia);
app.use(Maska);
app.directive('tooltip', tooltip)
app.mount('#app');

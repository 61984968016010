export default {
	getRouteStepMap() {
		return {
			'whyApplying': 1,
			'census': 2,
			'medical': 3,
			'dental': 4,
			'vision': 5,
			'review': 6,
			'subInfo': 7,
			'other': 8,
			'pcp': 9,
			'rap': 10,
		}
	},

	getRouteByStepId(id) {
		const routeMapping = {
			1: 'whyApplying',
			2: 'census',
			3: 'medical',
			4: 'dental',
			5: 'vision',
			6: 'review',
			7: 'subInfo',
			8: 'other',
			9: 'pcp',
			10: 'rap'
		}

		return routeMapping[id]
	}
}

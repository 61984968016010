<style>
	.days {
		line-height: 1;
		font-size: 5rem;
		font-weight: bold;
	}

	.text {
		font-size: 1rem;
	}
</style>

<div class="vld-parent"></div>

<template>
<div v-if="this.days" class="card vld-parent">
	<loading v-model:active="isLoading" :is-full-page="false"></loading>
	<div class="card-header">
		<h2 class="h4 mb-0">{{ headerText }}</h2>
	</div>
	<div class="card-body text-center py-4">
		<div class="days">{{ days }}</div>
		<div class="text">{{ message }}</div>
	</div>
</div>
</template>

<script>
import apiDashboard from "@/api/dashboard";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	emits: [],
	props: {
		oeType: {
			required: true,
			validator(value) {
				// The value must match one of these strings
				return ['individual', 'medicare'].includes(value)
			},
		},
	},
	components: {
		Loading,
	},

	data() {
		return {
			isLoading: null,
			days: null,
			message: null,
			headerText: null,
		}
	},

	created() {
		if (this.oeType === 'individual') {
			this.headerText = 'Individual';
		} else if (this.oeType === 'medicare') {
			this.headerText = 'Medicare';
		}
	},

	mounted() {
		this.loadData()
	},

	computed: {
	},

	methods: {
		async loadData() {
			let options = null

			try {
				this.isLoading = true

				if (this.oeType === 'individual') {
					const result = (await apiDashboard.getCountdownIndividual(options)).data
					this.days = result.days
					this.message = result.message
				} else if (this.oeType === 'medicare') {
					const result = (await apiDashboard.getCountdownMedicare(options)).data
					this.days = result.days
					this.message = result.message
				}

				this.isLoading = false
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},
	}
}
</script>

import apiHelper from '@/helpers/apiHelper';

export default {
	getPlans(planTypeId) {
		return apiHelper.appGet(`plans/${planTypeId}/allPlans`);
	},

	getPlanInfo() {
		return apiHelper.appGet(`plans/planInfo`);
	},

	getSelectedPlanId(planTypeId) {
		return apiHelper.appGet(`plans/${planTypeId}`);
	},

	selectPlan(planTypeId, data) {
		return apiHelper.appPut(`plans/${planTypeId}`, data);
	},

	getMetals(planTypeId) {
		return apiHelper.appGet(`plans/${planTypeId}/metals`);
	},

	getCarriers(planTypeId) {
		return apiHelper.appGet(`plans/${planTypeId}/carriers`);
	},

	getNetworks(planTypeId) {
		return apiHelper.appGet(`plans/${planTypeId}/networks`);
	},

	// HSA, etc (basically, the "Other" filter)
	getAttributes(planTypeId) {
		return apiHelper.appGet(`plans/${planTypeId}/attributes`);
	},

	getPrintPdf(planTypeId, compareAll, printFilters) {
		if (printFilters) {
			return apiHelper.appGet(`printPdf/${planTypeId}/${compareAll}/${printFilters}`, { responseType: 'blob'});
		} else {
			return apiHelper.appGet(`printPdf/${planTypeId}/${compareAll}`, {responseType: 'blob'});
		}
	},

	getSelectedPdf() {
		return apiHelper.appGet(`printSelectionPdf`, {responseType: 'blob'});
	}
};

<template>
	<div class="row gx-3">
		<div class="col-xxl-18 mb-3">
			<messages />
		</div>
		<div class="col-sm-18 col-md-12 col-xxl-8 mb-3">
			<countdown oe-type="individual" />
		</div>
		<div v-if="agentInfoStore.displayMedicare" class="col-sm-18 col-md-12 col-xxl-8 mb-3">
			<countdown oe-type="medicare" />
		</div>
	</div>
	<div class="card">
		<div class="card-header">
			<h2 class="h4 mb-0">Off Marketplace</h2>
		</div>
		<div class="card-body">
			<div class="row gx-3">
				<div class="col-xxl-18 mb-3">
					<div class="mb-3">
						<dashboard-quotes results-per-page="5" />
					</div>
					<div>
						<dashboard-enrollees results-per-page="5" />
					</div>
				</div>
				<div class="col-sm-18 col-md-12 col-xxl-12 mb-3">
					<enrollments-ytd />
				</div>
				<div class="col-sm-18 col-md-10 col-xxl-6 mb-3">
					<carrier-share />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DashboardQuotes from '@/components/modules/dashboard/DashboardQuotes.vue'
import DashboardEnrollees from '@/components/modules/dashboard/DashboardEnrollees.vue'
import EnrollmentsYtd from '@/components/modules/dashboard/EnrollmentsYTD.vue'
import CarrierShare from '@/components/modules/dashboard/CarrierShare.vue'
import Countdown from '@/components/modules/dashboard/Countdown.vue'
import Messages from '@/components/modules/dashboard/Messages.vue'
import { useAgentInfoStore } from '@/stores/agentInfo'
import {mapStores} from "pinia/dist/pinia";

export default {
	inject: ['startLoading', 'stopLoading'],
	components: {
		DashboardQuotes,
		DashboardEnrollees,
		EnrollmentsYtd,
		CarrierShare,
		Countdown,
		Messages,
	},
	created() {
	},
	computed: {
		...mapStores(useAgentInfoStore)
	},
}
</script>


<template>
<div class="col-sm-12 col-md-10 col-lg-8 col-xxl-5">
	<agent-dashboard-nav />
</div>
<div class="col-xxl-31">
	<!-- Adding npn key causes the component to reload when agent changes (used with agent login fabrication)  -->
	<!-- Without the isAuthenticated check, the update of agent branding (thus npn) on logout was trying to update this component even -->
	<!-- though we were at the home page, causing authentication errors. Not exactly sure why it was trying to update -B.S. -->
	<router-view v-if="isAuthenticated" :key="npn" />
</div>
</template>

<script>
import AgentDashboardNav from './modules/dashboard/AgentDashboardNav.vue';
import { useAgentInfoStore } from '@/stores/agentInfo'
import { mapState } from 'pinia'

export default {
	components: {
		AgentDashboardNav,
	},
	computed: {
		...mapState(useAgentInfoStore, ['npn', 'isAuthenticated']),
	},
}
</script>

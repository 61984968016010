import apiHelper from '@/helpers/apiHelper';

export default {
	dismissMessage(msgXrefId) {
		return apiHelper.put(`dashboard/dismissMessage/` + msgXrefId);
	},
	getMessages(options) {
		return apiHelper.get(`dashboard/getMessages`, options);
	},
	getOldMessages(options) {
		return apiHelper.get(`dashboard/getOldMessages`, options);
	},
	getAppInfo(appId, options) {
		return apiHelper.get(`dashboard/getAppInfo/${appId}`, options);
	},
	getCarrierShare(appId, options) {
		return apiHelper.get(`dashboard/carrierShare`, options);
	},
	getCountdownIndividual(options) {
		return apiHelper.get(`dashboard/countdownIndividual`, options);
	},
	getCountdownMedicare(options) {
		return apiHelper.get(`dashboard/countdownMedicare`, options);
	},
	getEnrollmentsYtd(options) {
		return apiHelper.get(`dashboard/enrollmentsYtd`, options);
	},
	getQuotes(options) {
		return apiHelper.get(`dashboard/quotes`, options);
	},
	getEnrollees(options) {
		return apiHelper.get(`dashboard/enrollees`, options);
	},
	getQuotesExport(options) {
		options.responseType = 'blob'
		return apiHelper.get(`dashboard/quotesExport`, options);
	},
	getEnrolleesExport(options) {
		options.responseType = 'blob'
		return apiHelper.get(`dashboard/enrolleesExport`, options);
	},
};

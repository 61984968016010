<template>
	<form id="main_form">
		<input type="hidden" name="path_id" id="path_id">
		<div id="front_outer_container">
			<div id="front_inner_container">
				<div class="card col-md-21 col-lg-15">
					<div class="card-body">
						<div v-if="agentInfoStore.isInitialized()">
							<div v-if="agentInfoStore.displayBCBSM || agentInfoStore.displayPriority">
								<h1 class="pt-3 pt-md-0">Individual health care plans tailored to fit your life.</h1>
								<p>Whether you&rsquo;re looking for insurance for yourself or your family, CoverageForOne has the coverage you want from a carrier you trust. Our site can help you get the health plan that best meets your needs, including plans available with office visits, prescription drug coverage, dental and maternity.</p>
								<div v-if="applicationStore.isInitialized()" class="d-flex justify-content-center">
									<div v-if="applicationStore.isExisting()">
										<div class="d-flex flex-column flex-sm-row">
											<button type="button" @click="continueQuote" class="getQuoteButton btn btn-default btn-primary btn-lg">Continue Quote</button>
											<button type="button" id="restart_btn" @click="restartApplication" class="btn btn-outline-primary btn-lg mt-3 mt-sm-0 ms-0 ms-sm-3">Restart Quote</button>
										</div>
									</div>
									<div v-else>
										<router-link to="/whyapplying" class="getQuoteButton btn btn-primary btn-lg">Get a Quote</router-link>
									</div>
								</div>
							</div>
							<div v-else-if="! agentInfoStore.agentFound()">
								<p class="alert alert-info">We&rsquo;re sorry &ndash; the web site address you used, <b>{{ this.baseUrl }}</b>, did not match one of our agents. Please check your records to be sure you typed it correctly and contact your agent if you continue to have trouble.</p>
							</div>
							<div v-else>
								<p class="alert alert-info">We&rsquo;re sorry &ndash; this agent does not support Coverage For One at this time.</p>
							</div>
						</div>
						<div v-else>
							<p>loading...</p>
						</div>

						<div class="row mt-3" v-if="agentInfoStore.agentFound()">
							<h2 class="h3">Presented by:</h2>
							<div class="col-md-18">
								<img class="img-fluid mb-3" :alt="agentInfoStore.agencyName + ' logo'" :src="agentInfoStore.logo" onerror="this.style.display='none'" />
							</div>
							<div>
								<strong>{{ agentInfoStore.fullName }}</strong><br>
								<span v-if="agentInfoStore.agencyName">
									<strong>{{ agentInfoStore.agencyName }}</strong><br>
								</span>
								<span v-if="agentInfoStore.address" v-html="agentInfoStore.address"></span>
								<br v-if="agentInfoStore.address">
								<span v-if="agentInfoStore.phone">Phone: <a :href="'tel:' + this.agentPhoneLink">{{ agentInfoStore.phone }}</a><br></span>
								<span v-if="agentInfoStore.fax">Fax: <a :href="'tel:' + this.agentFaxLink">{{ agentInfoStore.fax }}</a><br></span>
								<span v-if="agentInfoStore.email">Email: <a :href="'mailto:' + agentInfoStore.email">{{ agentInfoStore.email }}</a><br></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="fineprint mt-3">
		<div class="col">
			<div class="card">
				<div class="card-body">
					<p>This application may be used for 2023 and 2024 individual and family coverage through Blue Cross Blue Shield of Michigan (BCBSM), Blue Care Network of Michigan (BCN) or Priority Health, depending on which medical plan you choose. Dental plans, dental with vision, and adult vision plans are offered through BCBSM and Priority Health.</p>
					<p>Complete all fields unless otherwise noted. Review your application for accuracy, then sign and date.</p>
					<p class="mb-0">To get individual medical, dental, dental with vision, or adult vision coverage, you must be a Michigan resident when your coverage starts and intend to reside in Michigan. If you're eligible for Medicare, you’re not eligible for individual medical coverage.</p>
				</div>
			</div>
		</div>
	</div>

	<ResetModal ref="resetModal"></ResetModal>
	<RegisterModal ref="registerModal"></RegisterModal>

</template>

<script>
import { mapStores } from 'pinia'
import { useAgentInfoStore } from '@/stores/agentInfo'
import { useApplicationStore } from '@/stores/application'
import { useStepStore } from '@/stores/step';
import apiHelper from "@/helpers/apiHelper"
import stepHelper from '../helpers/stepHelper.js';
import generalHelper from '../helpers/generalHelper.js';
import ResetModal from "@/components/modules/ResetPassword.vue";
import RegisterModal from "@/components/modules/RegisterUser.vue";

export default {
	inject: ['startLoading', 'stopLoading'],
	components: {
		ResetModal,
		RegisterModal
	},

	data() {
		return {
			baseUrl: window.location.origin,
			currentStepUrl: 'whyapplying', //todo: update with current step url when steps are implemented
			tmp: '',
			result: null,
		}
	},

	mounted() {
		if (this.$route.name === 'reset-password') {
			this.$refs.resetModal.show()
		} else if (this.$route.name === 'register') {
			this.$refs.registerModal.show()
		}
	},

	created() {
	},

	computed: {
		...mapStores(useAgentInfoStore, useApplicationStore, useStepStore), // each store will be accessible as its id + 'Store'
		agentPhoneLink() {
			return generalHelper.formatClickableContactNumber(this.agentInfoStore.phone)
		},
		agentFaxLink() {
			return generalHelper.formatClickableContactNumber(this.agentInfoStore.fax)
		},
	},

	methods: {
		async restartApplication() {
			await apiHelper.get('application/active', {
				params: {
					'reset': true
				}
			})
			useApplicationStore().init(true)
			this.$router.push({name: 'whyApplying'})
		},

		continueQuote() {
			this.$router.push({name: stepHelper.getRouteByStepId(this.stepStore.lastStep)})
		},
	},
}
</script>

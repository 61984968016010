
/*
export const PlanTypes = {
	MEDICAL: 1,
	DENTAL: 2,
	VISION_MONTHLY: 3,
	VISION_ANNUAL: 4
};
 */
export default {
	data: {
		PlanTypes: {
			MEDICAL: 1,
			DENTAL: 2,
			VISION_MONTHLY: 3,
			VISION_ANNUAL: 4
		}
	}
}

<template>

	<div id="session_timeout_notice_modal" ref="sessionTimeoutNoticeModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="sessionTimeoutNoticeModalLabel" aria-hidden="true" data-bs-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="sessionTimeoutNoticeModalLabel">Your session is about to expire!</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="keepAlive"></button>
				</div>
				<div class="modal-body">
					<p class="alert alert-info">For protection of personal data, your session will expire in {{ this.remainingTime }} seconds due to inactivity.  Upon expiration any information entered so far will be cleared.  Please click continue to avoid expiration.</p>
				</div>
				<div class="modal-footer">
					<button id="session_keep_alive" class="btn btn-primary" @click="keepAlive">Continue</button>
				</div>
			</div>
		</div>
	</div>

	<div id="session_timeout_modal" ref="sessionTimeoutModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="sessionTimeoutModalLabel" aria-hidden="true" data-bs-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="sessionTimeoutModalLabel">Your session has expired.</h3>
				</div>
				<div class="modal-body">
					<p class="alert alert-warning">For protection of personal data, your session has expired.  Please click continue to start a new quote.</p>
				</div>
				<div class="modal-footer">
					<a href="/" class="btn btn-primary" @click="this.sessionTimeoutModal.hide()">Continue</a>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import { Modal } from "bootstrap";
import { useApplicationStore } from '@/stores/application';
import { useAgentInfoStore } from '@/stores/agentInfo';
import generalHelper from '@/helpers/generalHelper';
import apiHelper from '@/helpers/apiHelper';

export default {

	data() {
		return {
			intervalId: null,
			timeout: 0,
			warningThreshold: 0,
			sessionTimeoutNoticeModal: null,
			sessionTimeoutModal: null,
			remainingTime: 0,
		}
	},

	created() {
		if (process.env.VUE_APP_SESSION_LIFETIME) {
			this.timeout = parseInt(process.env.VUE_APP_SESSION_LIFETIME) * 60;
		}
		if (process.env.VUE_APP_SESSION_WARNING_THRESHOLD) {
			this.warningThreshold = parseInt(process.env.VUE_APP_SESSION_WARNING_THRESHOLD);
		}
	},

	mounted() {
		this.sessionTimeoutNoticeModal = new Modal(this.$refs.sessionTimeoutNoticeModal);
		this.sessionTimeoutModal = new Modal(this.$refs.sessionTimeoutModal);
		useApplicationStore().updateLastAccessTime();
		if (0 !== this.timeout) {
			this.intervalId = setInterval(() => {
				this.timeoutCheck();

			}, 1000);
		}
	},

	methods: {

		updateLastAccessTime() {
			useApplicationStore().updateLastAccessTime();
		},

		getIdleTime() {
			var lastAccess = useApplicationStore().getLastAccessTime();
			if (lastAccess) {
				return generalHelper.getTimestamp() - lastAccess;
			}
			return 0;
		},

		getRemainingTime() {
			let remainingTime = this.timeout - this.getIdleTime();
			return (remainingTime < 0) ? 0 : remainingTime;
		},

		isTimedOut() {
			return (0 !== this.timeout && this.getIdleTime() >= this.timeout);
		},

		isTimeoutWarning() {
			return (0 !== this.timeout && this.getIdleTime() >= (this.timeout - this.warningThreshold));
		},

		keepAlive() {
			let appStore = useApplicationStore();
			appStore.updateLastAccessTime();
			appStore.init(true);
		},

		stopInterval() {
			clearInterval(this.intervalId);
			this.intervalId = null;
		},

		enforceTimeout() {
			// todo: may want to consider what step of application to determine if personal data is at risk
			// enforce timeout if application is in progress or agent is logged in
			return useApplicationStore().isExisting() || useAgentInfoStore().isAuthenticated
		},

		timeoutCheck() {
			if (this.isTimedOut()) {
				if (this.enforceTimeout()) {
					this.stopInterval();
					apiHelper.get('application/timeout')
					this.sessionTimeoutNoticeModal.hide();
					this.sessionTimeoutModal.show();
					useAgentInfoStore().isAuthenticated = false
				} else {
					// todo: we'll need to keep-alive on server if not enforcing timeout during active application
					this.updateLastAccessTime();
				}
			} else {
				this.remainingTime = this.getRemainingTime();
				if (this.enforceTimeout()) {
					if (this.isTimeoutWarning()) {
						this.sessionTimeoutNoticeModal.show();
					} else {
						this.sessionTimeoutNoticeModal.hide();
					}
				}
			}
		},
	}
}

</script>

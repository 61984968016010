<template>
	<div class="row">
		<div class="col-18 col-md-9">
			<small>Individual Deductible:</small> <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="ttDedText"></i><h2 class="h5">{{ formatUSDwhole(plan.ded_ind) }}</h2>
			<small>Family Deductible:</small> <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="ttDedFamText"></i><h2 class="h5">{{ formatUSDwhole(plan.ded_fam) }}</h2>
		</div>
		<div class="col-18 col-md-9">
			<small>Max Individual Out-of-pocket: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttMaxOopText"></i></small> <h2 class="h5">{{ formatUSDwhole(plan.max_ind_oop) }}</h2>
			<small>Max Family Out-of-pocket: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttMaxOopText"></i></small> <h2 class="h5">{{ formatUSDwhole(plan.max_fam_oop) }}</h2>
		</div>
		<div class="col-36 col-md-6">
			<small>Coinsurance:</small> <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttCoinsText"></i><h2 class="h5">{{ plan.coins }}%</h2>
		</div>
		<hr class="d-block d-md-none my-3">
		<div class="col-36 col-md-12 d-flex flex-column align-items-end">
			<div class="mb-2"><span class="h2">{{ formatUSD(plan.rates.total) }}</span><div class="permo">per<br>month</div></div>
			<div class="mb-2" v-if="plan.is_hsa">
				<div class="form-check" v-if="allowSelect">
					<input @click="$emit('hsaEvent')" class="form-check-input" type="checkbox" :id="'hsa_' + plan.id" :value="plan.id" :checked="this.hsaSelections.indexOf(plan.id) !== -1">
					<label class="form-check-label" :for="'hsa_' + plan.id">Add Health Savings Account (HSA)</label>
				</div>
				<span v-if="allowChange" class="fw-bold mb-2"><i class="fa fa-check"></i> Add Health Savings Account (HSA)</span>
			</div>
			<template v-if="allowSelect">
				<button @click="$emit('selectEvent')" class="btn btn-primary" v-if="selectedPlanId != plan.id">Select</button>
				<button class="btn btn-outline-primary" disabled="disabled" v-if="selectedPlanId == plan.id">Selected</button>
				<button @click="$emit('selectEvent')" class="mt-2 btn btn-primary" v-if="selectedPlanId == plan.id">Continue</button>
			</template>
			<template v-if="allowChange">
				<router-link :to="{ name: 'medical' }" id="change_plan_medical" class="btn btn-outline-primary">Change Plan</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import generalHelper from '@/helpers/generalHelper'

export default {
	props: {
		plan: {
			required: true
		},
		allowSelect: {
			required: true
		},
		allowChange: {
			required: true
		},
		selectedPlanId: {
		},
		hsaSelections: {
		},
	},
	data() {
		return {
			ttMaxOopText: 'Includes deductible, coinsurance and copays.',
			ttDedText: 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>An individual deductible applies to each person covered by insurance.',
			ttDedFamText: 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>Each family member has an individual deductible that counts toward the family deductible.  If the family’s combined medical expenses meet the family deductible, insurance begins paying for any family member.',
			ttCoinsText: 'The percentage of the cost for medical services you have to pay after you have met your deductible.',
		}
	},
	mounted() {
	},
	methods: {
		formatUSD: generalHelper.formatUSD,
		formatUSDwhole: generalHelper.formatUSDwhole,
	}
}
</script>

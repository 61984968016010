
import { useApplicationStore } from '@/stores/application';
import Carrier from '@/enums/carrier';
import PlanTypes from '@/enums/plantype';

export default {
	mixins: [ Carrier, PlanTypes ],
	methods: {
		// has selected carrier of any plan type
		appContext_carrier(carrierId) {
			switch (carrierId) {
				case useApplicationStore().medicalCarrierId:
				case useApplicationStore().dentalCarrierId:
				case useApplicationStore().visionCarrierId:
					return true;
			}
			return false;
		},

		// has selected plan type of any carrier
		appContext_planType(planTypeId) {
			if (
				planTypeId === this.PlanTypes.MEDICAL && useApplicationStore().medicalCarrierId ||
				planTypeId === this.PlanTypes.DENTAL && useApplicationStore().dentalCarrierId ||
				planTypeId === this.PlanTypes.VISION && useApplicationStore().visionCarrierId
			) {
				return true;
			}
			return false;
		},

		// has selected specific carrier/plan type
		appContext_carrierPlanType(carrierId, planTypeId) {
			switch (planTypeId) {
				case this.PlanTypes.MEDICAL:
					return (carrierId === useApplicationStore().medicalCarrierId);
				case this.PlanTypes.DENTAL:
					return (carrierId === useApplicationStore().dentalCarrierId);
				case this.PlanTypes.VISION:
					return (carrierId === useApplicationStore().visionCarrierId);
			}
			return false;
		},

		// is app for specified enrollment period
		appContext_enrollPeriod(enrollPeriodId) {
			return (enrollPeriodId === useApplicationStore().enrollmentPeriodId);
		},

		// is open enrollment period
		appContext_isOep() {
			// todo: replace with enums
			return (1 === useApplicationStore().enrollmentPeriodTypeId);
		},

		// is special enrollment period
		appContext_isSep() {
			// todo: replace with enums
			return (2 === useApplicationStore().enrollmentPeriodTypeId);
		}
	}
}

//export { carrier, planType, carrierPlanType, enrollPeriod, isOep, isSep };

import { defineStore } from 'pinia'

import apiStep from "../api/step";

export const useStepStore = defineStore('step', {
	state: () => ({
		initialized: false,
		lastStep: null,
	}),

	actions: {
		initStore() {
			if (this.initialized) {
				return Promise.resolve()
			} else {
				try {
					// return the Promise so we can chain setting last step if needed
					return apiStep.getLastStep().then(result => {
						this.lastStep = result.data
						this.initialized = true
					})
				} catch (error) {
					console.log('initStore api error')
					console.log(error)
				}
			}
		},
	},
})

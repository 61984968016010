<template>
	<div class="row">
		<div class="col-18 col-md-12">
			<small>Eye Exam Copay: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttCopayText"></i></small> <h2 class="h5">{{ formatUSDwhole(plan.eye_exam_copay) }}</h2>
			<small>Frames and Contact Lens Allowance:</small> <h2 class="h5">{{ formatUSDwhole(plan.frames_contact_allowance) }}</h2>
		</div>
		<div class="col-18 col-md-12">
			<small>Adult Eye Exam Frequency:</small> <h2 class="h5">{{ plan.eye_exam_frequency }}</h2>
		</div>
		<hr class="d-block d-md-none my-3">
		<div class="col-36 col-md-12 d-flex flex-column align-items-end">
			<div class="mb-2"><span class="h2">{{ formatUSD(plan.rates.total) }}</span><div class="permo">per<br>month</div></div>
			<template v-if="allowSelect">
				<button @click="$emit('selectEvent')" class="btn btn-primary" v-if="selectedPlanId != plan.id">Select</button>
				<button class="btn btn-outline-primary" disabled="disabled" v-if="selectedPlanId == plan.id">Selected</button>
				<button @click="$emit('selectEvent')" class="mt-2 btn btn-primary" v-if="selectedPlanId == plan.id">Continue</button>
			</template>
			<template v-if="allowChange">
				<router-link :to="{ name: 'vision' }" id="change_plan_vision" class="btn btn-outline-primary">Change Plan</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import generalHelper from '@/helpers/generalHelper'

export default {
	props: {
		plan: {
			required: true
		},
		allowSelect: {
			required: true
		},
		allowChange: {
			required: true
		},
		selectedPlanId: {
		},
	},
	data() {
		return {
			ttCopayText: 'The fixed amount paid for a covered service.',
		}
	},
	methods: {
		formatUSD: generalHelper.formatUSD,
		formatUSDwhole: generalHelper.formatUSDwhole,
	}
}
</script>

<template>

<form @submit.prevent class="">
	<h1 class="page_title h2">Medical Plans</h1>

	<div class="d-flex flex-column flex-lg-row justify-content-end mb-3">
		<div class="mb-2 mb-lg-0">
			<button id="print_comp_button" class="btn btn-outline-secondary btn-sm btn-block" @click="printCompare(1, false, printFilters)" :disabled="compareButtonDisable"><i class="fa fa-print"></i>&nbsp;Print a Medical Plan Comparison Sheet</button>
		</div>
		<div class="ms-lg-2">
			<button id="print_all_comp_button" class="btn btn-outline-secondary btn-sm btn-block" @click="printCompare(1, true, printFilters)" :disabled="compareButtonDisable"><i class="fa fa-print"></i>&nbsp;Print a Medical, Dental & Vision Comparison Sheet</button>
		</div>
	</div>

	<p class="fst-italic fw-light lh-sm m-0 mb-3">Premiums are charged for the subscriber, spouse, all adult children age 21 and older, and for the three oldest dependent children under age 21. Child only policies are available on all plans below.</p>
	<p class="fst-italic fw-light lh-sm m-0 mb-3">Pediatric vision benefits are included in all medical plans.</p>
	<p class="fst-italic fw-light lh-sm m-0 mb-3">Your network of affiliated doctors and hospitals may be different based on the carrier and product. Links to covered doctors, hospitals, and prescriptions can be found at the bottom of each plan below.</p>
	<div class="align-items-center bg-white mb-2 py-2 filters_container" v-if="hasPlans">
		<button class="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
			<i class="fa fa-filter"></i> Filters
		</button>
		<button type="button" class="btn btn-secondary ms-2" id="compare-button" :disabled="comparedPlans.length < 2" data-bs-toggle="modal" data-bs-target="#compareModal">
			Compare Selected Plans
		</button>
		<span id="compare-limit-warning" class="d-inline-block fw-bold fst-italic mx-3 mt-2" v-if="comparedPlans.length >= 6">You selected the maximum number of plans to compare (6).</span>

		<div class="collapse" id="collapseFilter">
			<div class="filters_area">
				<div id="pills_description" class="visually-hidden">Optional Plan Filter: Limit the available plans to the type you choose.</div>

				<div class="row">
					<div class="col-md-36 col-lg-24 col-xl-21">
						<div v-for="filter in filters" :key="filter.id">
							<div class="row" v-if="filter.options.length > 0">
								<h2 class="h6 col-md-12 col-lg-9 col-xl-6 d-inline"><b>{{ filter.description }}</b></h2>
								<div class="col-md-24 col-lg-27 col-xl-30">
									<div class="form-check form-check-inline" v-for="option in filter.options" :key="option.id">
										<input class="form-check-input" type="checkbox" :id="'filter_' + filter.short + option.id" :value="option.id" v-model="$data[filter.filterArray]">
										<label class="form-check-label" :for="'filter_' + filter.short + option.id">{{ option.name }}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-36 col-lg-12 col-xl-12">
						<label for="price_max" class="form-label mb-0"><b class="me-3">Maximum Price:</b>${{ filterMaxPrice }} per month</label>
						<input type="range" class="form-range" :min="planRangeMin" :max="planRangeMax" step="10" id="price_max" v-model="filterMaxPrice">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-for="plan in filteredPlans" :key="plan.id" class="card mt-4" :class="{ 'border-primary': selectedPlanId == plan.id }">
		<div class="plan t_all">
			<div class="card-header d-flex justify-content-between align-items-center">
				<h2 class="h4 col-24 m-0" v-html="plan.name"></h2>
				<div class="form-check m-0">
					<input class="form-check-input" :id="'compare_' + plan.id" type="checkbox" :disabled="comparedPlans.length >= 6 && !plan.compare" v-model="plan.compare">
					<label class="form-check-label text-center lh-sm" :for="'compare_' + plan.id">Compare Plan</label>
				</div>
			</div>
			<div class="card-body">
				<medical-plan :plan="plan" :allowSelect="true" :allowChange="false" :selectedPlanId="selectedPlanId" :hsaSelections="hsaSelections" @select-event="selectPlan(plan.id)" @hsa-event="selectHsa(plan.id)"/>
			</div>
			<plan-footer :plan="plan" :showRx="true" />
		</div>
	</div>
	<sorry-no-plans v-if="plansLoaded && ! hasPlans" />

	<div class="d-flex justify-content-between mt-4">
		<router-link :to="{ name: 'census' }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>
	</div>
</form>

	<!-- Modal -->
	<div class="modal fade" id="compareModal" tabindex="-1" aria-labelledby="compareModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="h3 modal-title" id="compareModalLabel">Compare Plans</h2>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="table-responsive" v-show="comparedPlans.length > 0">
						<table class="table m-0">
							<thead>
								<tr>
									<th><span class="sr-only">Plan</span></th>
									<th>In-Network Deductible (Individual <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="ttInDedIndText"></i> / Family <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="ttInDedFamText"></i>)</th>
									<th>Out-of-Network Deductible (Individual <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="ttOutDedIndText"></i> / Family <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="ttOutDedFamText"></i>) </th>
									<th>Coinsurance <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttCoinsuranceText"></i></th>
									<th>Out-of-pocket Max <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttMaxOopText"></i></th>
									<th>Monthly Premium</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="plan in comparedPlans" :key="plan.id">
									<td><b v-html="plan.name"></b></td>
									<td>{{ formatUSDwhole(plan.ded_ind) }} / {{ formatUSDwhole(plan.ded_fam) }}</td>
									<td>{{ formatUSDwhole(plan.ded_out_ind) }} / {{ formatUSDwhole(plan.ded_out_fam) }}</td>
									<td>{{plan.coins}}%</td>

									<td v-if="family">{{ formatUSDwhole(plan.max_fam_oop) }}</td>
									<td v-if="p2">{{ formatUSDwhole(plan.max_2p_oop) }}</td>
									<td v-if="ind">{{ formatUSDwhole(plan.max_ind_oop) }}</td>

									<td>{{ formatUSD(plan.rates.total) }}</td>
									<td>
										<button type="button" @click.stop="removeCompare(plan)" class="btn btn-sm btn-danger" :value="plan.id">
											<i class="fa fa-times"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-show="comparedPlans.length === 0" class="text-center">
						Please choose plans to compare
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline-secondary btn-block" @click="printCompare(1, false, comparedPlanIds)" :disabled="compareButtonDisable"><i class="fa fa-print"></i>&nbsp;Print this comparison</button>
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import generalHelper from '../helpers/generalHelper'
import planHelper from '../helpers/planHelper'
import api from "@/api/plan"
import {useApplicationStore} from "@/stores/application"
import Carriers from "@/enums/carrier";
import PlanFooter from './modules/PlanFooter'
import MedicalPlan from './modules/MedicalPlan'
import SorryNoPlans from './snippets/SorryNoPlans'

export default {
    inject: ['startLoading', 'stopLoading'],
	mixins: [ Carriers ],
	components: {
		PlanFooter,
		MedicalPlan,
		SorryNoPlans,
	},
	data() {
		const ttDedIndText = 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>An individual deductible applies to each person covered by insurance.'

		return {
			errorMessage: '',
			plans: [],
			plansLoaded: false,
			filters: [
				{
					id: 1,
					description: 'Carrier',
					short: 'car',
					filterArray: 'filterCarriers',
					options: []
				},
				{
					id: 2,
					description: 'Network',
					short: 'net',
					filterArray: 'filterNetworks',
					options: []
				},
				{
					id: 3,
					description: 'Metal',
					short: 'met',
					filterArray: 'filterMetals',
					options: []
				},
				{
					id: 4,
					description: 'Other',
					short: 'other',
					filterArray: 'filterOthers',
					options: []
				}
			],
			filterCarriers: [],
			filterNetworks: [],
			filterMetals: [],
			filterOthers: [],
			filterMinPrice: '',
			filterMaxPrice: '',
			planRangeMin: null,
			planRangeMax: null,
			compareButtonDisable: false,
			selectedPlanId: null,
			hsaSelections: [],

			ind: false,
			p2: false,
			family: false,

			ttMaxOopText: 'Includes deductible, coinsurance and copays.',
			ttCoinsuranceText: 'Coinsurance is the percentage of the cost for medical services you have to pay after you have met your deductible.',
			ttInDedIndText: ttDedIndText,
			ttInDedFamText: 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>Each family member has an individual deductible that counts toward the family deductible. If the family’s combined in-network medical expenses meet the family deductible, insurance begins paying for any family member.',
			ttOutDedIndText: ttDedIndText,
			ttOutDedFamText: 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>Each family member has an individual deductible that counts toward the family deductible. If the family’s combined out-of-network medical expenses meet the family deductible, insurance begins paying for any family member.',
		}
	},

	created() {
		//start/stop loading (indicator) takes place in planHelper's loadPlans function
		this.startLoading() // todo: looks like this can be removed per comment above? -B.S.
		useApplicationStore().init().then(async () => {
			await this.loadPlans(1)
			this.plansLoaded = true
			await this.loadFilterValues(1)
			await this.loadForm()
			this.stopLoading() // todo: looks like this can be removed per comment above? -B.S.
		})
	},

	mounted() {
	},

	methods: {
		async updateForm() {
			this.startLoading()
			try {
				let formData = {
					'selected_plan_id': this.selectedPlanId,
					'add_hsa': this.hsaSelections.indexOf(this.selectedPlanId) !== -1
				}

				await api.selectPlan(1, formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.errorMessage = 'Sorry, something went wrong'
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
			if (!this.errorMessage) {
				this.$router.push({ name: 'dental' })
			}
			this.stopLoading()
		},

		async loadForm() {
			try {
				if (this.hasPlans) {
					const result = (await api.getSelectedPlanId(1)).data.data
					this.selectedPlanId = result.medical_product_id
					this.setPlanCostFlags(parseInt(this.plans[0].rates.members.length));

					if(result.add_hsa) {
						this.hsaSelections.push(this.selectedPlanId)
					}
				}
			} catch (error) {
				window.scrollTo(0, 0)
				this.errorMessage = error.message || 'Something went wrong!'
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},

		filterPlansByMetal: function(plans) {
			return this.filterMetals.length ? plans.filter(plan => this.filterMetals.includes(plan.metal)) : plans
		},
		filterPlansByOther: function(plans) {
			let finalPlans = plans

			//hsa
			if (this.filterOthers.includes(1)) {
				finalPlans =  finalPlans.filter(plan => plan.is_hsa)
			}

			return finalPlans
		},

		selectPlan(planId = null) {
			this.selectedPlanId = planId
			this.updateForm()
		},

		selectHsa(planId = null) {
			if(this.hsaSelections.indexOf(planId) === -1) {
				this.hsaSelections.push(planId)
			} else {
				this.hsaSelections.splice(this.hsaSelections.indexOf(planId), 1)
			}
		},

		setPlanCostFlags(param_count) {
			switch(true) {
				case param_count === 1:
					this.ind = true;
					this.p2 = false;
					this.family = false;
					break;

				case param_count === 2:
					this.ind = false;
					this.p2 = true;
					this.family = false;
					break;

				case param_count > 2:
					this.ind = false;
					this.p2 = false;
					this.family = true;
					break;
			}
		},

		//these function need to be called out specifically here in order to access this component's data
		loadPlans: planHelper.loadPlans,
		loadFilterValues: planHelper.loadFilterValues,
		printCompare: planHelper.printCompare,
		printCompareSelections: planHelper.printCompareSelections,
		removeCompare: planHelper.removeCompare,
		//--------------------------------------------
		formatUSD: generalHelper.formatUSD,
		formatUSDwhole: generalHelper.formatUSDwhole,
	},
	computed: {
		hasPlans() {
			return this.plans.length > 0
		},
		filteredPlans() {
			let returnPlans = this.plans;

			returnPlans = planHelper.filterPlansByCarrier(returnPlans, this.filterCarriers)
			returnPlans = planHelper.filterPlansByNetwork(returnPlans, this.filterNetworks)
			returnPlans = this.filterPlansByMetal(returnPlans)
			returnPlans = this.filterPlansByOther(returnPlans)
			returnPlans = planHelper.filterPlansByPrice(returnPlans, this.filterMinPrice, this.filterMaxPrice)

			if(returnPlans.length > 0) {
				returnPlans.sort((obj1, obj2) => {
					return obj1.rates.total - obj2.rates.total
				})

				if (this.selectedPlanId != null) {
					let temp_array = [];
					let temp_sel_index = returnPlans.map(object => object.id).indexOf(this.selectedPlanId);
					temp_array = returnPlans.splice(temp_sel_index, 1);
					returnPlans.unshift(temp_array[0]);
				}
			}

			return returnPlans
		},
		comparedPlans() {
			return this.plans.filter(plan => plan.compare)
		},
		comparedPlanIds() {
			let ids = []

			this.comparedPlans.forEach(plan => {
				ids.push('plan_' + plan.id)
			})

			return ids
		},
		printFilters() {
			let filters = []

			this.filterCarriers.forEach(choice => {
				filters.push('car_' + choice)
			})
			this.filterNetworks.forEach(choice => {
				filters.push('net_' + choice)
			})
			this.filterMetals.forEach(choice => {
				filters.push('m_' + choice)
			})
			this.filterOthers.forEach(choice => {
				if (choice === 1) {
					filters.push('hsa')
				}
			})
			if (this.filterMinPrice) {
				filters.push('minPrice:' + this.filterMinPrice)
			}
			if (this.filterMaxPrice) {
				filters.push('maxPrice:' + this.filterMaxPrice)
			}

			return filters
		},
	},
	watch: {
		plans(newPlans) {
			let minRate = null
			newPlans.forEach((plan) => {
				if (minRate == null || minRate > plan.rates.total) {
					minRate = plan.rates.total
				}
			})

			this.planRangeMin = Math.ceil(minRate/10) * 10

			let maxRate = null
			newPlans.forEach((plan) => {
				if (maxRate == null || maxRate < plan.rates.total) {
					maxRate = plan.rates.total
				}
			})

			this.planRangeMax = Math.ceil(maxRate/10) * 10
			this.filterMaxPrice = this.planRangeMax
		}
	}
}
</script>

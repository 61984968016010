import apiHelper from '@/helpers/apiHelper';

export default {
	login(data) {
		return apiHelper.post(`login`, data);
	},

	logout() {
		return apiHelper.post(`logout`);
	},

	forgot(data) {
		return apiHelper.post(`forgot-password`, data);
	},

	register(data) {
		return apiHelper.post(`register`, data);
	},

	reset(data) {
		return apiHelper.post(`reset-password`, data);
	},

	changePassword(data) {
		return apiHelper.put(`user/password`, data);
	},

	getUserByToken(token) {
		return apiHelper.get(`get-user/${token}`);
	},

	getAuthState() {
		return apiHelper.get(`get-auth-state`);
	},
};

<template>
	<p v-if="! isAuthenticated" class="mb-3 py-2 alert alert-info text-center">Please note: By continuing to use this site, you consent to our <a href="javascript:;" @click="showTermsOfUseModal()">Terms of Use</a>.</p>
	<form id="main_form" @submit.prevent="submitForm" @keydown.enter="$event.preventDefault()">
		<div class="contents">
			<h1 class="h2 page_title">Why are you applying?</h1>

			<validation-error-msg v-if="Object.keys(validationErrors).length !== 0"></validation-error-msg>

			<div class="card mb-3">
				<div class="card-body">
					<div class="form-check" v-if="oepAvailable">
						<input type="radio" name="enrollment_reason" value="oe" id="enrollment_reason_oe" class="form-check-input" v-model="enrollmentType">
						<label for="enrollment_reason_oe" class="form-check-label">
							Open Enrollment ({{ oepStartDate }} &ndash; {{ oepEndDate }}) <span id="effectiveDate" v-show="enrollmentType === 'oe'"><strong>The policy&rsquo;s effective date will be {{ oepEffectiveDate }}</strong></span>
						</label>
					</div>
					<div class="form-check" v-if="sepAvailable">
						<input type="radio" name="enrollment_reason" value="qle" id="enrollment_reason_qle" class="form-check-input" v-model="enrollmentType">
						<label for="enrollment_reason_qle" class="form-check-label">
							I have a qualifying event, loss of coverage, or am planning to move to Michigan
						</label>
					</div>
					<div class="form-check" v-if="this.displayBCBSM">
						<input type="radio" name="enrollment_reason" value="vision" id="enrollment_reason_vision" class="form-check-input" v-model="enrollmentType">
						<label for="enrollment_reason_vision" class="form-check-label">
							Adult-only vision coverage (doesn't require a qualifying event) <span id="vision_effectiveDate" v-show="enrollmentType === 'vision'"><strong>The policy&rsquo;s effective date will be {{ dentalVisionEffectiveDate }}</strong></span>
						</label>
					</div>
					<div class="form-check" v-if="this.displayBCBSM">
						<input type="radio" name="enrollment_reason" value="dental" id="enrollment_reason_dental" class="form-check-input" v-model="enrollmentType">
						<label for="enrollment_reason_dental" class="form-check-label">
							Dental or Dental with Vision coverage (doesn't require a qualifying event) <span id="dental_effectiveDate" v-show="enrollmentType === 'dental'"><strong>The policy&rsquo;s effective date will be {{ dentalVisionEffectiveDate }}</strong></span>
						</label>
					</div>
				</div>
			</div>

			<div class="card mb-3" v-show="enrollmentType && enrollmentType === 'qle'">
				<h3 class="card-header h5">Qualifying Event</h3>

				<div class="card-body">
					<div :class="hasError('enrollment_reason_id')">
						<div class="row row-cols-1 row-cols-lg-2 gx-2 p-0 m-0 form_check_group">
							<div class="col" v-for="(qleGroup, index) in qleGroups" :key="index">
								<div class="form-check" v-for="qle in qleGroup" :key="qle.id">
									<input :id="`qle_${qle.id}`" class="form-check-input" type="radio" :value="`${qle.id}`" name="qle_rb" v-model="enrollmentReasonId">
									<label class="form-check-label" :for="`qle_${qle.id}`">{{ qle.description }}</label>
									<div v-if="qle.note">
										<p class="form-text m-0">{{ qle.note }}</p>
									</div>
								</div>
							</div>
						</div>
						<span id="help_block_enrollment_reason_id" v-for="(msg, index) in validationErrors['enrollment_reason_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>

					<div class="row mt-3">
						<div id="other_specify_row" class="col col-lg-18" v-show="enrollmentReasonId === '19'">
							<label class="form-label" for="other_details">Describe the event</label>
							<input class="form-control form-control-sm" maxlength="1000" name="other_details" type="text" id="other_details" v-model="qleOtherDetails">
						</div>
					</div>

					<div class="row mt-3">
						<div class="col col-lg-18" :class="hasError('qle_event_date')">
							<label class="form-label">Date of event</label>
							<input type="date" id="event_date" class="form-control form-control-sm" v-model="qleEventDate">
							<span id="help_block_event_date" v-for="(msg, index) in validationErrors['qle_event_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
							<div v-if="enrollmentReasonId">
								<p class="form-text">Date must be within the last 60 days<span v-show="allowFutureEventDate"> or the next 60 days</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card border-secondary mb-3" v-show="enrollmentReasonId && enrollmentType && enrollmentType === 'qle'">
				<h3 class="card-header h5">Acceptable Documentation</h3>

				<div class="card-body">
					<ul class="mb-0">
						<li v-for="doc in supportingDocDisplay" :key="doc.id" v-html="doc.description"></li>
					</ul>
				</div>
			</div>

			<div class="card mb-3" v-show="enrollmentReasonId && enrollmentType && enrollmentType === 'qle'">
				<h3 class="card-header h5">Submit Documentation</h3>

				<div class="card-body">
					<div class="alert alert-warning">
						Supporting documentation is required before being approved for coverage and if not included with your application, it must be received within 30 days.
					</div>
					<div class="card border-secondary">
						<div class="card-body">
							<div class="row">
								<label for="input_file_upload" class="col-md-12 col-lg-9 col-xl-6 col-form-label pt-md-3 text-md-end">Upload PDF file</label>
								<div class="col-md-24 col-lg-18">
									<div class="input-group mt-2">
										<button type="button" id="input_file_upload_btn" class="btn btn-secondary" @click="chooseFiles()">Choose File</button>
										<input type="file" id="input_file_upload" accept="application/pdf" @change="onFileChange" data-label="Browse for file" hidden>
										<input type="text" id="file-input-name" readonly="readonly" class="form-control fw-light fst-italic" :value="enrollmentReasonFileName">
									</div>
									<div class="form-text">Please note: File is limited to 3 MB</div>
								</div>
							</div>
							<p class="alert alert-danger mb-0 mt-3" v-show="showFileSizeAlert">The file you chose is larger than the maximum 3 MB allowed. If you cannot upload a smaller file, please submit your documentation using one of the methods below.</p>
						</div>
					</div>
					<p class="mt-4">If you do not have the supporting documentation at this time or cannot upload a file, submit it promptly to:</p>

					<div class="row g-3" :class="submitDocColumns">
						<div v-if="this.displayBCBSM">
							<div class="card">
								<h3 class="card-header h5" v-if="this.displayPriority">Blue Cross Blue Shield of Michigan</h3>
								<div class="card-body">
									<h4 class="h6 fw-bold mb-1">Email</h4>
									<a href="mailto:IBUenrollment@bcbsm.com?subject=QLE Supporting Documents" class="text-decoration-none">IBUenrollment@bcbsm.com</a><br>
									Make sure "QLE Supporting Documents" is in the subject line of your email. Your supporting documentation must include the primary applicant's first name, last name, date of birth, phone number, and application submission date.
									<h4 class="h6 fw-bold mt-3 mb-1">Fax</h4>
									1-877-486-2172<br>
									<h4 class="h6 fw-bold mt-3 mb-1">Mail</h4>
									<address class="mb-0">Individual Membership and Billing<br> Blue Cross Blue Shield of Michigan<br>600 E. Lafayette Blvd., Mail Code 610B<br>Detroit, MI 48226-2998</address>
								</div>
							</div>
						</div>
						<div v-if="this.displayPriority">
							<div class="card">
								<h3 class="card-header h5" v-if="this.displayBCBSM">Priority Health</h3>
								<div class="card-body">
									<h4 class="h6 fw-bold mb-1">Email</h4>
									<a href="mailto:mypriority@priorityhealth.com" class="text-decoration-none">mypriority@priorityhealth.com</a>
									<h4 class="h6 fw-bold mt-3 mb-1">Fax</h4>
									1-248-324-2973<br>
									Attention: MyPriority
									<h4 class="h6 fw-bold mt-3 mb-1">Mail</h4>
									<address class="mb-0">Priority Health<br>Individual Operations<br>27777 Franklin Road, Ste. 1300<br>Southfield, MI 48034</address>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card mb-3" v-show="enrollmentReasonId && enrollmentType && enrollmentType === 'qle'">
				<h3 class="card-header h5">When would you like coverage to start?</h3>

				<div class="card-body">
					<p id="event_date_reminder" v-if="!startDatesAvailable"><b>Please enter a valid Date of Event.</b></p>

					<div v-else :class="hasError('start_date_option_id')">
						<div class="form_check_group">
							<div class="form-check start_date_selection" id="start_date_selection_event_date" v-show="qleEventDate && showDateOfEvent">
								<input id="start_date_date_of_event" class="form-check-input" type="radio" value="1" name="start_date" v-model="startDateOptionId">
								<label class="form-check-label" for="start_date_date_of_event">Date of event</label>
							</div>
							<div class="form-check start_date_selection" id="start_date_selection_first_day_month_following_application" v-show="qleEventDate && showFirstDayMonthFollowingApplication">
								<input id="start_date_first_day_month_following_application" class="form-check-input" type="radio" value="2" name="start_date" v-model="startDateOptionId">
								<label class="form-check-label" for="start_date_first_day_month_following_application">First of the month following the date of application</label>
							</div>
							<div class="form-check start_date_selection" id="start_date_selection_day_after_loss_of_coverage" v-show="qleEventDate && showDayAfterLossOfCoverage">
								<input id="start_date_day_after_loss_of_coverage" class="form-check-input" type="radio" value="3" name="start_date" v-model="startDateOptionId">
								<label class="form-check-label" for="start_date_day_after_loss_of_coverage">Day after loss of coverage date</label>
							</div>
							<div class="form-check start_date_selection" id="start_date_selection_first_day_month_following_loss_of_coverage" v-show="qleEventDate && showFirstDayMonthFollowingLossOfCoverage">
								<input id="start_date_first_day_month_following_loss_of_coverage" class="form-check-input" type="radio" value="4" name="start_date" v-model="startDateOptionId">
								<label class="form-check-label" for="start_date_first_day_month_following_loss_of_coverage">First of the month following loss of coverage</label>
							</div>
						</div>
						<span id="help_block_start_date_option_id" v-for="(msg, index) in validationErrors['start_date_option_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg" v-show="enrollmentType">Continue <i class="fa fa-arrow-right"></i></button>
			</div>
		</div>
	</form>
</template>

<script>
import generalHelper from '../helpers/generalHelper'
import apiWhyApplying from '../api/whyApplying';
import { mapState } from 'pinia'
import { useAgentInfoStore } from "@/stores/agentInfo";
import { useApplicationStore } from '@/stores/application';
import ValidationErrorMsg from './snippets/ValidationErrorMsg'

const OPEN_ENROLLMENT = "1"
const BIRTH = "2"
const ADOPTION = "3"
const LEGAL_GUARDIANSHIP = "4"
const GAINING_OR_BECOMING_A_DEPENDENT = "5"
const MARRIAGE = "6"
const LOSS_OF_EMPLOYER_SPONSORED_GROUP_COVERAGE = "7"
const DIVORCE_OR_LEGAL_SEPARATION = "8"
const DEATH_OF_POLICY_HOLDER = "9"
const DEPENDENT_AGING_OFF_OR_LOSS_OF_COVERAGE = "10"
const INVOLUNTARY_LOSS_FROM_MEDICAID_OR_CHIP = "11"
const NEWLY_INELIGIBLE_FOR_APTC_OR_CSR = "12"
const LOSS_OF_STUDENT_HEALTH_PLAN = "13"
const BECAME_ELIGIBLE_FOR_MEDICARE = "14"
const EXHAUSTION_OF_COBRA_BENEFITS = "15"
const MOVED_OUT_OF_PLAN_COVERAGE_AREA = "16"
const GAINED_ACCESS_DUE_TO_MOVE = "17"
const LOSS_MARKETPLACE_DENTAL = "18"
const OTHER_EVENT = "19"
const ADULT_ONLY_VISION = "20"
const DENTAL_OR_DENTAL_WITH_VISION = "21"

export default {
	name: "WhyApplying",
	inject: ['showTermsOfUseModal', 'startLoading', 'stopLoading'],
	components: {
		ValidationErrorMsg,
	},
	data() {
		return {
			carrierID: "1",
			oepAvailable: false,
			sepAvailable: false,
			oepStartDate: '',
			oepEndDate: '',
			oepEffectiveDate: '',
			dentalVisionEffectiveDate: '',

			qles: [],
			supportingDocCopy: [],
			allowFutureEventDate: false,

			enrollmentType: null,
			enrollmentReasonId: null,
			qleOtherDetails: '',
			qleEventDate: '',
			startDateOptionId: null,
			file: null,
			enrollmentReasonFileName: '',

			validationErrors: {},
			errorMessage: null,
			showFileSizeAlert: false,
		}
	},
	created() {
		this.startLoading()
		useApplicationStore().init(true).then(async () => {
			await this.initForm();
			await this.loadForm()
			this.stopLoading()
		})
	},
	mounted() {
	},
	methods: {
		submitForm() {
			this.updateForm()
		},

		async updateForm() {
			this.validationErrors = {};
			this.errorMessage = null;

			this.startLoading();
			try {
				const subdomain = generalHelper.getSubdomain()

				let formData = {
					'subdomain': subdomain,
					'agree_tc': false,
					'enrollment_type': this.enrollmentType,
					'enrollment_reason_id': this.enrollmentReasonId,
					'qle_other_details': this.qleOtherDetails,
					'qle_event_date': this.qleEventDate,
					'start_date_option_id': this.startDateOptionId,
					'oe_effective_date': this.oepEffectiveDate,
					'agent_started': this.isAuthenticated
				}

				if (useApplicationStore().isExisting()) {
					await apiWhyApplying.update(formData)
					await this.uploadFile()
				} else {
					await apiWhyApplying.create(formData)
					useApplicationStore().init(true).then(async () => {
						await this.uploadFile()
					})
				}
			} catch (err) {
				window.scrollTo(0, 0)
				if (err.response.data.errors) {
					this.validationErrors = err.response.data.errors
				} else {
					this.errorMessage = 'Sorry, something went wrong'
				}
			}
			// success
			this.stopLoading()
			if (Object.keys(this.validationErrors).length === 0) {
				this.$router.push({name: 'census'})
			}
		},

		async initForm() {
			try {
				const result = (await apiWhyApplying.formData()).data;

				this.qles = result.qles
				this.supportingDocCopy = result.supporting_doc_copy
				this.oepAvailable = result.oep != null
				this.sepAvailable = result.sep != null
				if (this.oepAvailable) {
					this.oepStartDate = result.oep.start_date
					this.oepEndDate = result.oep.end_date
					this.oepEffectiveDate = result.oep.effective_date
				}
				this.dentalVisionEffectiveDate = result.dental_vision_effective_date
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},

		async loadForm() {
			if (! useApplicationStore().isExisting()) {
				return;
			}
			try {
				const result = (await apiWhyApplying.find()).data.data

				this.enrollmentType = result.enrollmentType
				this.enrollmentReasonId = result.enrollmentReasonId
				this.qleOtherDetails = result.qleOtherDetails
				this.qleEventDate = result.qleEventDate
				this.startDateOptionId = result.startDateOptionId
				this.enrollmentReasonFileName = result.enrollmentReasonFileName

			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},

		async uploadFile() {
			let fileFormData = new FormData()
			fileFormData.append('file', this.file)
			fileFormData.append('enrollment_type', this.enrollmentType)

			await apiWhyApplying.uploadFile(fileFormData)
		},

		chooseFiles() {
			document.getElementById("input_file_upload").click()
		},

		onFileChange() {
			let fileData = event.target.files[0]
			this.showFileSizeAlert = false

			if (fileData.size > 3 * 1024 * 1024) {
				this.showFileSizeAlert = true
				document.querySelector('#input_file_upload').value = '';
				return
			}

			this.enrollmentReasonFileName = fileData.name
			this.file = fileData
		},

		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},

	},
	watch: {
		enrollmentType(newVal, oldVal) {
			if (newVal === 'oe') {
				this.enrollmentReasonId = OPEN_ENROLLMENT
				this.qleOtherDetails = ''
				this.qleEventDate = ''
				this.file = null
				this.enrollmentReasonFileName = null
			} else if(newVal === 'vision') {
				this.enrollmentReasonId = ADULT_ONLY_VISION
				this.qleOtherDetails = ''
				this.qleEventDate = ''
				this.file = null
				this.enrollmentReasonFileName = null
			} else if(newVal === 'dental') {
				this.enrollmentReasonId = DENTAL_OR_DENTAL_WITH_VISION
				this.qleOtherDetails = ''
				this.qleEventDate = ''
				this.file = null
				this.enrollmentReasonFileName = null
			} else {
				if (oldVal) {
					this.enrollmentReasonId = null
				}
			}
		},
		enrollmentReasonId(newVal, oldVal) { // eslint-disable-line no-unused-vars
			const selectedQle = this.qles.find(e => e.id == this.enrollmentReasonId)

			if (selectedQle) { // won't apply to OE, which doesn't have a QLE object
				this.allowFutureEventDate = selectedQle.allow_future_event_date === '1'
			}

			if (this.enrollmentReasonId != OTHER_EVENT) {
				this.qleOtherDetails = ''
			}

			if (oldVal) {
				this.startDateOptionId = null
			}
		}
	},
	computed: {
		...mapState(useAgentInfoStore, ['displayBCBSM', 'displayPriority', 'isAuthenticated', 'agentSubDomain']),
		submitDocColumns() {
			return (this.displayBCBSM && this.displayPriority) ? "row-cols-lg-2" : "row-cols-lg-1"
		},
		qleGroups() {
			var qleGroups = [];

			// split qle list to display them nicely
			const splitIndex = parseInt(this.qles.length / 2)

			qleGroups.push(this.qles.slice(0, splitIndex))
			qleGroups.push(this.qles.slice(splitIndex, this.qles.length))

			return qleGroups
		},
		supportingDocDisplay() {
			if (this.enrollmentReasonId) {
				const selectedQle = this.qles.find(e => e.id == this.enrollmentReasonId)

				if (selectedQle) { // won't apply to OE, which doesn't have a QLE object
					return this.supportingDocCopy.filter(function (doc) {
						return selectedQle.supportingDocIds.includes(doc.id)
					})
				} else {
					return []
				}
			} else {
				return []
			}
		},
		showDateOfEvent() {
			var today = new Date()
			var eventDate = new Date(this.qleEventDate + ' ') // space character required to avoid timezone shenanigans

			if ([OTHER_EVENT,
				LEGAL_GUARDIANSHIP,
				BIRTH,
				ADOPTION,
				GAINING_OR_BECOMING_A_DEPENDENT,
				NEWLY_INELIGIBLE_FOR_APTC_OR_CSR,
				LOSS_MARKETPLACE_DENTAL].includes(this.enrollmentReasonId)) {
				return true;
			}

			if(this.enrollmentReasonId === LOSS_OF_STUDENT_HEALTH_PLAN && today < eventDate && eventDate.getDate() === 1) {
				return true;
			}

			return false;
		},
		showFirstDayMonthFollowingApplication() {
			var today = new Date()
			var eventDate = new Date(this.qleEventDate + ' ') // space character required to avoid timezone shenanigans

			if ([MARRIAGE,
				DIVORCE_OR_LEGAL_SEPARATION,
				DEATH_OF_POLICY_HOLDER,
				OTHER_EVENT,
				LEGAL_GUARDIANSHIP,
				BIRTH,
				ADOPTION,
				GAINING_OR_BECOMING_A_DEPENDENT,
				NEWLY_INELIGIBLE_FOR_APTC_OR_CSR].includes(this.enrollmentReasonId)) {
				return true;
			}

			if (today >= eventDate) {
				if([MOVED_OUT_OF_PLAN_COVERAGE_AREA,
					EXHAUSTION_OF_COBRA_BENEFITS,
					BECAME_ELIGIBLE_FOR_MEDICARE,
					GAINED_ACCESS_DUE_TO_MOVE,
					LOSS_OF_EMPLOYER_SPONSORED_GROUP_COVERAGE,
					DEPENDENT_AGING_OFF_OR_LOSS_OF_COVERAGE,
					INVOLUNTARY_LOSS_FROM_MEDICAID_OR_CHIP,
					LOSS_OF_STUDENT_HEALTH_PLAN,
					LOSS_MARKETPLACE_DENTAL].includes(this.enrollmentReasonId)) {
					return true;
				}
			}

			return false;
		},
		showDayAfterLossOfCoverage() {
			var today = new Date()
			var eventDate = new Date(this.qleEventDate + ' ') // space character required to avoid timezone shenanigans

			if ([DIVORCE_OR_LEGAL_SEPARATION,
				DEATH_OF_POLICY_HOLDER,
				EXHAUSTION_OF_COBRA_BENEFITS,
				BECAME_ELIGIBLE_FOR_MEDICARE,
				LOSS_OF_EMPLOYER_SPONSORED_GROUP_COVERAGE,
				DEPENDENT_AGING_OFF_OR_LOSS_OF_COVERAGE,
				INVOLUNTARY_LOSS_FROM_MEDICAID_OR_CHIP].includes(this.enrollmentReasonId)) {
				return true;
			}

			if (today < eventDate) {
				if([MOVED_OUT_OF_PLAN_COVERAGE_AREA, GAINED_ACCESS_DUE_TO_MOVE].includes(this.enrollmentReasonId)) {
					return true;
				}
			}

			return false;
		},
		showFirstDayMonthFollowingLossOfCoverage() {
			var today = new Date()
			var eventDate = new Date(this.qleEventDate + ' ') // space character required to avoid timezone shenanigans

			if (today < eventDate) {
				if ([MOVED_OUT_OF_PLAN_COVERAGE_AREA,
					EXHAUSTION_OF_COBRA_BENEFITS,
					BECAME_ELIGIBLE_FOR_MEDICARE,
					GAINED_ACCESS_DUE_TO_MOVE,
					LOSS_OF_EMPLOYER_SPONSORED_GROUP_COVERAGE,
					DEPENDENT_AGING_OFF_OR_LOSS_OF_COVERAGE,
					INVOLUNTARY_LOSS_FROM_MEDICAID_OR_CHIP,
					LOSS_OF_STUDENT_HEALTH_PLAN,
					LOSS_MARKETPLACE_DENTAL].includes(this.enrollmentReasonId)) {
					return true;
				}
			}

			return false;
		},
		startDatesAvailable() {
			return this.qleEventDate && (this.showDateOfEvent || this.showDayAfterLossOfCoverage
					|| this.showFirstDayMonthFollowingLossOfCoverage || this.showFirstDayMonthFollowingApplication);
		}
	}
}
</script>

<style scoped>
	.fs-7 {
		font-size: 0.875rem;
	}
</style>

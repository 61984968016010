import apiHelper from '@/helpers/apiHelper';

export default {
	getBranding(subdomain) {
		return apiHelper.get(`agent_branding/${subdomain}`)
	},

	getBrandingByNumber(npn) {
		return apiHelper.get(`agent_branding_number/${npn}`);
	},

	getLogoUrl(subdomain) {
		return process.env.VUE_APP_BASE_API_URL + '/agent_logo/' + subdomain
	},
}

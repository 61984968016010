<style>
legend {
	font-size: 1rem;
	margin-bottom: 0;
}

.filter_section {
	font-size: 0.85rem;
}

.filter_section .form-check {
	min-height: 1rem;
}

[data-bs-toggle="collapse"] .fa.collapse_toggle:before {
	content: "\f078";
}

[data-bs-toggle="collapse"].collapsed .fa.collapse_toggle:before {
	content: "\f054";
}

.badge {
	cursor: default;
}

/*start fix for dropdown in responsive table being cut off
 *https://github.com/twbs/bootstrap/issues/11037#issuecomment-1266127639
 */
.table-responsive .dropdown {
	position: static !important;
}

@media (min-width: 768px) {
    .table-responsive {
        overflow-x: visible;
    }
}
/* end dropdown fix */

</style>

<div class="vld-parent"></div>

<template>
<div class="card vld-parent">
	<loading v-model:active="isLoading" :is-full-page="false"></loading>
	<div class="card-header">
		<div class="d-flex flex-column flex-md-row justify-content-between">
			<div class="d-flex justify-content-start align-items-center">
				<h3 class="h5 mb-0">Submissions</h3>
				<span class="badge rounded-pill text-bg-secondary ms-2">{{ totalEnrollees }}</span>
				<select class="form-select form-select-sm ms-2" @change="loadData()" v-model="daysToShow">
					<option value="60">last 60 days</option>
					<option value="180">last 180 days</option>
					<option value="365">last 12 months</option>
				</select>
			</div>
			<div class="d-flex flex-column flex-md-row mt-2 mt-md-0 justify-content-end">
				<input @search="loadData()" class="form-control form-control-sm me-md-2" type="search" v-model="searchName" placeholder="Search name & email" />

				<div class="d-flex mt-2 mt-md-0 flex-grow justify-content-between">
					<button class="btn btn-sm btn-outline-secondary collapsed me-2 d-flex align-items-center" data-bs-toggle="collapse" data-bs-target="#enrollCollapseFilters" aria-expanded="false" aria-controls="enrollCollapseFilters"><i class="fa me-2 collapse_toggle"></i>Filters</button>
					<button class="btn btn-sm btn-outline-secondary d-flex align-items-center" :class="{ disabled : exportButtonDisabled }" @click="exportExcel()"><i class="fa fa-file-excel me-2"></i>Export</button>
				</div>
			</div>
		</div>
	</div>
	<div id="enrollCollapseFilters" class="collapse">
		<div class="card-header">
			<form class="row row-cols-lg-auto filter_section">
<!--
				<div class="col-12">
					<fieldset>
						<legend>Last</legend>
						<div class="form-check" v-for="item in timelineOptions" :key="item.id">
							<input @change="loadData()" class="form-check-input" name="lastFilterQuotes" type="radio" :id="`last_${item.value}`" :value="item.value" :checked="defaultTimeline == item.value">
							<label class="form-check-label" :for="`last_${item.value}`">{{ item.display }}</label>
						</div>
					</fieldset>
				</div>
-->
<!--
				<div class="col-12">
					<fieldset>
						<legend>Carrier</legend>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="" id="enrollFilterCarrier1">
							<label class="form-check-label" for="enrollFilterCarrier1">
							BCBSM
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="" id="enrollFilterCarrier2">
							<label class="form-check-label" for="enrollFilterCarrier2">
							BCN
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="" id="enrollFilterCarrier3">
							<label class="form-check-label" for="enrollFilterCarrier3">
							Priority Health
							</label>
						</div>
					</fieldset>
				</div>
-->
				<div class="col-12">
					<fieldset>
						<legend>Products</legend>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="medical" v-model="filterProduct" id="enrollFilterMedical">
							<label class="form-check-label" for="enrollFilterMedical">
							Medical
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="dental" v-model="filterProduct" id="enrollFilterDental">
							<label class="form-check-label" for="enrollFilterDental">
							Dental
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="vision" v-model="filterProduct" id="enrollFilterVision">
							<label class="form-check-label" for="enrollFilterVision">
							Vision
							</label>
						</div>
					</fieldset>
				</div>
				<div class="col-12" v-if="subdomains && subdomains.length > 1">
					<fieldset>
						<legend>Site</legend>
						<select class="form-select form-select-sm" @change="loadData()" v-model="filterSubdomain">
							<option value="">All</option>
							<option v-for="subdomain in subdomains" :key="subdomain" :value="subdomain">{{ subdomain }}</option>
						</select>
					</fieldset>
				</div>
			</form>
		</div>
	</div>
	<div class="card-body p-0" v-if="enrollees.length > 0">
		<div class="table-responsive">
		<table class="table m-0">
			<thead>
				<tr>
					<th></th>
					<th class="sortable" @click="doSort('last_name')"><i class="fa" :class="sortCol == 'last_name' ? (sortOrderAscName ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort'"></i> Subscriber Name</th>
					<th class="sortable" @click="doSort('submission_time')"><i class="fa" :class="sortCol == 'submission_time' ? (sortOrderAscSubmitted ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort'"></i> Submit Date</th>
					<th class="sortable" @click="doSort('effective_date')"><i class="fa" :class="sortCol == 'effective_date' ? (sortOrderAscEffective ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort'"></i> Effective Date</th>
					<th><i class=""></i> OEP/SEP</th>
					<th><i class=""></i> Contract Count</th>
					<th>Products</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(enroll, index) in enrollees" :key="index">
					<td>
						<div class="dropdown">
							<button class="btn btn-sm btn-outline-secondary dropdown-toggle py-0 px-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="fa fa-user"></i>
							</button>
							<ul class="dropdown-menu">
								<li><a class="dropdown-item" href="javascript:;" @click="getInfo(enroll.application_id)">More Info</a></li>
								<li><a class="dropdown-item" :href="'mailto:' + enroll.sub_email + '?subject=Your CoverageForOne Submission&body=Dear ' + enroll.full_name + ',%0d%0a%0d%0a\<Your email text\>%0d%0a%0d%0aSincerely,%0d%0a' + agentFullName">Send Email</a></li>
								<li v-if="sbcs[enroll.application_id]"><a class="dropdown-item" :href="sbcs[enroll.application_id]" target="_blank">View Medical SBC</a></li>
							</ul>
						</div>
					</td>
					<td>{{ enroll.primary_name }}</td>
					<td>{{ enroll.submission_time }}</td>
					<td>{{ enroll.effective_date }}</td>
					<td>{{ enroll.enrollment_period_type_id == 1 ? 'OEP' : 'SEP' }}</td>
					<td>{{ 1 + JSON.parse(enroll.dependents).length + (enroll.spouse_member_id ? 1 : 0) }}</td>
					<td>
						<span v-if="enroll.medical_product_id" v-tooltip data-bs-toggle="tooltip" class="badge text-bg-primary fw-normal me-1 px-1" :title="enroll.medical_plan_name">M</span>
						<span v-if="enroll.dental_product_id" v-tooltip data-bs-toggle="tooltip" class="badge text-bg-secondary fw-normal px-1 me-1" :title="enroll.dental_plan_name">D</span>
						<span v-if="enroll.vision_product_id" v-tooltip data-bs-toggle="tooltip" class="badge text-bg-info fw-normal px-1 me-1" :title="enroll.vision_plan_name">V</span>
					</td>
				</tr>
			</tbody>
		</table>
		</div>
	</div>
	<div class="card-body text-center" v-else-if="! isLoading">
		<b>No results were found</b>
	</div>
	<div class="card-footer d-flex justify-content-center">
		<div :style="{ visibility : enrollees.length > 0 ? 'visible' : 'hidden' }">
			<pagination-controls
				@loadPage="loadData"
				:prevLink="prevLink"
				:nextLink="nextLink"
				:pageCount="pageCount"
				:currentPage="currentPage"
			/>
		</div>
	</div>
</div>

<app-info-modal @resetAppId="resetAppId" :appId="appInfoId" ref="appInfoModal" />

</template>

<script>
import apiDashboard from "@/api/dashboard"
import AppInfoModal from '@/components/modules/AppInfoModal.vue'
import PaginationControls from "@/components/modules/PaginationControls.vue"
import Loading from 'vue-loading-overlay'
import displayHelper from "@/helpers/displayHelper"
import { useAgentInfoStore } from '@/stores/agentInfo'
import { mapState } from "pinia/dist/pinia"
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	emits: [],
	props: {
		resultsPerPage: {
			required: true,
		},
	},
	components: {
		Loading,
		AppInfoModal,
		PaginationControls,
	},

	data() {
		return {
			isLoading: null,
			enrollees: [],
			sbcs: {},
			totalEnrollees: null,
			currentPage: null,
			pageCount: null,
			prevLink: null,
			nextLink: null,
			sortCol: 'submission_time',
			sortOrderAsc: false,

			// set the default sort order of the column depending on type of data; allows us to remember the sort order per column
			sortOrderAscName: true,
			sortOrderAscSubmitted: false,
			sortOrderAscEffective: false,

			daysToShow: 60,
			filterProduct: [],
			filterSubdomain: '',
			subdomains: null,
			// filterCarrier: [],
			searchName: '',
			exportButtonDisabled: false,
			defaultTimeline: '60',

			// Select options
			timelineOptions: displayHelper.getDashboardTimelineArray(),

			appInfoId: null,
		}
	},

	mounted() {
		this.loadData()
	},

	created() {
	},

	computed: {
		...mapState(useAgentInfoStore, { agentFullName: 'fullName' })
	},

	methods: {
		async loadData(pageNum) {
			let options = this.getOptions(pageNum)

			try {
				this.isLoading = true
				const result = (await apiDashboard.getEnrollees(options)).data
				this.enrollees = result.data
				this.sbcs = result.sbcs
				this.totalEnrollees = result.meta.total
				this.currentPage = result.meta.current_page
				this.pageCount = result.meta.last_page
				this.isLoading = false
				this.prevLink = result.links.prev
				this.nextLink = result.links.next
				this.subdomains = JSON.parse(result.subdomains)
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},

		async exportExcel() {
			this.exportButtonDisabled = true;
			this.isLoading = true

			let options = this.getOptions()
			const file = (await apiDashboard.getEnrolleesExport(options))

			const link = document.createElement("a")
			link.href = window.URL.createObjectURL(new Blob([file.data]))
			link.setAttribute('download', 'Exported_Enrollees.xlsx')
			document.body.appendChild(link)
			link.click()
			URL.revokeObjectURL(link.href)

			this.isLoading = false
			this.exportButtonDisabled = false;
		},

		getOptions(pageNum) {
			let options = { params: {} }

			if (typeof(pageNum) !== 'undefined') {
				options.params.page = pageNum
			}

			options.params.resultsPerPage = this.resultsPerPage
			options.params.sortCol = this.sortCol
			options.params.sortOrderAsc = this.sortOrderAsc
			options.params.daysToShow = this.daysToShow
			options.params.filterProduct = this.filterProduct
			options.params.filterSubdomain = this.filterSubdomain
			// options.params.filterCarrier = this.filterCarrier
			options.params.searchName = this.searchName

			return options
		},

		doSort(column) {
			let prevSortCol = this.sortCol

			// if clicking again on same column header, reverse sort order
			let reverseSort = (column == prevSortCol)

			switch (column) {
				case 'last_name':
					if (reverseSort) this.sortOrderAscName = !this.sortOrderAscName
					this.sortOrderAsc = this.sortOrderAscName
					break
				case 'submission_time':
					if (reverseSort) this.sortOrderAscSubmitted = !this.sortOrderAscSubmitted
					this.sortOrderAsc = this.sortOrderAscSubmitted
					break
				case 'effective_date':
					if (reverseSort) this.sortOrderAscEffective = !this.sortOrderAscEffective
					this.sortOrderAsc = this.sortOrderAscEffective
					break
			}

			this.sortCol = column

			this.loadData()
		},

		getInfo(id) {
			this.appInfoId = id
		},

		resetAppId() {
			this.appInfoId = null
		},
	},
}
</script>

<template>
<p>
THE FOLLOWING TERMS OF USE CONSTITUTE A LICENSE THAT COVERS YOUR USE OF THIS SITE AND ANY TRANSACTIONS THAT YOU ENGAGE IN THROUGH THIS SITE. BY ACCESSING, VIEWING, OR USING THIS SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE WITH THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS, PLEASE DO NOT USE THIS SITE.
</p>
<h3>USE OF SITE</h3>
<p>
This website is provided solely for the use of licensed insurance agents of Action Benefits and individuals to provide information and to permit you to quote, enroll in, and manage insurance products and services. Any other use of this site is prohibited. By way of example, you should not use this site in violation of any laws, nor use any features of this site that permit communications or postings to post, transmit, display, or otherwise communicate (a) any defamatory, threatening, obscene, harassing, or otherwise unlawful information; (b) any advertisement, solicitation, spam, chain letter, or other similar type of information; (c) any encouragement of illegal activity; (d) unauthorized use or disclosure of private, personally identifiable information of others and/or protected health information under the Health Insurance Portability and Accountability Act of 1996 (HIPPA); or (e) any materials subject to trademark, copyright, or other laws protecting any materials or data of others in the absence of a valid license or other right to do so.
</p>
<h3>SITE CONTENTS AND OWNERSHIP</h3>
<p>
The information contained on this site, including all images, designs, photographs, writings, graphs, data, and other materials (Content) are the property of Interserv LLC and its affiliate Action Benefits and are protected by copyrights, trademarks, trade secrets, or other proprietary rights. Permission is granted to display, copy, distribute, download, and print portions of this site solely for the purposes of using this site for the authorized uses described above. You must retain all copyright and other proprietary notices on all copies of the Contents. You shall comply with all copyright laws worldwide in your use of this website and prevent unauthorized copying of the Contents. Except as provided in this Notice, Action Benefits does not grant you any express or implied right in or under any patents, trademarks, copyrights, or trade secret information.
</p>
<h3>DISCLAIMER OF WARRANTY</h3>
<p>
You expressly agree that use of this website is at your sole risk. Neither Action Benefits, its affiliates, nor any of their officers, directors, employees, agents, third-party content providers, or licensors (collectively, “Providers”) warrant that this site will be uninterrupted or error-free; nor do they make any warranty as to the results that may be obtained from the use of this site, or as to the accuracy, completeness, reliability, security, or currency of the Content.
</p>
<p>
The Content may contain errors, omissions, inaccuracies, or outdated information. Further, Action Benefits does not warrant reliability of any statement or other information displayed or distributed through the site. Action Benefits reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the site. Action Benefits may make any other changes to this site, the Content and the products, programs, services, or prices (if any) described in this site at any time without notice.
</p>
<p>
THIS SITE AND THE INFORMATION AND CONTENT ON THIS SITE ARE PROVIDED ON AN “AS IS”, “WHERE IS” AND “WHERE AVAILABLE” BASIS. ACTION BENEFITS MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE, THE CONTENT, INFORMATION, OR THE MATERIALS USED ON THIS SITE. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, ACTION BENEFITS EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND, WITH RESPECT TO ANY OF THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY PRODUCTS OR SERVICES OFFERED, SOLD, OR DISPLAYED ON THIS SITE OR YOUR USE OF THIS SITE GENERALLY, INCLUDING WARRANTIES OF MERCHANTABILITY, ACCURACY OF INFORMATION, QUALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH JURISDICTION’S LAW APPLIES TO THIS AGREEMENT.
</p>
<h3>LIMITATION OF LIABILITIES</h3>
<p>
YOU AGREE THAT ACTION BENEFITS AND ITS PROVIDERS SHALL NOT BE LIABLE FOR ANY DAMAGE, LOSS, OR EXPENSE OF ANY KIND ARISING OUT OF OR RESULTING FROM YOUR POSSESSION OR USE OF THE CONTENT OR INFORMATION ON THIS SITE REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN TORT, CONTRACT, OR OTHERWISE. IN NO EVENT, INCLUDING, WITHOUT LIMITATION, A NEGLIGENT ACT, SHALL ACTION BENEFITS OR ANY OF ITS PROVIDERS BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, LOSS OR CORRUPTION OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR INTERRUPTION OF BUSINESS), ARISING OUT OF OR IN ANY WAY RELATED TO THE MATERIALS, CONTENT, OR INFORMATION ON THIS SITE OR ANY OTHER PRODUCTS, SERVICES, OR INFORMATION OFFERED, SOLD, OR DISPLAYED ON THIS SITE, YOUR USE OF, OR INABILITY TO USE, THIS SITE GENERALLY, OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF WHETHER ACTION BENEFITS OR ANY OF ITS PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
</p>
<h3>INDEMNIFICATION</h3>
<p>
You agree to indemnify, defend, and hold harmless Action Benefits, its affiliates, agents, employees, and licensors from and against any and all claims and expenses, including reasonable attorney fees, arising out of or related in any way to your use of the site, violation of this Agreement, violation of any law or regulation, or violation of any proprietary or privacy right.
</p>
<h3>PRIVACY POLICY</h3>
<p>
Click here to access Action Benefits’ Privacy Policy governing the use of information that Action Benefits obtains from you through your use of this website.
</p>
<h3>LIMITATIONS ON CLAIM</h3>
<p>
Any cause of action you may have with respect to your use of this site must be commenced within one year after the claim or cause of action arises.
</p>
<h3>TERM AND TERMINATION</h3>
<p>
Without limiting its other remedies, Action Benefits may immediately discontinue, suspend, terminate, or block your and any user’s access to this site at any time in our sole discretion.
</p>
<h3>HYPERLINK DISCLAIMERS</h3>
<p>
As a convenience to you, we may provide on this site links to websites operated by other entities (collectively the “Linked Sites”). If you use any Linked Sites, you will leave this site. If you decide to visit any Linked Site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements. Linked Sites, regardless of the linking form (e.g., hotlinks, hypertext links, IMG links) are not maintained, controlled, or otherwise governed by Action Benefits. The content, accuracy, opinions expressed, and other links provided by Linked Sites are not investigated, verified, monitored, or endorsed by Action Benefits. Action Benefits does not endorse, make any representations regarding, or warrant any information, goods, and/or services appearing and/or offered on any Linked Site, other than linked information authored by Action Benefits. Links do not imply that Action Benefits or this site sponsors, endorses, is affiliated or associated with, or is legally authorized to use any trademark, trade name, logo, or copyright symbol displayed in or accessible through the links, or that any Linked Site is authorized to use any trademark, trade name, logo or copyright symbol of Action Benefits or any of its affiliates or subsidiaries. Except for links to information authored by Action Benefits, Action Benefits is neither responsible for nor will it be liable under any theory based on (i) any Linked Site; (ii) any information and/or content found on any Linked Site; or (iii) any site(s) linked to or from any Linked Site. If you decide to visit any Linked Sites and/or transact any business on them, you do so at your own risk. Action Benefits reserves the right to discontinue any Linked Site at any time without prior notice. Please contact the webmasters of any Linked Sites concerning any information, goods, and/or services appearing on them.
</p>
<h3>CONTROLLING LAW, JURISDICTION, AND INTERNATIONAL USERS</h3>
<p>
This Agreement is governed by and shall be construed in accordance with the laws of the State of Michigan, without reference to its conflict-of-law provisions. Action Benefits makes no representation that the Content is appropriate or available for use outside the United States. If you access this site from outside the United States, you will be responsible for compliance with all local laws. You agree to comply with all laws and regulations applicable to your use of this site. You agree to submit to the personal and exclusive jurisdiction of the state or federal courts located within Oakland County, Michigan for any disputes with Action Benefits arising out of your use of this site.
</p>
<h3>ENTIRE AGREEMENT</h3>
<p>
This Agreement constitutes the entire agreement between Action Benefits and you with respect to this website, and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between you and Action Benefits with respect to this website. A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based on or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. If for any reason a court of competent jurisdiction finds any provision of this Agreement or portion of it to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of this Agreement, and the remainder of this Agreement shall continue in full force and effect. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.
</p>
<h3>MODIFICATIONS TO AGREEMENT</h3>
<p>
We may revise this Agreement at any time and you agree to be bound by the revised Agreement. Any such modifications will become effective on the date they are first posted to this site. It is your responsibility to return to this Agreement from time to time to review the most current terms and conditions. Action Benefits does not and will not assume any obligation to notify you of changes to this Agreement.
</p>
<h3>ELECTRONIC COMMUNICATIONS AND ELECTRONIC SIGNATURES</h3>
<p>
You agree to be bound by any affirmation, assent, or agreement you transmit through this website, including but not limited to any consent you give to receive communications from Action Benefits solely through electronic transmission. You agree that when in the future you click on an “I agree,” “I consent,” or other similarly worded “button” or entry field with your mouse, keystroke, or other computer device, your agreement or consent will be legally binding and enforceable and the legal equivalent of your handwritten signature.
</p>
</template>

<style>
</style>

<div class="vld-parent"></div>

<template>
<div class="card vld-parent">
	<loading v-model:active="isLoading" :is-full-page="false"></loading>
	<div class="card-header">
		<h3 class="h5 mb-0">Enrollments Year to Date</h3>
	</div>
	<div class="card-body">
		<Line
			class="w-100 h-100"
			v-if="! isLoading"
			id="my-chart-id"
			:options="chartOptions"
			:data="chartData"
		/>
	</div>
</div>
</template>

<script>
import apiDashboard from "@/api/dashboard";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
	emits: [],

	props: {
	},

	components: {
		Loading,
		Line,
	},

	data() {
		return {
			isLoading: true,

			chartData: {
				labels: null,
				datasets: [
					{
						data: [],
						backgroundColor: ['#41B883'],
					}
				],
			},
			chartOptions: {
				responsive: true,
				scales: {
					x: {
						title: {
							display: false,
							text: 'Date',
						}
					},
					y: {
						title: {
							display: false,
							text: 'Submissions',
						},
						beginAtZero: true,
						ticks: {
							precision: 0, // results in just integers for axis ticks
						}
					}
				},
				plugins: {
					legend: {
						display: false,
					}
				}
			}
		}
	},

	mounted() {
		this.loadData()
	},

	created() {
	},

	computed: {
	},

	methods: {
		async loadData() {
			let options = null;

			try {
				const result = (await apiDashboard.getEnrollmentsYtd(options)).data


				this.chartData['labels'] = Object.keys(result)
				this.chartData['datasets'][0]['data'] = Object.values(result)

				this.isLoading = false

			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		}
	}
}
</script>

<template>
	<p>Your privacy is important to us. We maintain high standards for the protection of your privacy at our website. This privacy policy explains what type of information we collect and what we do with that information.</p>
	<p>PLEASE REMEMBER this policy covers only the data collection and use practices for an official website of Interserv LLC. Interserv LLC privacy policies may differ at its other websites or in its off-line data collection and reporting practices. Questions regarding the privacy policy of Interserv LLC should be directed via e-mail to <a href="mailto:privacy@interservsystems.com">privacy@interservsystems.com</a>.</p>
	<h3>The Information We Collect</h3>
	<p>Our web servers use the Google Analytics Service and automatically capture your domain name, the IP address of the web page from which you enter our site, the pages you visit on our site and the amount of time you spend here. We do not collect any information that can reveal your personal identity, unless you voluntarily provide it when you register to use the interactive features of the site or to receive information from us. The information we collect is not part of any designated record set. We do not knowingly collect or use information from children under 18 without the consent of a parent or guardian.</p>
	<p>Personally identifiable information voluntarily provided by you may be retained indefinitely for the purposes described in this Privacy Notice.</p>
	<h3>Use of the Information this Site Gathers/Tracks</h3>
	<p>Any personally identifiable information you give us will be used only to provide the product, service or information you have requested, like a quote or to support, supplement or enhance the programs and protection for which you registered. Unless you specifically consent to let us do so, your personally identifiable information, including your e-mail address, will not be sold, rented, licensed or otherwise shared with third parties, other than our partner companies as required to fulfill a request from you. The non-personally identifiable information we gather in aggregate form may be used to improve our site and we may share that information with our partners or our health and wellness sponsors.</p>
	<p>We will share personal information with companies, organizations or individuals if we have a good faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:</p>
	<ul>
	<li>Meet any applicable law, regulation, legal process or enforceable governmental request</li>
	<li>Enforce applicable Terms of Service, including investigation of potential violations</li>
	<li>Detect, prevent, or otherwise address fraud, security or technical issues</li>
	<li>Protect against harm to the rights, property or safety of Interserv LLC, our users or the public as required or permitted by law</li>
	</ul>

	<h3>Making Changes to the Information You Provide to Us</h3>
	<p>Information that you voluntarily provided will be maintained as long as Interserv LLC deems appropriate to fulfill the purpose for which you provided the information or until you request us to remove it.</p>
	<h3>HIPAA</h3>
	<p>We comply with updated regulations under the Health Insurance Portability and Accountability Act of 1996 (HIPAA).  We will not use your protected health information for marketing communications except where the law permits.  You have the right to be notified if there is a security breach that involves your protected health information.  We won't use or disclose your protected health information in any way other than as described in this Privacy Policy, unless we have a signed authorization.</p>
	<h3>Cookies</h3>
	<p>When you visit our site, we may place a temporary session or a permanent cookie on your computer that will allow us to customize and enhance your experience on the site, make improvements to our site or to report site activity. Our session cookies are not permanently stored on your computer and expire when you leave. Permanent cookies are issued only to site visitors who link the site from a search engine and are used only to permit Interserv LLC to attract qualified users to the site. Our cookies will never be used to track your activity on any third party websites or to send spam, nor will they provide us with any personally identifiable information about you. We store cookie information only in aggregate form and use the aggregate information only to make improvements to the site or in internal reports on site activity. You may use your browser controls to refuse to allow cookies to be placed on your computer. Refusing cookies disables our ability to include information about your visit in our regular monitoring of site traffic.</p>
	<h3>Sites We Link To</h3>
	<p>If you choose to use any of the links we provide to our member companies, sponsors and other third party resources, you will be leaving our website and going to a new website. Protection of your privacy at those other sites will be governed by the privacy policy at that site. We've taken special care selecting the companies that appear on our site to try to ensure that they respect your privacy as we do, but please take the time to read the privacy policies at their sites.</p>
	<h3>Changes to this Policy</h3>
	<p>If we make any changes to this policy, we'll let you know the effective date of the changes and you can contact our Webmaster if you'd like to know more about what's new. If you have any questions or comments, or receive any unwanted e-mail from this site, please contact our Webmaster via e-mail at <a href="mailto:webmaster@interservsystems.com">webmaster@interservsystems.com</a>.</p>
</template>

<style>
.nav_text {
	color:#777;
	font-size: 0.8rem;
	font-weight: bold;
	text-transform: uppercase;
}
</style>

<div class="vld-parent"></div>

<template>
	<div class="card mb-3">
		<div class="card-body p-0">
			<h2 class="nav_text m-2">Off Marketplace</h2>
			<ul class="nav flex-column">
				<li class="nav-item mx-2 mb-2" v-if="applicationStore.isInitialized() && applicationStore.isExisting()">
					<button class="btn btn-secondary d-block w-100" type="button" @click="continueQuote">
						Continue Quote
					</button>
				</li>
				<li class="nav-item mx-2">
					<button type="button" class="btn btn-secondary w-100" @click="newQuote">Start Quote</button>
				</li>
				<li class="nav-item">
					<router-link :to="{ name: 'dashboard-quoting' }" class="nav-link"><i class="fa fa-rectangle-list pe-2"></i>Quoting</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="{ name: 'dashboard-enrolled' }" class="nav-link"><i class="fa fa-rectangle-list pe-2"></i>Submissions</router-link>
				</li>
			</ul>

			<h2 class="nav_text mt-3 mx-2 mb-0">On Marketplace</h2>
			<ul class="nav flex-column">
				<li class="nav-item">
					<a class="nav-link" :href="marketplaceLink + '/enrollment/welcome'" target="_blank">Start Quote <i class="fa fa-up-right-from-square ps-2"></i></a>
				</li>
			</ul>

			<span v-if="agentInfoStore.displayMedicare">
				<h2 class="nav_text mt-3 mx-2 mb-0">Medicare</h2>
				<ul class="nav flex-column">
					<li class="nav-item">
						<a class="nav-link" href="https://www.sunfirematrix.com/app/agent/c4one" target="_blank">Start Quote <i class="fa fa-up-right-from-square ps-2"></i></a>
					</li>
				</ul>
			</span>
		</div>
	</div>
	<div class="card mb-3">
		<div class="card-body p-0">
			<h2 class="nav_text m-2">Action Benefits Contact Info</h2>
			<p class="mx-2">
				<b>Email</b><br>
				<a href="mailto:individual@actionbenefits.com">individual@actionbenefits.com</a><br>
				<b>Phone</b><br>
				<a href="tel:(833) 754-2163">(833) 754-2163</a>
			</p>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAgentInfoStore } from '@/stores/agentInfo'
import { useApplicationStore } from '@/stores/application'
import { useStepStore } from '@/stores/step'
import apiHelper from "@/helpers/apiHelper"
import stepHelper from '@/helpers/stepHelper.js'
import {useConfigStore} from "@/stores/config"

export default {
	inject: ['startLoading', 'stopLoading'],
	props: {
	},
	components: {
	},

	data() {
		return {
			marketplaceLink: '#',
		}
	},

	mounted() {
	},

	created() {
		const configStore = useConfigStore()
		configStore.initStore().then(() => {
			this.marketplaceLink = configStore.strideBaseUrl
		})
	},

	computed: {
		...mapStores(useAgentInfoStore, useApplicationStore, useStepStore) // each store will be accessible as its id + 'Store'
	},

	methods: {
		async restartApplication() {
			await apiHelper.get('application/active', {
				params: {
					'reset': true
				}
			})

			await useApplicationStore().init(true)
		},

		async newQuote() {
			if (useApplicationStore().isExisting()) {
				await this.restartApplication()
			}

			this.$router.push({name: 'whyApplying'})
		},

		continueQuote() {
			this.$router.push({name: stepHelper.getRouteByStepId(this.stepStore.lastStep)})
		},
	},
}
</script>

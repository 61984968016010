import { useAgentInfoStore } from '@/stores/agentInfo'
import { useApplicationStore } from "@/stores/application"
import apiAdmin from '@/api/admin'
import apiBranding from '@/api/branding'

export default {
	async fabricate(userId, npn) {
		/* todo: we should be able to execute these in parallel but I had an issue where log in/log out was only working
		 * intermittently. Perhaps related to each API call updating the session at nearly the same time?
		 *
		let [responseFabricate, responseBranding] = await Promise.all([
			apiAdmin.fabricateAgent(userId),
			apiBranding.getBrandingByNumber(npn),
		])
		*/
		let responseFabricate = await apiAdmin.fabricateAgent(userId)
		let responseBranding = await apiBranding.getBrandingByNumber(npn)

		if (responseFabricate.status == 200 && responseBranding.status == 200) {

			await useAgentInfoStore().initStore(responseBranding.data.subdomains[0])

			// clear application in progress due to the possibility of incorrect application assignment
			if (useApplicationStore().isExisting()) {
				useApplicationStore().init(true)
			}
		}
	},

	async unFabricate() {
		try {
			let responseUnFabricate = await apiAdmin.unFabricateAgent()

			if (responseUnFabricate.status == 200) {

				await useAgentInfoStore().initStore(useAgentInfoStore().fabricatorSubdomain)

				useAgentInfoStore().fabricatorSubdomain = null

				if (useApplicationStore().isExisting()) {
					// clear application in progress due to the possibility of incorrect application assignment
					useApplicationStore().init(true)
				}
			}
		} catch (err) {
			console.log('error')
		}
	},
}

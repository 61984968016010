
export default {
	data: function() {
		return {
			Carriers: {
				BCBSM: 1,
				BCN: 2,
				PRIORITY: 3,
				UNITED: 4
			}
		}
	}
}

<template>
	<!-- <button id="triggerModal" type="button" class="btn btn-primary" data-toggle="modal" data-target="#mymodal">modal</button> -->
	<button class="btn btn-primary btn-lg" @click="this.$refs.testModal.show()">open bs-modal</button>
	<BsModal title="test title one" id="mymodal" ref="testModal">
		<template #body>first modal body</template>
	</BsModal>
	<button class="btn btn-primary btn-lg" @click="this.$refs.testModal2.show()">open bs-modal2</button>
	<BsModal title="test title two" id="mymodal2" ref="testModal2">
		<template #body>second modal body</template>
	</BsModal>
	<BsCard title="test card title" id="mycard" :footer="false">
		<template #body>test body in a slot</template>
		<template #footer>test footer</template>
	</BsCard>

	<div class="input-group">
		<div class="col-6">
			<input id="active_app_id" type="text" class="form-control" v-model.trim="activeAppIdInput">
		</div>
		<button class="btn btn-primary" type="button" @click="setActiveAppId">Set Application ID (for testing)</button>
	</div>

	<div class="alert alert-primary" role="alert">
		Currently active application ID is {{ activeAppId }}
	</div>
</template>

<script>
import BsModal from './bootstrap/BsModal.vue';
import BsCard from './bootstrap/BsCard.vue';

import { useApplicationStore } from '@/stores/application';

import apiHelper from '@/helpers/apiHelper';

export default {
	inject: ['startLoading', 'stopLoading'],
	components: {
		BsModal,
		BsCard,
	},
	data() {
		return {
			activeAppId: '...',
			activeAppIdInput: 1
		};
	},

	created() {
		this.startLoading()
		this.getActiveAppId();
	},

	mounted() {
		this.stopLoading()
	},

	methods: {
		async setActiveAppId() {
			(await apiHelper.get('application/active', {
				params: {
					'application_id': this.activeAppIdInput
				}
			}));
			useApplicationStore().init(true);
			this.activeAppId = this.activeAppIdInput;
			alert ('active application ID set to ' + this.activeAppIdInput);
		},

		async getActiveAppId() {
			const result = (await apiHelper.get(`application/active`)).data;
			this.activeAppId = result.applicationId;
		}
	},
};
</script>

<template>
	<div class="alert alert-danger">
		<ol class="list-unstyled mb-0">
			<template v-for="(fieldErrors, fieldName) in errors" :key="fieldName">
				<li v-for="error in fieldErrors" :key="error.id">{{ error }}</li>
			</template>
		</ol>
	</div>
</template>

<script>
export default {
	props: {
		errors: {
			required: true
		},
	},
}
</script>

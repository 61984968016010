import apiHelper from '@/helpers/apiHelper';

export default {
	find() {
		return apiHelper.appGet(`rap`);
	},
	formData() {
		return apiHelper.get(`application/rap`);
	},
	update(data) {
		return apiHelper.appPut(`rap`, data);
	},
	create(data) {
		return apiHelper.post(`application/rap`, data);
	}
};

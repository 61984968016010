import { defineStore } from 'pinia'
import apiHelper from "@/helpers/apiHelper"

export const useConfigStore = defineStore({
	id: 'config',

	state: () => ({
		initPromise: null,
		initialized: false,
		strideBaseUrl: ''
	}),

	actions: {
		initStore(force = false) {
			if (this.initPromise && ! force) {
				return this.initPromise
			}
			this.initialized = false

			this.initPromise = apiHelper.get(`config`)

			this.initPromise.then(result => {
				this.strideBaseUrl = result.data.strideBaseUrl
				this.initialized = true
			})

			this.initPromise.catch(error => {
				this.error = error
				console.log('ERROR with initialize app: ' + error)
			})

			return this.initPromise
		},

		isInitialized() {
			return this.initialized
		}
	}
})

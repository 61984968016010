<template>
	<nav aria-label="next/previous page controls">
		<ul class="pagination mb-0">
			<li class="page-item" :class="{ disabled : prevLink === null }">
				<button type="button" @click="this.$emit('loadPage', currentPage - 1)" class="page-link" href="#" aria-label="Previous">
					<span aria-hidden="true" class="d-none d-sm-inline">Previous</span>
					<span aria-hidden="true" class="d-sm-none"><i class="fas fa-chevron-left" /></span>
				</button>
			</li>

			<template v-if="pageCount <= 5">
				<li v-for="i in pageCount" :key="i" class="page-item" :class="{ active : i == currentPage }">
					<button type="button" @click="this.$emit('loadPage', i)" class="page-link" :disabled="currentPage == i ? 'disabled' : null" href="#">{{ i }}</button>
				</li>
			</template>

			<!-- more compact layout when we have more pages -->
			<template v-else>
				<!-- always show first page -->
				<li class="page-item" :class="{ active : currentPage == 1 }">
					<!-- note: if null is returned the disabled attribute will not be applied which is what we want -->
					<button type="button" :disabled="currentPage == 1 ? 'disabled' : null" @click="this.$emit('loadPage', 1)" class="page-link" href="#">1</button>
				</li>

				<!-- add ellipsis if there's a gap between first page and current page -->
				<li class="page-item disabled">
					<button v-if="currentPage > 2" type="button" disabled="disabled" class="page-link" href="#">...</button>
				</li>

				<li v-if="currentPage > 1 && currentPage < pageCount" class="page-item active">
					<button type="button" disabled="disabled" class="page-link" href="#">{{ currentPage }}</button>
				</li>

				<!-- add ellipsis if there's a gap between current page and last page -->
				<li class="page-item disabled">
					<button v-if="pageCount - currentPage > 1" type="button" disabled="disabled" class="page-link" href="#">...</button>
				</li>

				<!-- always show last page -->
				<li class="page-item" :class="{ active : currentPage == pageCount }">
					<!-- note: if null is returned the disabled attribute will not be applied which is what we want -->
					<button type="button" :disabled="currentPage == pageCount ? 'disabled' : null" @click="this.$emit('loadPage', pageCount)" class="page-link" href="#">{{ pageCount }}</button>
				</li>
			</template>

			<li class="page-item" :class="{ disabled : nextLink === null }">
				<button type="button" @click="this.$emit('loadPage', currentPage + 1)" class="page-link" href="#" aria-label="Next">
					<span aria-hidden="true" class="d-none d-sm-inline">Next</span>
					<span aria-hidden="true" class="d-sm-none"><i class="fas fa-chevron-right" /></span>
				</button>
			</li>
		</ul>
	</nav>
</template>

<script>

export default {
	props: {
		prevLink: {
			required: true,
		},
		nextLink: {
			required: true
		},
		pageCount: {
			required: true
		},
		currentPage: {
			default: true
		},
	},
	data() {
		return {
		}
	},
	methods: {
	},
	computed: {
	}
}
</script>

export default {
	getNoYesArray() {
		return [
			{ id: 0, value: 'No' },
			{ id: 1, value: 'Yes' }
		]
	},

	getGenderArray() {
		return [
			{ id: 1, value: 'M', display: 'Male' },
			{ id: 2, value: 'F', display: 'Female' }
		]
	},

	getRelationshipArray() {
		return [
			{ id: 1, value: 'Child' },
			{ id: 2, value: 'Principally supported' },
			{ id: 3, value: 'Child adoption in progress' },
			{ id: 4, value: 'Stepchild' },
			{ id: 5, value: 'Court ordered coverage' },
			{ id: 6, value: 'Legal guardianship' },
			{ id: 7, value: 'Disabled child' }
		]
	},

	getTitleArray() {
		return [
			{ id: 1, value: 'Mr.' },
			{ id: 2, value: 'Mrs.' },
			{ id: 3, value: 'Ms.' },
			{ id: 4, value: 'Dr.' },
		]
	},

	getStateAbbrevSelectOptions() {
		return {
			'AL':'AL', 'AK':'AK', 'AZ':'AZ', 'AR':'AR', 'CA':'CA', 'CO':'CO', 'CT':'CT',
			'DE':'DE', 'DC':'DC', 'FL':'FL', 'GA':'GA', 'HI':'HI', 'ID':'ID', 'IL':'IL', 'IN':'IN', 'IA':'IA',
			'KS':'KS', 'KY':'KY', 'LA':'LA', 'ME':'ME', 'MD':'MD', 'MA':'MA', 'MI':'MI', 'MN':'MN', 'MS':'MS',
			'MO':'MO', 'MT':'MT', 'NE':'NE', 'NV':'NV', 'NH':'NH', 'NJ':'NJ', 'NM':'NM', 'NY':'NY', 'NC':'NC',
			'ND':'ND', 'OH':'OH', 'OK':'OK', 'OR':'OR', 'PA':'PA', 'RI':'RI', 'SC':'SC', 'SD':'SD', 'TN':'TN',
			'TX':'TX', 'UT':'UT', 'VT':'VT', 'VA':'VA', 'WA':'WA', 'WV':'WV', 'WI':'WI', 'WY':'WY'
		}
	},

	getAgreeArray() {
		return [
			{id: 0, display: 'Nothing to show.'},
			{id: 1, display: 'By checking this box and entering my name below, I am indicating my intent to electronically sign this Medical/Dental Application and warrant all that all of the information I have provided is true, complete, and accurate. Only direct users and Assisted Consumers can provide online signature(s). MGM User, Agency Admin, Agent, Sales Manager or Telesales cannot sign on behalf of a consumer.'},
			{id: 2, display: 'Application entered on behalf of consumer. The consumer has provided consent for electronic submission on his or her behalf.'},
		]
	},

	getPhoneTypeArray() {
		return [
			{ id: 1, value: 'Home' },
			{ id: 4, value: 'Cell' },
			{ id: 2, value: 'Work' },
			{ id: 3, value: 'Fax' },
			{ id: 5, value: 'Other' },
		]
	},

	getDashboardTimelineArray() {
		return [
			{ id: 1, value: '60', display: '60 Days' },
			{ id: 2, value: '180', display: '180 Days' },
			{ id: 3, value: '365', display: 'Year' }
		]
	},

	getEthnicityTypesHispanic(isBlueCrossApp, isPriorityApp) {
		if(isPriorityApp && !isBlueCrossApp) {
			return [
				{
					id: 16,
					desc: "Mexican",
				},
				{
					id: 17,
					desc: "Mexican American",
				},
				{
					id: 18,
					desc: "Chicano/a",
				},
				{
					id: 19,
					desc: "Puerto Rican",
				},
				{
					id: 20,
					desc: "Cuban",
				},
				{
					id: 23,
					desc: "Hispanic, Latino or Spanish Origin",
				},
				{
					id: 22,
					desc: "Not Hispanic",
				}
			]
		}

		if(isBlueCrossApp && !isPriorityApp) {
			return [
				{
					id: 16,
					desc: "Mexican",
				},
				{
					id: 17,
					desc: "Mexican American",
				},
				{
					id: 18,
					desc: "Chicano/a",
				},
				{
					id: 19,
					desc: "Puerto Rican",
				},
				{
					id: 20,
					desc: "Cuban",
				},
				{
					id: 21,
					desc: "Other",
				}
			]
		}

		//combined
		return [
			{
				id: 16,
				desc: "Mexican",
			},
			{
				id: 17,
				desc: "Mexican American",
			},
			{
				id: 18,
				desc: "Chicano/a",
			},
			{
				id: 19,
				desc: "Puerto Rican",
			},
			{
				id: 20,
				desc: "Cuban",
			},
			{
				id: 23,
				desc: "Hispanic, Latino or Spanish Origin",
			},
			{
				id: 22,
				desc: "Not Hispanic",
			},
			{
				id: 21,
				desc: "Other",
			}
		]
	}

}

<template>
	<h1 class="h2 mb-3">Manage Agent Messages</h1>
	<div class="row">
		<div class="toast-container top-0 position-fixed start-50 translate-middle-x p-3">
			<div id="savedToast" ref="savedToast" class="toast text-white bg-primary" role="alert" data-bs-delay="3000" aria-live="assertive" aria-atomic="true">
				<div class="toast-body text-center">
					Message has been saved
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="card mb-3">
				<h2 class="card-header h4">Message List</h2>
				<div class="card-body">
					<button class="btn btn-primary mb-3" @click="newMessage()">New message</button>
					<div class="list-group">
						<a href="#" :class="{'list-group-item': true, 'list-group-item-action': true, 'active': selectedMessage && selectedMessage.id === message.id}" v-for="message in messageList" :key="message.id" @click="activeMessageListItem(message)"><b>{{ message.title }}</b><br><span class="text-muted">Created {{ message.created_at_formatted }}</span></a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-24">
			<div class="card mb-3">
				<h2 class="card-header h4">Message Details</h2>
				<div class="card-body pb-0">
					<div class="d-flex justify-content-between mb-3">
						<button class="btn btn-primary" @click="saveMessage()" :disabled="messageEmpty">Save message</button>
						<button class="btn btn-danger" @click="deleteMessage()" :disabled="selectedMessage.id == null">Delete message</button>
					</div>
					<div class="row">
						<div class="col-md-36 mb-3" :class="hasError('title')">
							<label for="title" class="form-label required_field">Title</label>
							<input type="text" id="title" class="form-control form-control-sm" v-model.trim="selectedMessage.title">
							<span id="help_block_title" v-for="(msg, index) in validationErrors['title']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 mb-3" :class="hasError('publish_date')">
							<label for="publish_date" class="form-label required_field">Publish Date</label>
							<input type="date" id="publish_date" class="form-control form-control-sm" v-model.trim="selectedMessage.publish_date">
							<span id="help_block_publish_date" v-for="(msg, index) in validationErrors['publish_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-md-12 mb-3" :class="hasError('expiration_date')">
							<label for="expiration_date" class="form-label">Expiration Date</label>
							<input type="date" id="expiration_date" class="form-control form-control-sm" v-model.trim="selectedMessage.expires_at">
							<span id="help_block_expiration_date" v-for="(msg, index) in validationErrors['expiration_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-md-12 mb-3" :class="hasError('code')">
							<label for="code" class="form-label">Message Code</label>
							<input type="text" id="code" class="form-control form-control-sm" v-model.trim="selectedMessage.code">
							<span id="help_block_code" v-for="(msg, index) in validationErrors['code']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-36 mb-3" :class="hasError('content')">
							<label for="content" class="form-label required_field">Content</label>
							<textarea id="content" class="form-control form-control-sm" rows="10" v-model.trim="selectedMessage.content"></textarea>
							<span id="help_block_content" v-for="(msg, index) in validationErrors['content']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-36">
							<div class="form-check mb-3" :class="hasError('sticky')">
								<input type="checkbox" id="sticky" class="form-check-input" v-model.trim="selectedMessage.is_sticky">
								<label for="sticky" class="form-check-label">Prevent agent from dismissing this message</label>
								<span id="help_block_sticky" v-for="(msg, index) in validationErrors['sticky']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-36 mb-2">
							<h3 class="h4 mb-3">Show message to:</h3>
							<button id="select_all_agents" class="btn btn-sm btn-outline-secondary me-2" @click="selectAllAgents()">Select All</button>
							<button id="deselect_all_agents" class="btn btn-sm btn-outline-secondary" @click="deselectAllAgents()">Deselect All</button>
						</div>
					</div>
					<table class="table">
						<thead>
							<tr>
								<th></th>
								<th>NPN</th>
								<th>Name</th>
								<th>Email Address</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="agent in agents" :key="agent.id">
								<td>
									<div class="form-check mr-3">
										<input type="checkbox" :id="'agent_' + agent.id" class="form-check-input" :value="agent.id" v-model="selectedMessage.agents">
									</div>
								</td>
								<td><label :for="'agent_' + agent.id" class="form-check-label">{{ agent.npn }}</label></td>
								<td><label :for="'agent_' + agent.id" class="form-check-label">{{ agent.first_name + ' ' + agent.last_name }}</label></td>
								<td><label :for="'agent_' + agent.id" class="form-check-label">{{ agent.email }}</label></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Toast } from 'bootstrap'
import apiAdmin from "@/api/admin"

const blankMessage = {
	id: null,
	publish_date: null,
	expires_at: null,
	code: null,
	title: null,
	content: null,
	is_sticky: null,
	agents: []
}

export default {
	props: {
	},
	components: {
	},
	data() {
		return {
			agents: [],
			messageList: [],
			selectedMessage: JSON.parse(JSON.stringify(blankMessage)),
			validationErrors: {},
			errorMessage: null,
		}
	},
	computed: {
		messageEmpty() {
			return !this.selectedMessage.title && !this.selectedMessage.publish_date && !this.selectedMessage.expires_at
				&& !this.selectedMessage.code && !this.selectedMessage.content && !this.selectedMessage.is_sticky
				&& this.selectedMessage.agents.length === 0
		}
	},
	created() {

	},
	mounted() {
		this.loadData()
		this.savedToast = new Toast(this.$refs.savedToast)
	},
	expose: ['show'],
	methods: {
		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},
		async loadData() {
			try {
				this.agents = (await apiAdmin.getAgentList([])).data
				this.messageList = (await apiAdmin.getMessageList([])).data
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact support.")
			}
		},
		activeMessageListItem(message) {
			if(message.id !== this.selectedMessage.id) {
				this.selectedMessage = JSON.parse(JSON.stringify(message))
			}
		},
		selectAllAgents() {
			this.selectedMessage.agents = this.agents.map(agent => agent.id)
		},
		deselectAllAgents() {
			this.selectedMessage.agents = []
		},
		newMessage() {
			this.selectedMessage = JSON.parse(JSON.stringify(blankMessage))
		},
		async saveMessage() {
			this.validationErrors = {};
			this.errorMessage = null;

			try {
				let formData = {
					'id': this.selectedMessage.id,
					'publish_date': this.selectedMessage.publish_date,
					'expiration_date': this.selectedMessage.expires_at,
					'code': this.selectedMessage.code,
					'title': this.selectedMessage.title,
					'content': this.selectedMessage.content,
					'is_sticky': this.selectedMessage.is_sticky,
					'agents': this.selectedMessage.agents
				}

				await apiAdmin.saveMessage(formData)

				this.messageList = (await apiAdmin.getMessageList([])).data
				if(!this.selectedMessage.id) {
					this.newMessage()
				}

				this.savedToast.show()
			} catch (err) {
				window.scrollTo(0, 0)
				if (err.response.data.errors) {
					this.validationErrors = err.response.data.errors
				} else {
					this.errorMessage = 'Sorry, something went wrong'
				}
			}
		},
		async deleteMessage() {
			try {
				await apiAdmin.deleteMessage(this.selectedMessage.id, [])
			} catch (err) {
				window.scrollTo(0, 0)
				if (err.response.data.errors) {
					this.validationErrors = err.response.data.errors
				} else {
					this.errorMessage = 'Sorry, something went wrong'
				}
			}

			this.messageList = (await apiAdmin.getMessageList([])).data
			this.newMessage()
		}
	}
}
</script>

<template>
	<div id="register_user_modal" ref="modalElem" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="registerUserModalLabel" aria-hidden="true" data-bs-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content  vld-parent">
				<loading v-model:active="isLoading" :is-full-page="false"></loading>
				<div class="modal-header">
					<h3 class="modal-title" id="registerUserModalLabel">Create Your Account</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<h2 class="h4 mb-4" v-if="firstName">Hi {{ firstName }} {{ lastName }}, let&rsquo;s get you registered:</h2>
					<form @submit.prevent="registerForm" class="">
						<validation-error-msg v-if="Object.keys(validationErrors).length !== 0"></validation-error-msg>
						<validation-info-msg v-if="tokenError" :message="tokenError"></validation-info-msg>
						<template v-else>
							<input type="hidden" id="register_token" name="register_token" v-model="token">
							<input type="hidden" id="register_email" name="register_email" v-model="email">

							<div class="row mb-3">
								<div class="col-sm-18">
									<label for="password" class="form-label">Create a Password</label>
									<input type="password" id="register_password" class="form-control form-control-sm" v-model.trim="password" :class="{ 'is-invalid': 'password' in validationErrors }">
									<span id="help_block_password" v-for="(msg, index) in validationErrors['password']" :key="index" class="invalid-feedback">{{ msg }}</span>
								</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-18">
									<label for="register_password_confirmation" class="form-label">Type Password Again</label>
									<input type="password" id="register_password_confirmation" class="form-control form-control-sm" v-model.trim="passwordConfirmation" :class="{ 'is-invalid': 'password_confirmation' in validationErrors }">
									<span id="help_block_password_confirmation" v-for="(msg, index) in validationErrors['password_confirmation']" :key="index" class="invalid-feedback">{{ msg }}</span>
								</div>
							</div>

							<div id="agree_check_register" class="row mb-3" :class="hasError('tos_agree')">
								<div class="col form_check_group">
									<div class="form-check">
										<input class="form-check-input" type="checkbox" v-model="tosAgree" id="agent_agree_to_terms_checkbox_register">
										<label class="form-check-label" for="agent_agree_to_terms_checkbox_register">
											I have read and agree to the&nbsp;
										</label>
										<a href="javascript:;" @click="showTermsOfUseModal()">Terms of Use</a>
										<label class="form-check-label" for="agent_agree_to_terms_checkbox_register">
											&nbsp;for this site.
										</label>
									</div>
								</div>
								<span id="help_block_tos_agree" v-for="(msg, index) in validationErrors['tos_agree']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>

							<button type="submit" name="register_submit_btn" id="register_submit_btn" class="btn btn-primary btn-lg">Submit</button>
						</template>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" name="register_cancel_btn" id="register_cancel_btn" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>

	<BsSimpleModal title="Success" sizeClass="" id="registerSuccessModal" ref="registerSuccessModal">
		<template #body>
			Thank you for creating your account. <a href="javascript:;" @click="login()">Click here to log in.</a>
		</template>
	</BsSimpleModal>

</template>

<script>
import { Modal } from 'bootstrap'
import ValidationErrorMsg from '@/components/snippets/ValidationErrorMsg'
import ValidationInfoMsg from '@/components/snippets/ValidationInfoMsg'
import BsSimpleModal from '@/components/bootstrap/BsSimpleModal.vue'
import apiFortify from '@/api/fortify'
import Loading from 'vue-loading-overlay'

export default {
	inject: ['showTermsOfUseModal'],
	props: {
	},
	components: {
		ValidationErrorMsg,
		ValidationInfoMsg,
		BsSimpleModal,
		Loading,
	},
	data() {
		return {
			modalObj: null,
			token: null,
			tokenError: null,
			firstName: null,
			lastName: null,
			email: null,
			password: null,
			passwordConfirmation: null,
			validationErrors: {},
			isLoading: false,
			tosAgree: false,
		}
	},
	mounted() {
		this.modalObj = new Modal(this.$refs.modalElem)
		this.token = this.$route.params.token
	},
	expose: ['show'],
	methods: {
		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},

		show() {
			this.getUserByToken()
			this.modalObj.show()
		},

		login() {
			this.$refs.registerSuccessModal.hide()
			this.$router.push({ name: 'login' })
		},

		async getUserByToken() {
			let response = null

			try {
				response = await apiFortify.getUserByToken(this.token)
			} catch (err) {
				window.scrollTo(0, 0)
				this.tokenError = 'Sorry, the link you used is no longer valid.'
			}

			this.firstName = response.data.firstName
			this.lastName = response.data.lastName
			this.email = response.data.email
		},

		async registerForm() {
			this.validationErrors = {};

			this.isLoading = true

			try {
				let formData = {
					'token': this.token,
					'email': this.email,
					'password': this.password,
					'password_confirmation': this.passwordConfirmation,
					'tos_agree': this.tosAgree,
				}

				await apiFortify.register(formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrors = err.response.data.errors
			}

			this.isLoading = false

			if (Object.keys(this.validationErrors).length === 0) {
				// success
				this.modalObj.hide()
				this.$refs.registerSuccessModal.show()
			}
		},
	}
}
</script>

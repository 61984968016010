export default {
	formatUSD(val) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',

			// These options are needed to round to whole numbers if that's what you want.
			//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
			//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		});

	/*
		The section below was added due to an issue of deductibles, or any dollar amounts, that are not present in a plan.
		i.e. HMO plans do not have Out of Network deductibles, they hav 'N/A' in that field. When put through the sanitizer then passed here it would return 'NaN' and display that in the UI. This check ensures that if it is a number then proceed normally, but if what you will return is NaN, then return the value passed-('N/A').
	*/
		if(isNaN(val)) {
			return val;
		} else {
			return formatter.format(val);
		}
	},
	formatUSDwhole(val) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',

			// These options are needed to round to whole numbers if that's what you want.
			// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
			maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		});

	/*
		The section below was added due to an issue of deductibles, or any dollar amounts, that are not present in a plan.
		i.e. HMO plans do not have Out of Network deductibles, they hav 'N/A' in that field. When put through the sanitizer then passed here it would return 'NaN' and display that in the UI. This check ensures that if it is a number then proceed normally, but if what you will return is NaN, then return the value passed-('N/A').
	*/
		if(isNaN(val)) {
			return val;
		} else {
			return formatter.format(val);
		}
	},
	getTimestamp() {
		return Math.floor((new Date).getTime() / 1000);
	},

	checkApplicationCompletion(param) {
		return param !== null;
	},

	getType(paramArray, paramId = null) {
		if(paramId !== null && paramId !== '') {
			return paramArray.find(item => item.id === parseInt(paramId)).value;
		}
		return null;
	},

	formatClickableContactNumber(param) {
		if (param === null || param === '') {
			return null;
		}

		return '1' + param.replace(/\D+/g, '').trim().slice(0, 10);
	},

	getSubdomain() {
		const host = window.location.host;
		const parts = host.split('.');
		return parts[0];
	}
}

import apiHelper from '@/helpers/apiHelper';

export default {
	find() {
		return apiHelper.appGet(`census`);
	},

	update(data) {
		return apiHelper.appPut(`census`, data);
	},

	getMarketplaceUrl() {
		return apiHelper.getAppUrl('marketplace');
	},

	getAgentMarketplaceUrl() {
		return apiHelper.getAppUrl('marketplace/agent');
	},

	checkSubsidy(data) {
		return apiHelper.appPost('subsidy/check', data);
	}
};

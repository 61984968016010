import apiHelper from '@/helpers/apiHelper';

export default {
	find() {
		return apiHelper.appGet(`whyapplying`);
	},
	formData() {
		return apiHelper.get(`application/whyapplying`);
	},
	update(data) {
		return apiHelper.appPut(`whyapplying`, data);
	},
	create(data) {
		return apiHelper.post(`application/whyapplying`, data);
	},
	uploadFile(data) {
		return apiHelper.appPost(`whyapplying/fileupload`, data)
	}
};

<template>
	<div :id="id" class="card">
		<h4 class="card-header">{{ title }}</h4>
		<div class="card-body">
			<slot name="body"></slot>
		</div>
		<div v-if="footer" class="card-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			required: true
		},
		title: {
			required: true
		},
		footer: {
			default: true
		}
	},
}
</script>

<style>
legend {
	font-size: 1rem;
	margin-bottom: 0;
}

.filter_section {
	font-size: 0.85rem;
}

.filter_section .form-check {
	min-height: 1rem;
}

[data-bs-toggle="collapse"] .fa.collapse_toggle:before {
	content: "\f078";
}

[data-bs-toggle="collapse"].collapsed .fa.collapse_toggle:before {
	content: "\f054";
}

.badge {
	cursor: default;
}

th.sortable {
	cursor: pointer;
}

/*start fix for dropdown in responsive table being cut off
 *https://github.com/twbs/bootstrap/issues/11037#issuecomment-1266127639
 */
.table-responsive .dropdown {
	position: static !important;
}

@media (min-width: 768px) { /* not sure if this is needed? seemed to be fixed without it */
	.table-responsive {
		overflow-x: visible;
	}
}
/* end dropdown fix */
</style>

<div class="vld-parent"></div>

<template>
<div class="card vld-parent">
	<loading v-model:active="isLoading" :is-full-page="false"></loading>
	<div class="card-header">
		<div class="d-flex flex-column flex-md-row justify-content-between">
			<div class="d-flex justify-content-start align-items-center">
				<h3 class="h5 mb-0">Quoting</h3>
				<span class="badge rounded-pill text-bg-secondary ms-2">{{ totalQuotes }}</span>
				<select class="form-select form-select-sm ms-2" @change="loadData()" v-model="daysToShow">
					<option value="60">last 60 days</option>
					<option value="180">last 180 days</option>
					<option value="365">last 12 months</option>
				</select>
			</div>
			<div class="d-flex flex-column flex-md-row mt-2 mt-md-0 justify-content-end">
				<input @search="loadData()" class="form-control form-control-sm me-md-2" type="search" v-model="searchName" placeholder="Search name & email" />
				<div class="d-flex mt-2 mt-md-0 flex-grow justify-content-between">
					<button class="btn btn-sm btn-outline-secondary collapsed me-2 d-flex align-items-center" data-bs-toggle="collapse" data-bs-target="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters"><i class="fa me-2 collapse_toggle"></i>Filters</button>
					<button class="btn btn-sm btn-outline-secondary d-flex align-items-center" :class="{ disabled : exportButtonDisabled }" @click="exportExcel()"><i class="fa fa-file-excel me-2"></i>Export</button>
				</div>
			</div>
		</div>
	</div>
	<div id="collapseFilters" class="collapse">
		<div class="card-header">
			<form class="row row-cols-lg-auto filter_section">
<!--
				<div class="col-12">
					<fieldset>
						<legend>Carrier</legend>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="1" v-model="filterCarrier" id="filterCarrier1">
							<label class="form-check-label" for="filterCarrier1">
							BCBSM
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="2" v-model="filterCarrier" id="filterCarrier2">
							<label class="form-check-label" for="filterCarrier2">
							BCN
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="3" v-model="filterCarrier" id="filterCarrier3">
							<label class="form-check-label" for="filterCarrier3">
							Priority Health
							</label>
						</div>
					</fieldset>
				</div>
-->
				<div class="col-12">
					<fieldset>
						<legend>Products</legend>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="medical" v-model="filterProduct" id="quoteFilterMedical">
							<label class="form-check-label" for="quoteFilterMedical">
							Medical
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="dental" v-model="filterProduct" id="quoteFilterDental">
							<label class="form-check-label" for="quoteFilterDental">
							Dental
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="checkbox" value="vision" v-model="filterProduct" id="quoteFilterVision">
							<label class="form-check-label" for="quoteFilterVision">
							Vision
							</label>
						</div>
					</fieldset>
				</div>
				<div class="col-12">
					<fieldset>
						<legend>Started By</legend>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="radio" checked value="anyone" v-model="filterStartedBy" id="quoteFilterStartedByAnyone">
							<label class="form-check-label" for="quoteFilterStartedByAnyone">
							Anyone
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="radio" value="agent" v-model="filterStartedBy" id="quoteFilterStartedByAgent">
							<label class="form-check-label" for="quoteFilterStartedByAgent">
							Agent
							</label>
						</div>
						<div class="form-check">
							<input @change="loadData()" class="form-check-input" type="radio" value="consumer" v-model="filterStartedBy" id="quoteFilteStartedByConsumer">
							<label class="form-check-label" for="quoteFilteStartedByConsumer">
							Consumer
							</label>
						</div>
					</fieldset>
				</div>
				<div class="col-12" v-if="subdomains && subdomains.length > 1">
					<fieldset>
						<legend>Site</legend>
						<select class="form-select form-select-sm" @change="loadData()" v-model="filterSubdomain">
							<option value="">All</option>
							<option v-for="subdomain in subdomains" :key="subdomain" :value="subdomain">{{ subdomain }}</option>
						</select>
					</fieldset>
				</div>
			</form>
		</div>
	</div>
	<div class="card-body p-0" v-if="quotes.length > 0">
		<div class="table-responsive">
		<table class="table m-0">
			<thead>
				<tr>
					<th></th>
					<th class="sortable" @click="doSort('last_name')"><i class="fa" :class="sortCol == 'last_name' ? (sortOrderAscName ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort'"></i> Subscriber Name</th>
					<th>Started By</th>
					<th class="sortable" @click="doSort('created_at')"><i class="fa" :class="sortCol == 'created_at' ? (sortOrderAscCreated ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort'"></i> Quote Date</th>
					<th class="sortable" @click="doSort('effective_date')"><i class="fa" :class="sortCol == 'effective_date' ? (sortOrderAscEffective ? 'fa-sort-up' : 'fa-sort-down') : 'fa-sort'"></i> Effective Date</th>
					<th>Products</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(quote, index) in quotes" :key="index">
					<td>
						<div class="dropdown">
							<button class="btn btn-sm btn-outline-secondary dropdown-toggle py-0 px-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="fa fa-user"></i>
							</button>
							<ul class="dropdown-menu">
								<li><a class="dropdown-item" href="javascript:;" @click="getInfo(quote.application_id)">More Info</a></li>
								<li><a class="dropdown-item" href="#" @click="continueQuote(index)">Enroll</a></li>
								<li v-if="quote.sub_email"><a class="dropdown-item" :href="'mailto:' + quote.sub_email + '?subject=Your CoverageForOne Quote&body=Dear ' + quote.full_name + ',%0d%0a%0d%0a\<Your email text\>%0d%0a%0d%0aSincerely,%0d%0a' + fullName">Send Email</a></li>
								<li v-else><a class="dropdown-item disabled">Send Email</a></li>
								<li v-if="sbcs[quote.application_id]"><a class="dropdown-item" :href="sbcs[quote.application_id]" target="_blank">View Medical SBC</a></li>
							</ul>
						</div>
					</td>
					<td>{{ quote.primary_name }}</td>
					<td>{{ quote.started_by }}</td>
					<td>{{ quote.created_at }}</td>
					<td>{{ quote.effective_date }}</td>
					<td>
						<span v-if="quote.medical_product_id" v-tooltip data-bs-toggle="tooltip" class="badge text-bg-primary fw-normal me-1 px-1" :title="quote.medical_plan_name">M</span>
						<span v-if="quote.dental_product_id" v-tooltip data-bs-toggle="tooltip" class="badge text-bg-secondary fw-normal px-1 me-1" :title="quote.dental_plan_name">D</span>
						<span v-if="quote.vision_product_id" v-tooltip data-bs-toggle="tooltip" class="badge text-bg-info fw-normal px-1 me-1" :title="quote.vision_plan_name">V</span>
					</td>
				</tr>
			</tbody>
		</table>
		</div>
	</div>
	<div class="card-body text-center" v-else-if="! isLoading">
		<b>No results were found</b>
	</div>
	<div class="card-footer d-flex justify-content-center">
		<div :style="{ visibility : quotes.length > 0 ? 'visible' : 'hidden' }">
			<pagination-controls
				@loadPage="loadData"
				:prevLink="prevLink"
				:nextLink="nextLink"
				:pageCount="pageCount"
				:currentPage="currentPage"
			/>
		</div>
	</div>
</div>

<app-info-modal @resetAppId="resetAppId" :appId="appInfoId" ref="appInfoModal" />
</template>

<script>
import apiDashboard from "@/api/dashboard"
import AppInfoModal from '@/components/modules/AppInfoModal.vue'
import PaginationControls from "@/components/modules/PaginationControls.vue"
import Loading from 'vue-loading-overlay'
import displayHelper from "@/helpers/displayHelper"
import stepHelper from "@/helpers/stepHelper"
import { useStepStore } from '@/stores/step'
import { mapStores, mapState } from "pinia/dist/pinia";
import { useApplicationStore } from '@/stores/application'
import { useAgentInfoStore } from '@/stores/agentInfo'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	emits: [],
	props: {
		resultsPerPage: {
			required: true,
		},
	},
	components: {
		Loading,
		AppInfoModal,
		PaginationControls,
	},

	data() {
		return {
			isLoading: null,
			quotes: [],
			sbcs: {},
			totalQuotes: null,
			currentPage: null,
			pageCount: null,
			prevLink: null,
			nextLink: null,
			sortCol: 'created_at',
			sortOrderAsc: null,

			// set the default sort order of the column depending on type of data; allows us to remember the sort order per column
			sortOrderAscName: true,
			sortOrderAscCreated: false,
			sortOrderAscEffective: false,

			daysToShow: 60,
			filterProduct: [],
			filterStartedBy: '',
			filterSubdomain: '',
			subdomains: null,
			// filterCarrier: [],
			searchName: '',
			exportButtonDisabled: false,
			defaultTimeline: '60',

			// Select options
			timelineOptions: displayHelper.getDashboardTimelineArray(),

			appInfoId: null,
		}
	},

	mounted() {
		this.loadData()
	},

	created() {
	},

	computed: {
		...mapStores(useStepStore),
		...mapState(useAgentInfoStore, { fullName: 'fullName' })
	},

	methods: {
		async loadData(pageNum) {
			let options = this.getOptions(pageNum)

			try {
				this.isLoading = true
				const result = (await apiDashboard.getQuotes(options)).data
				this.quotes = result.data
				this.sbcs = result.sbcs
				this.totalQuotes = result.meta.total
				this.currentPage = result.meta.current_page
				this.pageCount = result.meta.last_page
				this.isLoading = false
				this.prevLink = result.links.prev
				this.nextLink = result.links.next
				this.subdomains = JSON.parse(result.subdomains)
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		},

		async exportExcel() {
			this.exportButtonDisabled = true;
			this.isLoading = true

			let options = this.getOptions()
			const file = (await apiDashboard.getQuotesExport(options))

			const link = document.createElement("a")
			link.href = window.URL.createObjectURL(new Blob([file.data]))
			link.setAttribute('download', 'Exported_Quotes.xlsx')
			document.body.appendChild(link)
			link.click()
			URL.revokeObjectURL(link.href)

			this.isLoading = false
			this.exportButtonDisabled = false;
		},

		getOptions(pageNum) {
			let options = { params: {} }

			if (typeof(pageNum) !== 'undefined') {
				options.params.page = pageNum
			}

			options.params.resultsPerPage = this.resultsPerPage
			options.params.sortCol = this.sortCol
			options.params.sortOrderAsc = this.sortOrderAsc
			options.params.daysToShow = this.daysToShow
			options.params.filterProduct = this.filterProduct
			options.params.filterSubdomain = this.filterSubdomain
			options.params.filterStartedBy = this.filterStartedBy
			// options.params.filterCarrier = this.filterCarrier
			options.params.searchName = this.searchName

			return options
		},

		doSort(column) {
			let prevSortCol = this.sortCol

			// if clicking again on same column header, reverse sort order
			let reverseSort = (column == prevSortCol)

			switch (column) {
				case 'last_name':
					if (reverseSort) this.sortOrderAscName = !this.sortOrderAscName
					this.sortOrderAsc = this.sortOrderAscName
					break
				case 'created_at':
					if (reverseSort) this.sortOrderAscCreated = !this.sortOrderAscCreated
					this.sortOrderAsc = this.sortOrderAscCreated
					break
				case 'effective_date':
					if (reverseSort) this.sortOrderAscEffective = !this.sortOrderAscEffective
					this.sortOrderAsc = this.sortOrderAscEffective
					break
			}

			this.sortCol = column

			this.loadData()
		},

		async continueQuote(quoteIndex) {
			let quote = this.quotes[quoteIndex]

			await useApplicationStore().initAppId(quote.application_id)
			this.$router.push({name: stepHelper.getRouteByStepId(quote.last_application_step_type_id)})
		},

		getInfo(id) {
			this.appInfoId = id
		},

		resetAppId() {
			this.appInfoId = null
		},
	},
}
</script>

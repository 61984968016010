<template>
	<div class="vld-parent">
		<loading v-model:active="isLoading" :is-full-page="true" />
	</div>
	<nav-bar />
	<div id="content" class="my-3" :class="containerFluid ? 'container-fluid' : 'container'">
		<div class="row g-3" v-if="layout === 'full_page'">
			<router-view />
		</div>
		<div class="row" v-else>
			<div class="col-md-6">
				<progress-nav />
			</div>
			<div class="col-md-30">
				<div class="card">
					<div class="card-body">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
	<the-footer />
	<session-timeout />

	<BsSimpleModal title="CoverageForOne Terms of Use Agreement" sizeClass="modal-lg" id="termsOfUseModal" ref="termsOfUseModal">
		<template #body>
			<TermsOfUseText />
		</template>
	</BsSimpleModal>

</template>

<script>
import NavBar from './components/NavBar.vue'
import TheFooter from './components/Footer.vue'
import ProgressNav from './components/ProgressNav.vue'
import SessionTimeout from './components/SessionTimeout.vue'
import BsSimpleModal from '@/components/bootstrap/BsSimpleModal.vue'
import TermsOfUseText from '@/components/snippets/TermsOfUseText.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { useApplicationStore } from './stores/application'
import { useAgentInfoStore } from '@/stores/agentInfo'

export default {
	components: {
		NavBar,
		ProgressNav,
		Loading,
		TheFooter,
		SessionTimeout,
		BsSimpleModal,
		TermsOfUseText,
	},
	data() {
		return {
			isLoading: false,
			layout: 'sidebar',
			containerFluid: false,
		}
	},
	created() {
		const agentInfoStore = useAgentInfoStore();
		const applicationStore = useApplicationStore();

		applicationStore.init();
		agentInfoStore.initStore(); // ensure agent branding is present 
	},
	provide() {
		return {
			showTermsOfUseModal: () => this.$refs.termsOfUseModal.show(),
			startLoading: () => this.isLoading = true,
			stopLoading: () => this.isLoading = false,
		}
	},
	mounted() {
	},
	watch: {
		$route(to) {
			this.layout = to.meta.layout;
			this.containerFluid = to.meta.containerFluid;
		}
	}
}
</script>

<template>
	<form @submit.prevent="submitForm" class="">
		<div class="contents">
			<div class="d-sm-flex justify-content-between mb-3 mb-sm-0">
				<h1 class="h2 page_title">Other Questions</h1>
				<required-field-legend></required-field-legend>
			</div>
			<validation-error-msg v-if="Object.keys(validationErrors).length !== 0"></validation-error-msg>

			<!-- Dental Coverage Section/Card Start -->
			<div id="bcbsm-dental-coverage-section" class="card mb-3" v-if="showBcbsmDentalCoverage">
				<div class="card-header h4">Dental Coverage</div>

				<div class="card-body">
					<p>The Affordable Care Act requires that individual market plans include the 10 categories of Essential Health Benefits (EHBs), one of which is pediatric dental benefits. However, when sold off the Exchange, the plan can excluded pediatric dental coverage as long as it is reasonably assured enrollees have such pediatric dental coverage elsewhere.</p>

					<p>This plan covers all 10 of the required EHBs for adults 19 years of age and older but excludes pediatric dental benefits for enrollees under 19 years of age. Therefore, you must attest to one of the following:</p>
					<hr>
					<div class="row">
						<div class="col mb-3" :class="hasError('bcbsm_dental_confirmation')">
							<label for="bcbsm_dental_confirmation" class="required_field form-label"><strong>Which of the following applies to you?</strong></label>
							<select id="bcbsm_dental_confirmation" name="bcbsm_dental_confirmation" class="form-select form-select-sm" v-model="bcbsmDentalConfirmation">
								<option selected disabled aria-disabled="true">Please choose one</option>
								<option v-for="dentalConf in dentalCoverageConfirmationBCBSM" :key="dentalConf.id" :value="dentalConf.id">{{ dentalConf.statement }}</option>
							</select>
							<span id="help_block_bcbsm_dental_confirmation" v-for="(msg, index) in validationErrors['bcbsm_dental_confirmation']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row mt-2" v-if="bcbsmDentalConfirmation === 2">
						<div class="col-md-18 mb-3" :class="hasError('bcbsm_dental_confirmation_ins_comp')">
							<label for="bcbsm_dental_confirmation_ins_comp" class="form-label">Insurance Company</label>
							<input type="text" id="bcbsm_dental_confirmation_ins_comp" name="bcbsm_dental_confirmation_ins_comp" class="form-control form-control-sm" v-model="bcbsmDentalConfirmationInsComp">
							<span id="help_block_bcbsm_dental_confirmation_ins_comp" v-for="(msg, index) in validationErrors['bcbsm_dental_confirmation_ins_comp']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-md-18" :class="hasError('bcbsm_dental_confirmation_pol_num')">
							<label for="bcbsm_dental_confirmation_pol_num" class="form-label">Policy Number</label>
							<input type="text" id="bcbsm_dental_confirmation_pol_num" name="bcbsm_dental_confirmation_pol_num" class="form-control form-control-sm" v-model="bcbsmDentalConfirmationPolNum">
							<span id="help_block_bcbsm_dental_confirmation_pol_num" v-for="(msg, index) in validationErrors['bcbsm_dental_confirmation_pol_num']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<hr>
					<p>By signing below, I acknowledge that the above statement about having or purchasing a qualified dental plan that includes pediatric dental coverage is true, to the best of my knowledge and belief, and that <strong>BCBSM/BCN</strong> will rely on my statement. I certify that my attestation covers all members on the contract.</p>
					<div class="card mb-3">
						<div class="card-body">
							<div class="h4">{{ subscriber.first_name }} {{ subscriber.last_name }}'s Signature</div>

							<div class="row">
								<div class="col-md-18" :class="hasError('bcbsm_signature')">
									<label for="bcbsm_signature" class="required_field form-label">Name (as shown above)</label>
									<input type="text" id="bcbsm_signature" class="form-control form-control-sm" v-model="bcbsmSignature">
									<span id="help_block_bcbsm_signature" v-for="(msg, index) in validationErrors['bcbsm_signature']" :key="index" class="invalid-feedback">{{ msg }}</span>
								</div>
								<div class="col-md-18" :class="hasError('bcbsm_signature_date')">
									<label for="bcbsm_signature_date" class="required_field form-label">Date</label>
									<input type="date" id="bcbsm_signature_date" class="form-control form-control-sm" v-model="bcbsmSignatureDate">
									<span id="help_block_bcbsm_signature_date" v-for="(msg, index) in validationErrors['bcbsm_signature_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
								</div>
							</div>

						</div>
					</div>

					<router-link :to="{ name: 'dental' }" class="">I want to select a dental plan instead</router-link>
				</div>

			</div>

			<div id="priority-dental-coverage-section" class="card mb-3" v-if="showPriorityDentalCoverage">
				<div class="card-header h4">Dental Coverage</div>

				<div class="card-body">
					<p>The Affordable Care Act requires that individual market plans include the 10 categories of Essential Health Benefits (EHBs), one of which is pediatric dental benefits. However, when sold off the Exchange, the plan can excluded pediatric dental coverage as long as it is reasonably assured enrollees have such pediatric dental coverage elsewhere.</p>

					<p>Individuals who are not purchasing coverage on the Health Insurance Marketplace must purchase pediatric dental benefits as part of Essential Health Benefits under health care reform. Even if you don’t have children under the age of 19, you are required to purchase pediatric dental.</p>
					<hr>
					<div class="col" :class="hasError('ph_dental_confirmation')">
						<label for="priority_dental_confirmation" class="required_field form-label"><strong>Which of the following applies to you?</strong></label>
						<select id="priority_dental_confirmation" name="priority_dental_confirmation" class="form-select form-select-sm" v-model="phDentalConfirmation">
							<option selected disabled aria-disabled="true">Please choose one</option>
							<option v-for="dentalConf in dentalCoverageConfirmationPriority" :key="dentalConf.id" :value="dentalConf.id">{{ dentalConf.statement }}</option>
						</select>
						<span id="help_block_priority_dental_confirmation" v-for="(msg, index) in validationErrors['ph_dental_confirmation']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>
					<hr>
					<p>By signing below, I acknowledge that the above statement about having or purchasing a qualified dental plan that includes pediatric dental coverage is true, to the best of my knowledge and belief, and that <strong>Priority Health</strong> will rely on my statement. I certify that my attestation covers all members on the contract.</p>
					<div class="card mb-3">
						<div class="card-body">
							<div class="h4">{{ subscriber.first_name }} {{ subscriber.last_name }}'s Signature</div>

							<div class="row">
								<div class="col-md-18" :class="hasError('ph_signature')">
									<label for="ph_signature" class="required_field form-label">Name (as shown above)</label>
									<input type="text" id="ph_signature" class="form-control form-control-sm" v-model="phSignature">
									<span id="help_block_ph_signature" v-for="(msg, index) in validationErrors['ph_signature']" :key="index" class="invalid-feedback">{{ msg }}</span>
								</div>
								<div class="col-md-18" :class="hasError('ph_signature_date')">
									<label for="ph_signature_date" class="required_field form-label">Date</label>
									<input type="date" id="ph_signature_date" class="form-control form-control-sm" v-model="phSignatureDate">
									<span id="help_block_ph_signature_date" v-for="(msg, index) in validationErrors['ph_signature_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
								</div>
							</div>

						</div>
					</div>

					<router-link :to="{ name: 'dental' }" class="">I want to select a dental plan instead</router-link>
				</div>

			</div>
			<!-- Dental Coverage Section/Card End -->

			<!-- Medicaid Eligibility Section/Card Start -->
			<div id="medicaid_eligibility-section" class="card mb-3" v-if="show_medicaid_eligibility">
				<div class="card-header h4">Medicaid Eligibility</div>
				<div class="card-body">
					<p>To be eligible for Medicaid, you need to be a Michigan resident and meet the financial criteria of the State of Michigan and the Federal Government. For more information on Medicais or CHIP, please visit <a href="https://www.michigan.gov/mdhhs">www.michigan.gov/mdhhs</a></p>
					<label for="medicaid_eligibility" class="required_field form-label"><strong>Is anyone listed on this application eligible for Medicaid or Children's Health Insurance Plan (CHIP)?</strong></label>
					<div class="col col-lg-18" :class="hasError('medicaid_eligibility')">
						<select id="medicaid_eligibility" name="medicaid_eligibility" class="form-select form-select-sm" v-model="medicaidEligibility">
							<option selected disabled aria-disabled="true">Please choose one</option>
							<option v-for="option in noYesOptions" :key="option.id" :value="option.id">{{ option.value }}</option>
						</select>
						<span id="help_block_medicaid_eligibility" v-for="(msg, index) in validationErrors['medicaid_eligibility']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>
				</div>
			</div>
			<!-- Medicaid Eligibility Section/Card End -->

			<!-- Previous BCBSM/BCN Medical Coverage Section/Card Start -->
			<div id="previous-bcbsm-bcn-section" class="card mb-3" v-if="isBlueCrossApp">
				<div class="card-header h4">Previous BCBSM/BCN Medical Coverage</div>
				<div class="card-body">
					<label for="has_previous_coverage" class="required_field form-label"><strong>Have you had BCBSM or BCN medical coverage in the past 60 days?</strong></label>
					<p class="m-0 mb-2 fw-light"><em>Note: The availability of continuous coverage depends on your event, event date and application date. Enrollee ID is required if you are a current BCBSM or BCN member seeking continuous coverage due to loss of employer sponsored coverage or due to death of the policy holder.</em></p>
					<div class="row">
						<div class="col col-lg-18" :class="hasError('has_previous_coverage')">
							<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`has_previous_coverage_${item.value}`" name="has_previous_coverage" v-model="hasPreviousCoverage" :value="item.id">
								<label class="form-check-label" :for="`has_previous_coverage_${item.value}`">{{ item.value }}</label>
							</div>
							<span id="help_block_has_previous_coverage" v-for="(msg, index) in validationErrors['has_previous_coverage']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col col-lg-18" :class="hasError('has_previous_coverage_id')" v-if="hasPreviousCoverage === 1">
							<input type="text" id="has_previous_coverage_id" name="has_previous_coverage_id" class="form-control form-control-sm" placeholder="Enrollee ID" aria-placeholder="Enrollee Id" v-model="hasPreviousCoverageId">
							<span id="help_block_has_previous_coverage_id" v-for="(msg, index) in validationErrors['has_previous_coverage_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- Previous BCBSM/BCN Medical Coverage Section/Card End -->

			<!-- Active Medical Coverage Section/Card Start -->
			<div id="has_active_coverage-section" class="card mb-3" v-if="showBcbsmActiveCoverage">
				<div class="card-header h4">Active Medical Coverage</div>
				<div class="card-body">
					<label for="has_active_coverage" class="required_field form-label"><strong>Are you or any family members applying for medical coverage or currently active under a medical plan?</strong></label>
					<div class="col col-lg-18" :class="hasError('has_active_coverage')">
						<select id="has_active_coverage" name="has_active_coverage" class="form-select form-select-sm" v-model="hasActiveCoverage">
							<option selected disabled aria-disabled="true">Please choose one</option>
							<option v-for="option in noYesOptions" :key="option.id" :value="option.id">{{ option.value }}</option>
						</select>
						<span id="help_block_has_active_coverage" v-for="(msg, index) in validationErrors['has_active_coverage']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>
				</div>
			</div>
			<!-- Active Medical Coverage Section/Card End -->

			<div id="ethnicity-section" class="card mb-3">
				<div class="card-header h4">Ethnicity</div>
				<div class="card-body">
					<p class="m-0"><strong>Check all that apply for all family members:</strong></p>
					<div class="container-fluid px-2 mb-3">
						<div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-2 p-0 m-0">
							<div class="col" v-for="ethnicity in ethnicityTypes" :key="ethnicity.id">
								<div class="form-check">
									<input :id="`ethnicity_type_${ethnicity.id}`" class="form-check-input" type="checkbox" :value="ethnicity.id" :name="`ethnicity_type_${ethnicity.id}`" v-model="ethnicities">
									<label class="form-check-label" :for="`ethnicity_type_${ethnicity.id}`">{{ ethnicity.desc }}</label>
								</div>
							</div>
						</div>
					</div>

					<p class="m-0"><strong>If Hispanic/Latino, ethnicity:</strong></p>
					<div class="container-fluid px-2">
						<div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-2 p-0 m-0">
							<div class="col" v-for="ethnicity in ethnicityTypesHispanic" :key="ethnicity.id">
								<div class="form-check">
									<input :id="`hispanic_ethnicity_type_${ethnicity.id}`" class="form-check-input" type="checkbox" :value="ethnicity.id" :name="`hispanic_ethnicity_type_${ethnicity.id}`" v-model="hispanicEthnicities">
									<label class="form-check-label" :for="`hispanic_ethnicity_type_${ethnicity.id}`">{{ ethnicity.desc }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Household Income/Who is Paying Section/Card Start -->
			<div id="household-income-section" class="card mb-3" v-if="isBlueCrossApp">
				<div class="card-body">
					<label class="form-label" for="yearly_household_income"><strong>My yearly household income is:</strong></label>
					<div class="row mb-4">
						<div class="col col-lg-18">
							<select id="yearly_household_income" name="yearly_household_income" class="form-select form-select-sm" v-model="yearlyHouseholdIncome">
								<option selected disabled aria-disabled="true">Please choose one</option>
								<option v-for="yearlyIncome in yearlyIncomes" :key="yearlyIncome.id" :value="yearlyIncome.id">{{ yearlyIncome.range }}</option>
							</select>
						</div>
					</div>

					<label class="required_field form-label" for="who_is_paying"><strong>Who will pay the premium for this policy?</strong></label>
					<div class="row">
						<div class="col col-lg-18" :class="hasError('who_is_paying')">
							<select id="who_is_paying" name="who_is_paying" class="form-select form-select-sm" v-model="whoIsPaying">
								<option selected disabled aria-disabled="true">Please choose one</option>
								<option v-for="option in whoIsPayingOptions" :key="option.id" :value="option.id">{{ option.answer }}</option>
							</select>
							<span id="help_block_who_is_paying" v-for="(msg, index) in validationErrors['who_is_paying']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div id="who_is_paying_other-container" class="col-lg-18" :class="hasError('who_is_paying_other')" v-if="whoIsPaying === '6'"> <!--todo: 'magic number' here... -->
							<input class="form-control form-control-sm" placeholder="Please Specify" maxlength="255" name="who_is_paying_other" type="text" id="who_is_paying_other" v-model="whoIsPayingOther">
							<span id="help_block_who_is_paying_other" v-for="(msg, index) in validationErrors['who_is_paying_other']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- Household Income/Who is Paying Section/Card End -->

			<div class="d-flex justify-content-between">
				<router-link :to="{ name: 'subInfo' }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>
				<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg">Continue <i class="fa fa-arrow-right"></i></button>
			</div>

		</div>
	</form>
</template>

<script>
import apiOther from "../api/other";
import RequiredFieldLegend from "./snippets/RequiredFieldLegend";
import {useApplicationStore} from "@/stores/application";
import Carriers from "@/enums/carrier";
import PlanTypes from "@/enums/plantype";
import appContext from "@/helpers/appContext";
import displayHelper from '@/helpers/displayHelper'
import ValidationErrorMsg from './snippets/ValidationErrorMsg'

const HMO = 2;

export default {
	inject: ['startLoading', 'stopLoading'],
	mixins: [ Carriers, PlanTypes, appContext ],
	components: {
		RequiredFieldLegend,
		ValidationErrorMsg,
	},
	data() {
		return {
			bcbsmDentalConfirmation: '',
			bcbsmDentalConfirmationInsComp: '',
			bcbsmDentalConfirmationPolNum: '',
			bcbsmSignature: '',
			bcbsmSignatureDate: '',
			phDentalConfirmation: '',
			phSignature: '',
			phSignatureDate: '',
			medicaidEligibility: '',
			hasPreviousCoverage: '',
			hasPreviousCoverageId: '',
			hasActiveCoverage: '',
			yearlyHouseholdIncome: '',
			whoIsPaying: '',
			whoIsPayingOther: '',
			ethnicities: [],
			hispanicEthnicities: [],
			nextPage: 'rap',

			show_medicaid_eligibility: false,

			subscriber: {},

			validationErrors: {},

			dentalCoverageConfirmationBCBSM: [
				{
					id: 1,
					statement: "All applicants are 19 years of age or older."
				},
				{
					id: 2,
					statement: "I have a separate qualified dental plan with another carrier that includes pediatric dental benefit coverage for applicants under 19 years of age."
				},
				{
					id: 3,
					statement: "I will have purchased a qualifying dental plan with pediatric dental coverage by the date my medical plan coverage starts."
				}
			],
			dentalCoverageConfirmationPriority: [
				{
					id: "1",
					statement: "Yes, I declare I have already purchased pediatric dental coverage through a certified stand-alone dental carrier."
				},
				{
					id: "2",
					statement: "No, I do not currently have pediatric dental coverage, but understand this is a requirement and certify my intent to purchase this coverage."
				}
			],
			noYesOptions: displayHelper.getNoYesArray(),
			whoIsPayingOptions: [
				{
					id: "1",
					answer: "Self",
				},
				{
					id: "2",
					answer: "Legal Guardian",
				},
				{
					id: "4",
					answer: "Family Member",
				},
				{
					id: "6",
					answer: "Other (please specify)",
				}
			],
			yearlyIncomes: [
				{
					id: null,
					range: ""
				},
				{
					id: "1",
					range: "Less than $30,000"
				},
				{
					id: "2",
					range: "$30,001 to $45,000"
				},
				{
					id: "3",
					range: "$45,001 to $70,000"
				},
				{
					id: "4",
					range: "$70,001 to $90,000"
				},
				{
					id: "5",
					range: "Greater than $90,001"
				}
			],
			ethnicityTypes: [
				{
					id: 1,
					desc: "White",
				},
				{
					id: 2,
					desc: "Black or African American",
				},
				{
					id: 3,
					desc: "American Indian or Alaska Native",
				},
				{
					id: 4,
					desc: "Asian Indian",
				},
				{
					id: 5,
					desc: "Chinese",
				},
				{
					id: 6,
					desc: "Filipino",
				},
				{
					id: 7,
					desc: "Japanese",
				},
				{
					id: 8,
					desc: "Korean",
				},
				{
					id: 9,
					desc: "Vietnamese",
				},
				{
					id: 10,
					desc: "Other Asian",
				},
				{
					id: 11,
					desc: "Native Hawaiian",
				},
				{
					id: 12,
					desc: "Guamanian or Chamorro",
				},
				{
					id: 13,
					desc: "Samoan",
				},
				{
					id: 14,
					desc: "Other Pacific Islander",
				},
				{
					id: 15,
					desc: "Other",
				},
			],
			ethnicityTypesHispanic: [],
		};
	},

	created() {
		this.startLoading()
		useApplicationStore().init(true).then(async () => {
			await this.loadForm()
			this.stopLoading()
		})
	},

	mounted() {
	},

	computed: {
		showBcbsmDentalCoverage() {
			return ! this.appContext_planType(this.PlanTypes.DENTAL) &&
				(this.appContext_carrierPlanType(this.Carriers.BCBSM, this.PlanTypes.MEDICAL) ||
				this.appContext_carrierPlanType(this.Carriers.BCN, this.PlanTypes.MEDICAL))
		},

		showPriorityDentalCoverage() {
			return ! this.appContext_planType(this.PlanTypes.DENTAL) && this.appContext_carrierPlanType(this.Carriers.PRIORITY, this.PlanTypes.MEDICAL)
		},

		showBcbsmActiveCoverage() {
			return ! this.appContext_planType(this.PlanTypes.MEDICAL) && (this.appContext_carrier(this.Carriers.BCBSM) || this.appContext_carrier(this.Carriers.BCN))
		},

		isBlueCrossApp() {
			return this.appContext_carrier(this.Carriers.BCBSM) || this.appContext_carrier(this.Carriers.BCN)
		},
		isPriorityApp() {
			return this.appContext_carrier(this.Carriers.PRIORITY)
		},
	},

	methods: {
		async updateForm() {
			this.validationErrors = {};

			this.startLoading();
			try {
				let formData = {
					'application_id': useApplicationStore().applicationId,
					'bcbsm_dental_confirmation': this.bcbsmDentalConfirmation,
					'bcbsm_dental_confirmation_ins_comp': this.bcbsmDentalConfirmationInsComp,
					'bcbsm_dental_confirmation_pol_num': this.bcbsmDentalConfirmationPolNum,
					'bcbsm_signature': this.bcbsmSignature,
					'bcbsm_signature_date': this.bcbsmSignatureDate,
					'ph_dental_confirmation': this.phDentalConfirmation,
					'ph_signature': this.phSignature,
					'ph_signature_date': this.phSignatureDate,
					'medicaid_eligibility': this.medicaidEligibility,
					'has_previous_coverage': this.hasPreviousCoverage,
					'has_previous_coverage_id': this.hasPreviousCoverageId,
					'has_active_coverage': this.hasActiveCoverage,
					'yearly_household_income': this.yearlyHouseholdIncome,
					'who_is_paying': this.whoIsPaying,
					'ethnicity_type': this.ethnicities,
					'hispanic_ethnicity_type': this.hispanicEthnicities,

					'next_page': this.nextPage
				}

				if (this.whoIsPaying == 6) { /* todo: 'magic number' here */
					formData['who_is_paying_other'] = this.whoIsPayingOther
				}

				await apiOther.update(formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrors = err.response.data.errors;
			}

			this.stopLoading()
			if (Object.keys(this.validationErrors).length === 0) {
				// success
				this.$router.push({ name: this.nextPage })
			}
		},

		submitForm() {
			this.updateForm()
		},

		async loadForm() {
			try {
				const result = (await apiOther.find()).data.data;

				if(result.selected_medical_network_id) {
					this.nextPage = result.selected_medical_network_id === HMO ? 'pcp' : 'rap'
				}

				this.subscriber = result.subscriber
				this.bcbsmDentalConfirmation = result.bcbsm_dental_confirmation
				this.bcbsmDentalConfirmationInsComp = result.bcbsm_dental_confirmation_ins_comp
				this.bcbsmDentalConfirmationPolNum = result.bcbsm_dental_confirmation_pol_num
				this.bcbsmSignature = result.bcbsm_signature
				this.bcbsmSignatureDate = result.bcbsm_signature_date
				this.phDentalConfirmation = result.ph_dental_confirmation
				this.phSignature = result.ph_signature
				this.phSignatureDate = result.ph_signature_date
				this.medicaidEligibility = result.medicaid_eligibility
				this.hasPreviousCoverage = result.has_previous_coverage
				this.hasPreviousCoverageId = result.has_previous_coverage_id
				this.hasActiveCoverage = result.has_active_coverage
				this.yearlyHouseholdIncome = result.yearly_household_income
				this.whoIsPaying = result.who_is_paying
				this.whoIsPayingOther = result.who_is_paying_other
				this.ethnicities = result.ethnicity_type
				this.hispanicEthnicities = result.hispanic_ethnicity_type

			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}

			this.ethnicityTypesHispanic = displayHelper.getEthnicityTypesHispanic(this.isBlueCrossApp, this.isPriorityApp)
		},

		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},

	},
}
</script>

import generalHelper from '../helpers/generalHelper'
import { defineStore } from 'pinia'
import apiBranding from "../api/branding";
import apiFortify from "../api/fortify";

export const useAgentInfoStore = defineStore({
	id: 'agentInfo',
	state: () => ({
		initPromise: null,
		initialized: false,
		fullName: '',
		fabricatorSubdomain: null, // subdomain of user who fabricated a login, used to restore branding
		agencyName: '',
		address: '',
		phone: '',
		email: '',
		fax: '',
		logo: '',
		displayBCBSM: false,
		displayPriority: false,
		displayMedicare: false,
		agentSubDomain: null, 
		allSubdomains: null,
		websiteUrl: null,
		npn: '',
		isAuthenticated: false,
		isAdmin: false,
	}),
	actions: {
		async initStore(subdomain = null) {
			// check if we have a current session
			let response = await apiFortify.getAuthState()

			if (response.data.is_authorized) {
				this.isAuthenticated = true
				this.isAdmin = response.data.is_admin
				this.agentSubDomain = response.data.agent_subdomain
				this.fabricatorSubdomain = response.data.admin_subdomain
			}

			if (! subdomain) {
				// if page is reloaded when logged in, use subdomain/branding associated with logged in agent
				if (this.isAuthenticated) {
					subdomain = this.agentSubDomain
				} else {
					subdomain = generalHelper.getSubdomain()
				}
			}

			this.initPromise = apiBranding.getBranding(subdomain);

			this.initPromise.then(result => {
				try {
					this.allSubdomains = result.data.subdomains
					this.npn = result.data.npn
					this.fullName = result.data.agent_name
					this.agencyName = result.data.agency_name
					this.address = result.data.address
					this.email = result.data.email
					this.phone = result.data.phone_number
					this.fax = result.data.fax_number
					this.logo = apiBranding.getLogoUrl(subdomain)
					this.displayBCBSM = result.data.display_bcbsm
					this.displayPriority = result.data.display_ph
					this.displayMedicare = result.data.display_medicare
					this.agentSubDomain = subdomain
					this.websitetUrl = result.data.websiteUrl
					this.initialized = true

				} catch (error) {
					this.error = error.message || 'Something went wrong!'
				}

			})

			this.initPromise.catch(error => {
				this.error = error.message || 'Failed to fetch agent branding';
			});

			return this.initPromise;
		},

		isInitialized() {
			return this.initialized;
		},

		agentFound() {
			return Boolean(this.fullName);
		},
	},
})

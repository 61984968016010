import apiPlan from "@/api/plan"
import apiPrint from "@/api/plan";

export default {
	//filters
	filterPlansByCarrier(plans, filterCarriers) {
		return filterCarriers.length ? plans.filter(plan => filterCarriers.includes(parseInt(plan.carrier))) : plans
	},
	filterPlansByNetwork(plans, filterNetworks) {
		return filterNetworks.length ? plans.filter(plan => filterNetworks.includes(plan.network)) : plans
	},
	filterPlansByPrice(plans, filterMinPrice, filterMaxPrice) {
		let finalPlans = plans

		if (filterMinPrice) {
			finalPlans = finalPlans.filter(plan => plan.rates.total > filterMinPrice)
		}

		if (filterMaxPrice) {
			finalPlans = finalPlans.filter(plan => plan.rates.total < filterMaxPrice)
		}

		return finalPlans
	},
	//comparison
	removeCompare(plan) {
		plan.compare = 0
	},
	//load plans
	async loadPlans(planTypeId) {
		this.startLoading()
		try {
			const result = (await apiPlan.getPlans(planTypeId)).data.data;

			this.plans = result.plans
		} catch (error) {
			this.error = error.message || 'Something went wrong!'
		}
		this.stopLoading()
	},
	//load filters
	async loadFilterValues(planTypeId) {
		try {
			const carriers = (await apiPlan.getCarriers(planTypeId)).data
			const networks = (await apiPlan.getNetworks(planTypeId)).data
			const attributes = (await apiPlan.getAttributes(planTypeId)).data
			const metals = (await apiPlan.getMetals(planTypeId)).data

			this.filters.forEach((value, index) => {
				switch (value.short) {
					case 'met':
						this.filters[index].options = metals;
						break;
					case 'car':
						this.filters[index].options = carriers;
						break;
					case 'net':
						this.filters[index].options = networks;
						break;
					case 'other':
						this.filters[index].options = attributes;
						break;
				}
			});
		} catch (error) {
			this.error = error.message || 'Something went wrong!';
		}
	},
	//download print compare document
	async printCompare(planTypeId, compareAll, printFilters) {
		this.startLoading();

		this.compareButtonDisable = true;
		const pdf = (await apiPrint.getPrintPdf(planTypeId, compareAll, printFilters))

		const link = document.createElement("a")
		link.href = window.URL.createObjectURL(new Blob([pdf.data]))
		link.setAttribute('download', 'compare.pdf')
		document.body.appendChild(link)
		link.click()
		URL.revokeObjectURL(link.href)
		this.compareButtonDisable = false;

		this.stopLoading();
	},
	//download print selection document
	async printSelection() {
		this.selectionButtonDisable = true;
		const pdf = (await apiPrint.getSelectedPdf())

		const link = document.createElement("a")
		link.href = window.URL.createObjectURL(new Blob([pdf.data]))
		link.setAttribute('download', 'benefits.pdf')
		document.body.appendChild(link)
		link.click()
		URL.revokeObjectURL(link.href)
		this.selectionButtonDisable = false;
	},
	//download print compare selections document
	async printCompareSelections(planTypeId, compareAll, printFilters) {
		this.startLoading();

		this.compareButtonDisable = true;
		const pdf = (await apiPrint.getPrintPdf(planTypeId, compareAll, printFilters))

		const link = document.createElement("a")
		link.href = window.URL.createObjectURL(new Blob([pdf.data]))
		link.setAttribute('download', 'compare.pdf')
		document.body.appendChild(link)
		link.click()
		URL.revokeObjectURL(link.href)
		this.compareButtonDisable = false;

		this.stopLoading();
	}
}

<template>
	<ul class="navbar-nav">
		<li v-if="agentInfoStore.isAuthenticated" class="nav-item dropdown px-2">
			<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ agentInfoStore.fullName }}</a>
			<div class="dropdown-menu" aria-labelledby="navbarDropdown">
				<a href="#" class="dropdown-item" @click="showChangePassword()">Change Password</a>
				<a href="#" class="dropdown-item" @click="unFabricate()" v-if="agentInfoStore.fabricatorSubdomain">Switch back to Admin</a>
				<a href="#" class="dropdown-item" @click="logout()">Log out</a>
			</div>
		</li>
		<li v-else class="nav-item px-2">
			<button href="#" class="btn btn-secondary" @click="modalLogin.show()">Agent Login</button>
		</li>
	</ul>

	<div id="agent_login_modal" ref="modalLogin" class="modal modal-sm fade" tabindex="-1" role="dialog" aria-labelledby="agentLoginModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content vld-parent">
				<loading v-model:active="isLoadingLogin" :is-full-page="false"></loading>
				<div class="modal-header">
					<h3 class="modal-title" id="agentLoginModalLabel">Agent Login</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<form @submit.prevent="loginForm" class="">
						<validation-error-msg-list v-if="Object.keys(validationErrorsLogin).length !== 0" :errors="validationErrorsLogin"></validation-error-msg-list>
						<validation-info-msg v-if="validationInfoLogin" :message="validationInfoLogin"></validation-info-msg>
						<label for="login_username" class="form-label">Email Address</label>
						<input type="email" id="login_username" class="form-control form-control-sm mb-3" v-model.trim="loginEmail" :class="{ 'is-invalid': 'email' in validationErrorsLogin }">
						<span id="help_block_login_username" v-for="(msg, index) in validationErrorsLogin['login_email']" :key="index" class="invalid-feedback">{{ msg }}</span>

						<div class="d-flex justify-content-between">
							<label for="login_password" class="form-label">Password</label>
							<a href="javascript:;" id="forgot_password_link" @click="showForgot()">Forgot Password?</a>
						</div>
						<input type="password" id="login_password" class="form-control form-control-sm mb-3" v-model.trim="loginPassword" :class="{ 'is-invalid': 'password' in validationErrorsLogin }">
						<span id="help_block_login_password" v-for="(msg, index) in validationErrorsLogin['login_password']" :key="index" class="invalid-feedback">{{ msg }}</span>

						<p class="mb-3 alert alert-info">By continuing, you agree to the <a href="javascript:;" @click="showTermsOfUseModal()">Terms of Use</a> for this site.</p>

						<button type="submit" name="login_submit_btn" id="login_submit_btn" class="btn btn-primary btn-lg">Submit</button>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div id="forgot_password_modal" ref="modalForgot" class="modal modal-sm fade" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content vld-parent">
				<loading v-model:active="isLoadingForgot" :is-full-page="false"></loading>
				<div class="modal-header">
					<h3 class="modal-title" id="forgotPasswordModalLabel">Forgot Password</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<form @submit.prevent="forgotForm" class="">
						<validation-error-msg-list v-if="Object.keys(validationErrorsForgot).length !== 0" :errors="validationErrorsForgot"></validation-error-msg-list>
						<validation-info-msg v-if="validationInfoForgot" :message="validationInfoForgot"></validation-info-msg>
						<label for="forgot_username" class="form-label">Email Address</label>
						<input type="email" id="forgot_username" class="form-control form-control-sm mb-3" v-model.trim="loginEmail" :class="{ 'is-invalid': 'username' in validationErrorsForgot }">

						<button type="submit" name="forgot_submit_btn" id="forgot_submit_btn" class="btn btn-primary btn-lg">Submit</button>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div id="change_password_modal" ref="modalChange" class="modal modal-sm fade" tabindex="-1" role="dialog" aria-labelledby="changePasswordModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content vld-parent">
				<loading v-model:active="isLoadingChange" :is-full-page="false"></loading>
				<div class="modal-header">
					<h3 class="modal-title" id="changePasswordModalLabel">Change Password</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<form @submit.prevent="changeForm" class="">
						<validation-error-msg-list v-if="Object.keys(validationErrorsChange).length !== 0" :errors="validationErrorsChange"></validation-error-msg-list>
						<validation-info-msg v-if="validationInfoChange" :message="validationInfoChange"></validation-info-msg>

						<template v-if="! changePasswordSuccess">
							<label for="change_password_current" class="form-label">Current Password</label>
							<input type="password" id="change_password_current" class="form-control form-control-sm mb-3" v-model.trim="changePasswordCurrent" :class="{ 'is-invalid': 'current_password' in validationErrorsChange }">

							<label for="change_password_new" class="form-label">New Password</label>
							<input type="password" id="change_password_new" class="form-control form-control-sm mb-3" v-model.trim="changePasswordNew" :class="{ 'is-invalid': 'new_password' in validationErrorsChange }">

							<label for="change_password_confirm" class="form-label">Confirm Password</label>
							<input type="password" id="change_password_confirm" class="form-control form-control-sm mb-3" v-model.trim="changePasswordConfirm" :class="{ 'is-invalid': 'new_password_confirmation' in validationErrorsChange }">

							<button type="submit" name="change_submit_btn" id="change_submit_btn" class="btn btn-primary btn-lg">Submit</button>
						</template>
						<template v-else>
							<button type="button" @click="this.modalChange.hide()" name="change_close_btn" id="change_close_btn" class="btn btn-primary btn-lg">Close</button>
						</template>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div class="toast-container top-0 position-fixed start-50 translate-middle-x p-3">
		<div id="logoutToast" ref="logoutToast" class="toast" role="alert" data-bs-delay="3000" aria-live="assertive" aria-atomic="true">
			<div class="toast-body text-center">
				You were logged out successfully.
			</div>
		</div>
	</div>

</template>

<script>
import { Modal } from 'bootstrap'
import { Toast } from 'bootstrap'
import Loading from 'vue-loading-overlay'
import apiFortify from '@/api/fortify'
import fabricateHelper from '@/helpers/fabricateHelper'
import { useAgentInfoStore } from '@/stores/agentInfo'
import ValidationErrorMsgList from '@/components/snippets/ValidationErrorMsgList'
import ValidationInfoMsg from '@/components/snippets/ValidationInfoMsg'
import { mapStores } from 'pinia'

export default {
	inject: ['showTermsOfUseModal'],
	components: {
		ValidationErrorMsgList,
		ValidationInfoMsg,
		Loading,
	},
	props: {
	},
	data() {
		return {
			validationErrorsLogin: {},
			validationErrorsForgot: {},
			validationErrorsLogout: {},
			validationErrorsChange: {},
			validationInfoLogin: '',
			validationInfoForgot: '',
			validationInfoChange: '',
			modalLogin: null,
			modalForgot: null,
			modalChange: null,
			loginEmail: null,
			loginPassword: null,
			newPassword: null,
			newPasswordRepeat: null,
			changePasswordCurrent: null,
			changePasswordNew: null,
			changePasswordConfirm: null,
			changePasswordSuccess: false,
			isLoadingLogin: false,
			isLoadingForgot: false,
			isLoadingChange: false,
		}
	},
	created() {
	},
	mounted() {
		this.modalLogin = new Modal(this.$refs.modalLogin)
		this.modalForgot = new Modal(this.$refs.modalForgot)
		this.modalChange = new Modal(this.$refs.modalChange)
		this.logoutToast = new Toast(this.$refs.logoutToast)
	},
	computed: {
		...mapStores(useAgentInfoStore),
	},
	watch: {
		$route(to) {
			if (to.meta.login) {
				this.modalLogin.show()
			}
		}
	},
	methods: {
		async loginForm() {
			const agentInfoStore = useAgentInfoStore()
			this.validationErrorsLogin = {}
			this.validationInfoLogin = ''

			this.isLoadingLogin = true

			let response = null

			try {
				let formData = {
					'email': this.loginEmail,
					'password': this.loginPassword,
				}

				response = await apiFortify.login(formData)
				await agentInfoStore.initStore(response.data.subdomain)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrorsLogin = err.response.data.errors
			}

			this.isLoadingLogin = false

			if (response && response.status == 200) {
				// success
				this.loginEmail = null
				this.loginPassword = null
				this.modalLogin.hide()
				this.$router.push({ name: 'dashboard-home' })
			}
		},
		async unFabricate() {
			this.$emit('is-loading', true)
			await fabricateHelper.unFabricate()
			this.$emit('is-loading', false)
		},
		async logout() {
			const agentInfoStore = useAgentInfoStore()

			let response = null

			try {
				response = await apiFortify.logout()
				agentInfoStore.isAuthenticated = false
				agentInfoStore.fabricatorSubdomain = null
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrorsLogout = err.response.data.errors
			}

			if (response && response.status == 204) {
				// success
				this.modalLogin.hide()
				this.logoutToast.show()
				this.$router.push({ name: 'root' })

				// need to re-init agent store to ensure branding matches subdomain
				agentInfoStore.initStore()
			}
		},
		async forgotForm() {
			this.validationErrorsForgot = {}
			this.validationInfoForgot = ''

			this.isLoadingForgot = true

			let response = null

			try {
				let formData = {
					'email': this.loginEmail,
				}

				response = await apiFortify.forgot(formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrorsForgot = err.response.data.errors
			}

			this.isLoadingForgot = false

			if (response && response.status == 200) {
				// success
				this.loginEmail = null
				this.validationInfoForgot = response.data.message
			}
		},

		async changeForm() {
			this.validationErrorsChange = {}
			this.validationInfoChange = ''

			this.isLoadingChange = true

			let response = null

			try {
				let formData = {
					'current_password': this.changePasswordCurrent,
					'new_password': this.changePasswordNew,
					'new_password_confirmation': this.changePasswordConfirm,
				}

				response = await apiFortify.changePassword(formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrorsChange = err.response.data.errors
			}

			this.isLoadingChange = false

			if (response && response.status == 200) {
				// success
				this.changePasswordCurrent = null
				this.changePasswordNew = null
				this.changePasswordConfirm = null
				this.changePasswordSuccess = true

				this.validationInfoChange = 'Your password has been changed.'
			}
		},

		showForgot() {
			this.modalLogin.hide()
			this.modalForgot.show()
		},

		showChangePassword() {
			this.changePasswordSuccess = false
			this.validationInfoChange = ''
			this.modalChange.show()
		}
	}
}
</script>

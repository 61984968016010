<template>
	<div id="reset_password_modal" ref="modalElem" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="resetPasswordModalLabel" aria-hidden="true" data-bs-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content vld-parent">
				<div class="modal-header">
					<loading v-model:active="isLoading" :is-full-page="false"></loading>
					<h3 class="modal-title" id="resetPasswordModalLabel">Reset Password</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<form @submit.prevent="resetForm" class="">
						<validation-error-msg-list v-if="Object.keys(validationErrors).length !== 0" :errors="validationErrors"></validation-error-msg-list>
						<validation-info-msg v-if="validationInfo" message="validationInfo"></validation-info-msg>
						<input type="hidden" name="token" id="token" v-model="token">
						<input type="hidden" name="email" id="email" v-model="email">

						<div class="row">
							<div class="col-sm-18 mb-3">
								<label for="password" class="form-label">New Password</label>
								<input type="password" id="password" class="form-control form-control-sm" v-model.trim="password" :class="{ 'is-invalid': 'username' in validationErrors }">
								<span id="help_block_password" v-for="(msg, index) in validationErrors['password']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-18 mb-3">
								<label for="password_confirmation" class="form-label">Type New Password Again</label>
								<input type="password" id="password_confirmation" class="form-control form-control-sm" v-model.trim="passwordConfirmation" :class="{ 'is-invalid': 'username' in validationErrors }">
								<span id="help_block_password_confirmation" v-for="(msg, index) in validationErrors['password_confirmation']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>

						<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg">Submit <i class="fa fa-arrow-right"></i></button>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" name="cancel_btn" id="cancel_btn" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>

	<BsSimpleModal title="Success" sizeClass="" id="resetSuccessModal" ref="resetSuccessModal">
		<template #body>
			Your password has been reset. <a href="javascript:;" @click="login()">Click here to log in.</a>
		</template>
	</BsSimpleModal>

</template>

<script>
import { Modal } from 'bootstrap'
import ValidationErrorMsgList from '@/components/snippets/ValidationErrorMsgList'
import ValidationInfoMsg from '@/components/snippets/ValidationInfoMsg'
import BsSimpleModal from '@/components/bootstrap/BsSimpleModal.vue'
import apiFortify from '@/api/fortify'
import Loading from 'vue-loading-overlay'

export default {
	props: {
	},
	components: {
		ValidationErrorMsgList,
		ValidationInfoMsg,
		BsSimpleModal,
		Loading,
	},
	data() {
		return {
			modalObj: null,
			token: null,
			email: null,
			password: null,
			passwordConfirmation: null,
			validationErrors: {},
			validationInfo: '',
			isLoading: false,
		}
	},
	mounted() {
		this.modalObj = new Modal(this.$refs.modalElem)
		this.email = this.$route.params.email
		this.token = this.$route.params.token
	},
	expose: ['show'],
	methods: {
		show() {
			this.modalObj.show();
		},

		login() {
			this.$refs.resetSuccessModal.hide()
			this.$router.push({ name: 'login' })
		},
		async resetForm() {
			this.validationErrors = {}
			this.validationInfo = ''

			this.isLoading = true

			try {
				let formData = {
					'email': this.email,
					'token': this.token,
					'password': this.password,
					'password_confirmation': this.passwordConfirmation,
				}

				await apiFortify.reset(formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrors = err.response.data.errors
			}

			this.isLoading = false

			if (Object.keys(this.validationErrors).length === 0) {
				// success
				this.modalObj.hide()
				this.$refs.resetSuccessModal.show()
			}
		},

	}
}
</script>

<template>
	<div class="card-footer d-flex flex-column flex-sm-row">
		<div>
			<a :href="plan.sbc" target="_blank" class="text-decoration-none" v-if="plan.sbc">Benefits Summary</a>
		</div>
		<span class="divider d-none d-sm-inline mx-3" aria-hidden="true" v-if="plan.sbc">|</span>
		<div>
			<a :href="plan.baag" target="_blank" class="text-decoration-none" v-if="plan.baag">Plan Details</a>
		</div>
		<span class="divider d-none d-sm-inline mx-3" aria-hidden="true" v-if="plan.baag">|</span>
		<div v-if="showRx">
			<a href="https://www.bcbsm.com/2023-select-ppo-druglist" class="text-decoration-none" target="_blank" v-if="plan.id < 391 && plan.carrier == Carriers.BCBSM">Prescriptions</a>
			<a href="https://www.bcbsm.com/2023-select-hmo-druglist" class="text-decoration-none" target="_blank" v-else-if="plan.id < 391 && plan.carrier == Carriers.BCN">Prescriptions</a>
			<a href="https://fm.formularynavigator.com/FBO/208/Individual_Optimized_2023.pdf" class="text-decoration-none" target="_blank" v-else-if="plan.id < 391 && plan.carrier == Carriers.PRIORITY">Prescriptions</a>
			<a href="https://www.bcbsm.com/2024-select-ppo-druglist" class="text-decoration-none" target="_blank" v-else-if="plan.carrier == Carriers.BCBSM">Prescriptions</a>
			<a href="https://www.bcbsm.com/2024-select-hmo-druglist" class="text-decoration-none" target="_blank" v-else-if="plan.carrier == Carriers.BCN">Prescriptions</a>
			<a href="https://fm.formularynavigator.com/FBO/208/MyPriority_Individual_2024.pdf" class="text-decoration-none" target="_blank" v-else-if="plan.carrier == Carriers.PRIORITY">Prescriptions</a>
		</div>
		<span v-if="showRx" class="divider d-none d-sm-inline mx-3" aria-hidden="true">|</span>
		<div>
			<a href="https://www.bcbsm.com/index/find-a-doctor.html" class="text-decoration-none" target="_blank" v-if="plan.carrier == Carriers.BCBSM || plan.carrier == Carriers.BCN">Find Provider</a>
			<a href="https://web.healthsparq.com/healthsparq/public/#/one/insurerCode=PH_I&brandCode=PH" class="text-decoration-none me-3" target="_blank" v-else-if="plan.carrier == Carriers.PRIORITY">Find Provider</a>
		</div>
	</div>
</template>

<script>
import Carriers from "@/enums/carrier";

export default {
	mixins: [ Carriers ],
	props: {
		plan: {
			required: true
		},
		showRx: {
			required: true
		},
	},
}
</script>

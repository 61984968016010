import { defineStore } from 'pinia'
import apiHelper from '@/helpers/apiHelper'
import generalHelper from '@/helpers/generalHelper'
import VueCookies from "vue-cookies"

export const useApplicationStore = defineStore({
	id: 'application',

	state: () => ({
		initialized: false,
		error: null,
		initPromise: null,
		// application data below
		applicationId: null,
		effectiveDate: null,
		birthDate: null,
		enrollmentPeriodId: null,
		enrollmentPeriodTypeId: null,
		medicalCarrierId: null,
		dentalCarrierId: null,
		visionCarrierId: null,
		medicalNetworkTypeId: null,
		skipMedical: null,
		skipDental: null,
		skipVision: null,
		skipOther: null,
		skipPcp: null,
		submissionTime: null
	}),

	actions: {

		init(force = false) {
			if (this.initPromise && ! force) {
				return this.initPromise
			}
			this.initialized = false

			this.initPromise = apiHelper.get(`application/active`)

			this.initPromise.then(result => {
				this.setAppData(result)
				this.initialized = true
			})

			this.initPromise.catch(error => {
				this.error = error
				console.log('ERROR with initialize app: ' + error)
			})

			return this.initPromise
		},

		initAppId(appId) {
			this.initialized = false

			let options = { params: {
				'application_id': appId
			}}
			this.initPromise = apiHelper.get(`application/active/`, options)

			this.initPromise.then(result => {
				this.setAppData(result)
				this.initialized = true
			})

			this.initPromise.catch(error => {
				this.error = error
				console.log('ERROR with initialize app: ' + error)
			})

			return this.initPromise
		},

		setAppData(result) {
			// todo: fill out other aspects of application data
			this.applicationId = result.data.applicationId
			this.effectiveDate = result.data.effectiveDate
			this.birthDate = result.data.birthDate
			this.enrollmentPeriodId = result.data.enrollmentPeriodId
			this.enrollmentPeriodTypeId = result.data.enrollmentPeriodTypeId
			this.medicalCarrierId = parseInt(result.data.medicalCarrierId)
			this.dentalCarrierId = parseInt(result.data.dentalCarrierId)
			this.visionCarrierId = parseInt(result.data.visionCarrierId)
			this.medicalNetworkTypeId = parseInt(result.data.medicalNetworkTypeId)
			this.skipMedical = result.data.skipMedical
			this.skipDental = result.data.skipDental
			this.skipVision = result.data.skipVision
			this.skipOther = result.data.skipOther
			this.skipPcp = result.data.skipPcp
			this.submissionTime = result.data.submissionTime
		},

		isInitialized() {
			return this.initialized
		},

		isExisting() {
			return Boolean(this.applicationId)
		},

		isSubmitted() {
			return Boolean(this.submissionTime)
		},

		updateLastAccessTime() {
			VueCookies.set('lastAccessTime', generalHelper.getTimestamp(), { path: '/' })
		},

		getLastAccessTime() {
			return VueCookies.get('lastAccessTime')
		}

	}
})

import apiHelper from '@/helpers/apiHelper';

export default {
	find() {
		return apiHelper.appGet('pcp');
	},

	update(data) {
		return apiHelper.appPut(`pcp`, data);
	}
};

<template>
	<div class="alert alert-info">
		{{ message }}
	</div>
</template>

<script>
export default {
	props: {
		message: {
			required: true
		},
	},
}
</script>

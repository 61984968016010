<template>
	<div class="card review_total mb-3">
		<div class="card-body">
			<div class="text-center">
				Your total cost: <div class="d-inline-block">{{ formatUSD(totalCost) }}<div class="permo">per<br>month</div></div>
			</div>
		</div>
		<div v-if="footer" class="card-footer text-center">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script>
import generalHelper from '@/helpers/generalHelper'

export default {
	props: {
		medicalPlan: {
			required: true,
		},
		dentalPlan: {
			required: true
		},
		visionPlan: {
			required: true
		},
		footer: {
			default: true
		},
	},
	data() {
		return {
		}
	},
	methods: {
		formatUSD: generalHelper.formatUSD,
		formatUSDwhole: generalHelper.formatUSDwhole,
	},
	computed: {
		totalCost() {
			let totalCost = 0.00
			if (this.medicalPlan) {
				totalCost += this.medicalPlan.rates.total
			}
			if (this.dentalPlan) {
				totalCost += this.dentalPlan.rates.total
			}
			if (this.visionPlan) {
				totalCost += this.visionPlan.rates.total
			}
			return totalCost
		},

	}
}
</script>

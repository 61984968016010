<template>
	<div class="mb-3" :class="{ 'has-error': agentNotFound }">
		<form @submit.prevent="search()">
			<label for="agent_npn" class="form-label">Agent NPN</label>
			<div class="input-group">
				<input id="agent_npn" type="search" class="form-control" aria-label="Agent NPN" maxlength="10" v-model="agentNpn">
				<button class="btn btn-outline-secondary" type="submit" id="agent_npn_search" :disabled="disableLogin || agentNpn.length == 0">Search</button>
			</div>
			<div v-if="agentNotFound" id="help_block_agent_npn" class="invalid-feedback">Agent not found</div>
		</form>
	</div>
	<div v-if="foundAgentNpn">
		<div class="text-center mb-2">
			User{{ userList.length > 1 ? 's' : '' }} associated with <b>{{ agentName }}</b>:
		</div>

		<table class="table" v-if="userList.length > 0">
			<tbody>
				<tr v-for="(user, index) in userList" :key="index">
					<td>{{ user.first_name }} {{ user.last_name }}</td>
					<td>{{ user.email }}</td>
					<td><button @click="fabricate(user.id)" class="btn btn-sm btn-primary" type="submit" :id="'login_agent_' + user.id" :disabled="disableLogin">Log In</button></td>
				</tr>
			</tbody>
		</table>
		<div class="alert alert-info text-center" v-else>
			No user accounts were found for the provided NPN.
		</div>
	</div>
</template>

<script>
import fabricateHelper from '@/helpers/fabricateHelper'
import apiBranding from '@/api/branding'
import apiAdmin from '@/api/admin'

export default {
	emits: ['is-loading', 'success'],
	components: {
	},
	data() {
		return {
			agentNpn: '',
			foundAgentNpn: null,
			userList: [],
			agentName: null,
			agentNotFound: false,
			disableLogin: false,
		}
	},
	created() {
	},
	computed: {
	},
	methods: {
		resetUsers() {
			this.userList = []
			this.foundAgentNpn = null
			this.agentName = null
		},
		async search() {
			this.disableLogin = true
			this.$emit('is-loading', true)
			this.resetUsers()

			try {
				this.agentNotFound = false
				let response = await apiBranding.getBrandingByNumber(this.agentNpn)

				if (response.data) {
					this.agentName = response.data.agent_name
					this.foundAgentNpn = this.agentNpn
				} else {
					this.agentNotFound = true					
				}

			} catch (err) { // this should be how we determine no agent found but currently it returns 200 (success) with empty data
				this.agentNotFound = true
			}

			if (this.foundAgentNpn) {
				// maybe do this in parallel with apiBranding API call? But this way we know we have a good agent NPN first
				try {
					let getUsersResponse = await apiAdmin.getUsersByNPN(this.foundAgentNpn)

					if (getUsersResponse.data) {
						this.userList = getUsersResponse.data
					}
				} catch (err) { 
					console.log('there was a problem getting users list')
				}
			}

			this.disableLogin = false
			this.$emit('is-loading', false)
		},
		async fabricate(userId) {
			this.disableLogin = true
			this.$emit('is-loading', true)
			try {
				await fabricateHelper.fabricate(userId, this.foundAgentNpn)
				this.$router.push({name: 'dashboard-home'})
				this.$emit('success')
				this.resetUsers()
				this.agentNpn = ''
			} catch (err) {
				console.log('there was a problem fabricating login')
			}

			this.disableLogin = false
			this.$emit('is-loading', false)
		}
	},
}
</script>


import apiHelper from '@/helpers/apiHelper';

export default {
	getCountySelectList(zipCode) {
		return apiHelper.appGet(`countyList/${zipCode}`);
	},

	getBillingCountySelectList(zipCode = null, state = null) {
		return apiHelper.appGet(`billingStateZip/${zipCode}/${state}`);
	}
}

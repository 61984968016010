<template>
	<form @submit.prevent="submitForm" class="">
		<div class="contents">
			<div class="d-sm-flex justify-content-between mb-3 mb-sm-0">
				<h1 class="page_title h2">Subscriber Information</h1>
				<required-field-legend></required-field-legend>
			</div>

			<validation-error-msg v-if="Object.keys(validationErrors).length !== 0"></validation-error-msg>

			<div class="card mb-3">
				<h2 class="card-header h4">Primary</h2>
				<div class="card-body pb-0">
					<p>This is the main person applying for this plan. All of your information will be kept confidential and only used for this application.</p>
					<div class="row gx-3">
						<div class="col-18 col-sm-15 col-lg-6 mb-3">
							<label for="sub_title" class="form-label">Title</label>
							<select id="sub_title" class="form-select form-select-sm" v-model="subTitle">
								<option></option>
								<option v-for="item in titleOptions" :value="item.value" :key="item.id">{{ item.value }}</option>
							</select>
						</div>
						<!-- Force next columns to break to new lines at sm and md breakpoint -->
						<div class="w-100 d-sm-block d-lg-none"></div>
						<div class="col-sm-15 col-lg-12 mb-3" :class="hasError('sub_first_name')">
							<label for="sub_first_name" class="required_field form-label">First Name</label>
							<input type="text" id="sub_first_name" class="form-control form-control-sm" v-model.trim="subFirstName" maxlength="30" :class="{ 'is-invalid': 'first_name' in validationErrors }">
							<span id="help_block_sub_first_name" v-for="(msg, index) in validationErrors['sub_first_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 col-sm-6 col-lg-6 mb-3" :class="hasError('sub_middle')">
							<label for="sub_middle" class="form-label">Initial</label>
							<input type="text" id="sub_middle" class="form-control form-control-sm" v-model.trim="subMiddle" maxlength="1">
						</div>
						<div class="col-sm-15 col-lg-12 mb-3" :class="hasError('sub_last_name')">
							<label for="sub_last_name" class="required_field form-label">Last Name</label>
							<input type="text" id="sub_last_name" class="form-control form-control-sm" v-model.trim="subLastName" maxlength="30" :class="{ 'is-invalid': 'last_name' in validationErrors }">
							<span id="help_block_first_name" v-for="(msg, index) in validationErrors['sub_last_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-18 col-md-12 mb-3">
							<label for="sub_gender" class="form-label">Gender</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in genderOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`sub_gender_${item.value}`" name="sub_gender" disabled="disabled" v-model="subGender" :value="item.value">
								<label class="form-check-label" :for="`sub_gender_${item.value}`">{{ item.display }}</label>
							</div>
						</div>
						<div class="col-18 col-md-12 mb-3">
							<label for="sub_birth_date" class="form-label">Birth Date</label>
							<input type="date" id="sub_birth_date" class="form-control form-control-sm" v-model.trim="subBirthDate" disabled="disabled">
						</div>
						<div class="col-18 col-md-12 mb-3">
							<label for="sub_tobacco" class="form-label">Tobacco User?</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`sub_tobacco_${item.value}`" name="sub_tobacco" disabled="disabled" v-model="subTobacco" :value="item.id">
								<label class="form-check-label" :for="`sub_tobacco_${item.value}`">{{ item.value }}</label>
							</div>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-36 col-sm-18 mb-3" :class="hasError('sub_ssn')">
							<label for="sub_ssn" class="required_field form-label">SSN / Personal Tax ID</label>
							<input type="text" id="sub_ssn" class="form-control form-control-sm" v-maska="'###-##-####'" v-model.trim="subSsn">
							<span id="help_block_sub_ssn" v-for="(msg, index) in validationErrors['sub_ssn']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 mb-3" v-if="appContext_carrier(Carriers.BCBSM) || appContext_carrier(Carriers.BCN)" :class="hasError('sub_us_citizen')">
							<label for="sub_us_citizen" class="required_field form-label">U.S. Citizen?</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`sub_us_citizen_${item.value}`" name="sub_us_citizen" v-model="subUsCitizen" :value="item.id">
								<label class="form-check-label" :for="`sub_us_citizen_${item.value}`">{{ item.value }}</label>
							</div>
							<span id="help_block_sub_ssn" v-for="(msg, index) in validationErrors['sub_us_citizen']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>

					<div class="row">
						<div class="col-md-18 mb-3" :class="hasError('email')">
							<label for="email" class="required_field form-label">Email Address</label>
							<input type="email" id="email" class="form-control form-control-sm" v-model.trim="email">
							<span id="help_block_email" v-for="(msg, index) in validationErrors['email']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-21 col-md-18 mb-3" :class="hasError('primary_phone')">
							<label for="primary_phone" class="required_field form-label">Primary Phone #</label>
							<input type="phone" id="primary_phone" class="form-control form-control-sm" v-maska="'(###) ###-####'" v-model.trim="primaryPhone">
							<span id="help_block_primary_phone" v-for="(msg, index) in validationErrors['primary_phone']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-15 col-md-18 mb-3" :class="hasError('primary_phone_type_id')">
							<label for="primary_phone_type_id" class="required_field form-label"><span class="d-none d-sm-inline">Primary </span>Phone Type</label>
							<select id="primary_phone_type_id" class="form-select form-select-sm" v-model="primaryPhoneTypeId">
								<option></option>
								<option v-for="item in phoneTypeLimit" :value="item.id" :key="item.id">{{ item.value }}</option>
							</select>
							<span id="help_block_primary_phone_type_id" v-for="(msg, index) in validationErrors['primary_phone_type_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-21 col-md-18 mb-3" :class="hasError('alternate_phone')">
							<label for="alternate_phone" class="form-label">Alternate Phone #</label>
							<input type="phone" id="alternate_phone" class="form-control form-control-sm" v-maska="'(###) ###-####'" v-model.trim="alternatePhone">
							<span id="help_block_alternate_phone" v-for="(msg, index) in validationErrors['alternate_phone']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-15 col-md-18 mb-3" :class="hasError('alternate_phone_type_id')">
							<label for="alternate_phone_type_id" class="form-label"><span class="d-none d-sm-inline">Alternate </span>Phone Type</label>
							<select id="alternate_phone_type_id" class="form-select form-select-sm" v-model="alternatePhoneTypeId">
								<option></option>
								<option v-for="item in phoneTypeLimit" :value="item.id" :key="item.id">{{ item.value }}</option>
							</select>
							<span id="help_block_alternate_phone_type_id" v-for="(msg, index) in validationErrors['alternate_phone_type_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-27 mb-3" :class="hasError('residential_street')">
							<label for="residential_street" class="required_field form-label">Residential Address</label>
							<input type="text" id="residential_street" class="form-control form-control-sm" v-model.trim="residentialStreet">
							<span id="help_block_residential_street" v-for="(msg, index) in validationErrors['residential_street']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-md-12 mb-3" :class="hasError('residential_city')">
							<label for="residential_city" class="required_field form-label">City</label>
							<input type="text" id="residential_city" class="form-control form-control-sm" v-model.trim="residentialCity">
							<span id="help_block_residential_city" v-for="(msg, index) in validationErrors['residential_city']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-md-6 mb-3" :class="hasError('residential_state')">
							<label for="residential_state" class="required_field form-label">State</label>
							<select id="residential_state" class="form-select form-select-sm" v-model="residentialState">
								<option></option>
								<option v-for="(item, key) in stateOptions" :value="key" :key="key">{{ item }}</option>
							</select>
							<span id="help_block_residential_state" v-for="(msg, index) in validationErrors['residential_state']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-md-9 mb-3">
							<label for="residential_zip" class="form-label">Zip Code</label>
							<input type="text" id="residential_zip" class="form-control form-control-sm" v-model.trim="residentialZip" disabled="disabled">
						</div>
						<div class="col-md-9 mb-3" v-show="this.residentialCountyOptions.length > 1">
							<label for="residential_county" class="form-label">County</label>
							<select id="residential_county" class="form-select form-select-sm" v-model="residentialCountyId" disabled="disabled">
								<option v-for="item in residentialCountyOptions" :value="item.id" :key="item.id">{{ item.description }}</option>
							</select>
						</div>
					</div>
					<div v-if="appContext_carrier(Carriers.BCBSM) || appContext_carrier(Carriers.BCN)">
						<div class="form-check mb-3">
							<input type="checkbox" id="billing_provided" class="form-check-input" value="1" v-model="billingProvided" @click="clearBilling">
							<label for="billing_provided" class="form-check-label">Bill me at a different address</label>
						</div>
						<div class="row">
							<div class="col-md-27 mb-3" :class="hasError('billing_street')">
								<label for="billing_street" class="form-label">Billing Address</label>
								<input type="text" id="billing_street" class="form-control form-control-sm" v-model.trim="billingStreet" :disabled="!billingProvided">
								<span id="help_block_billing_street" v-for="(msg, index) in validationErrors['billing_street']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
						<div class="row gx-3">
							<div class="col-md-12 mb-3" :class="hasError('billing_city')">
								<label for="billing_city" class="form-label">City</label>
								<input type="text" id="billing_city" class="form-control form-control-sm" v-model.trim="billingCity" :disabled="!billingProvided">
								<span id="help_block_billing_city" v-for="(msg, index) in validationErrors['billing_city']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-6 mb-3" :class="hasError('billing_state')">
								<label for="billing_state" class="form-label">State</label>
								<select id="billing_state" class="form-select form-select-sm" v-model="billingState" :disabled="!billingProvided">
									<option></option>
									<option v-for="(item, key) in stateOptions" :value="key" :key="key">{{ item }}</option>
								</select>
								<span id="help_block_billing_state" v-for="(msg, index) in validationErrors['billing_state']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-9 mb-3" :class="hasError('billing_zip')">
								<label for="billing_zip" class="form-label">Zip Code</label>
								<input type="text" id="billing_zip" class="form-control form-control-sm" v-maska="'#####'" v-model.trim="billingZip" :disabled="!billingProvided"  @keyup="updateBillingCountyList">
								<span id="help_block_billing_zip" v-for="(msg, index) in validationErrors['billing_zip']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-9 mb-3" v-show="this.billingCountyOptions.length > 1" :class="hasError('billing_county_id')">
								<label for="billing_county" class="form-label">County</label>
								<select id="billing_county" class="form-select form-select-sm" v-model="billingCountyId" :disabled="!billingProvided || billingCountyOptions.length <= 1">
									<option v-for="item in billingCountyOptions" :value="item.id" :key="item.id">{{ item.description }}</option>
								</select>
								<span id="help_block_billing_county" v-for="(msg, index) in validationErrors['billing_county_id']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3" v-if="spouseBirthDate">
				<h2 class="card-header h4">Spouse</h2>
				<div class="card-body pb-0">
					<div class="row gx-3">
						<div class="col-sm-15 col-lg-12 mb-3" :class="hasError('spouse_first_name')">
							<label for="spouse_first_name" class="required_field form-label">First Name</label>
							<input type="text" id="spouse_first_name" class="form-control form-control-sm" v-model.trim="spouseFirstName" maxlength="30" :class="{ 'is-invalid': 'first_name' in validationErrors }">
							<span id="help_block_spouse_first_name" v-for="(msg, index) in validationErrors['spouse_first_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 col-sm-6 col-lg-6 mb-3" :class="hasError('spouse_middle')">
							<label for="spouse_middle" class="form-label">Initial</label>
							<input type="text" id="spouse_middle" class="form-control form-control-sm" v-model.trim="spouseMiddle" maxlength="1">
						</div>
						<div class="col-sm-15 col-lg-12 mb-3" :class="hasError('spouse_last_name')">
							<label for="spouse_last_name" class="required_field form-label">Last Name</label>
							<input type="text" id="spouse_last_name" class="form-control form-control-sm" v-model.trim="spouseLastName" maxlength="30" :class="{ 'is-invalid': 'last_name' in validationErrors }">
							<span id="help_block_spouse_last_name" v-for="(msg, index) in validationErrors['spouse_last_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-18 col-md-12 mb-3" :class="hasError('spouse_gender')">
							<label for="spouse_gender" class="form-label">Gender</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in genderOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`spouse_gender_${item.value}`" name="spouse_gender" disabled="disabled" v-model="spouseGender" :value="item.value">
								<label class="form-check-label" :for="`spouse_gender_${item.value}`">{{ item.display }}</label>
							</div>
							<span id="help_block_spouse_gender" v-for="(msg, index) in validationErrors['spouse_gender']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 col-md-12 mb-3" :class="hasError('spouse_birth_date')">
							<label for="spouse_birth_date" class="form-label">Birth Date</label>
							<input type="date" id="spouse_birth_date" class="form-control form-control-sm" v-model.trim="spouseBirthDate" disabled="disabled">
							<span id="help_block_spouse_birth_date" v-for="(msg, index) in validationErrors['spouse_birth_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 col-md-12 mb-3" :class="hasError('spouse_tobacco')">
							<label for="spouse_tobacco" class="form-label">Tobacco User?</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`spouse_tobacco_${item.value}`" name="spouse_tobacco" disabled="disabled" v-model="spouseTobacco" :value="item.id">
								<label class="form-check-label" :for="`spouse_tobacco_${item.value}`">{{ item.value }}</label>
							</div>
							<span id="help_block_spouse_tobacco" v-for="(msg, index) in validationErrors['spouse_tobacco']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-36 col-sm-18 mb-3" :class="hasError('spouse_ssn')">
							<label for="spouse_ssn" class="required_field form-label">SSN / Personal Tax ID</label>
							<input type="text" id="spouse_ssn" class="form-control form-control-sm" v-maska="'###-##-####'" v-model.trim="spouseSsn">
							<span id="help_block_spouse_ssn" v-for="(msg, index) in validationErrors['spouse_ssn']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 mb-3" v-if="appContext_carrier(Carriers.BCBSM) || appContext_carrier(Carriers.BCN)" :class="hasError('spouse_us_citizen')">
							<label for="spouse_us_citizen" class="required_field form-label">U.S. Citizen?</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`spouse_us_citizen_${item.value}`" name="spouse_us_citizen" v-model="spouseUsCitizen" :value="item.id">
								<label class="form-check-label" :for="`spouse_us_citizen_${item.value}`">{{ item.value }}</label>
							</div>
							<span id="help_block_spouse_us_citizen" v-for="(msg, index) in validationErrors['spouse_us_citizen']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3" v-for="(dependent, index) in dependents" :key="dependent.id">
				<h2 class="card-header h4">Dependent born {{ dependent.displayBirthDate }}</h2>
				<div class="card-body pb-0">
					<div class="row gx-3">
						<div class="col-sm-15 col-lg-12 mb-3" :class="hasError(`dependent${index}_first_name`)">
							<label :for="`dependent${index}_first_name`" class="required_field form-label">First Name</label>
							<input type="text" :id="`dependent${index}_first_name`" class="form-control form-control-sm" v-model.trim="dependent.firstName" maxlength="30" :class="{ 'is-invalid': 'first_name' in validationErrors }">
							<span :id="`help_block_dependent${index}_first_name`" v-for="(msg, index) in validationErrors[`dependent${index}_first_name`]" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 col-sm-6 col-lg-6 mb-3" :class="hasError(`dependent${index}_middle`)">
							<label :for="`dependent${index}_middle`" class="form-label">Initial</label>
							<input type="text" :id="`dependent${index}_middle`" class="form-control form-control-sm" v-model.trim="dependent.middle" maxlength="1">
						</div>
						<div class="col-sm-15 col-lg-12 mb-3" :class="hasError(`dependent${index}_last_name`)">
							<label :for="`dependent${index}_last_name`" class="required_field form-label">Last Name</label>
							<input type="text" :id="`dependent${index}_last_name`" class="form-control form-control-sm" v-model.trim="dependent.lastName" maxlength="30" :class="{ 'is-invalid': 'last_name' in validationErrors }">
							<span :id="`help_block_dependent${index}_last_name`" v-for="(msg, index) in validationErrors[`dependent${index}_last_name`]" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-18 col-md-12 mb-3" :class="hasError(`dependent${index}_gender`)">
							<label :for="`dependent${index}_gender`" class="form-label">Gender</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in genderOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`dependent${index}_gender_${item.value}`" :name="`dependent${index}_gender`" disabled="disabled" v-model="dependent.gender" :value="item.value">
								<label class="form-check-label" :for="`dependent${index}_gender_${item.value}`">{{ item.display }}</label>
							</div>
						</div>
						<div class="col-18 col-md-12 mb-3" :class="hasError(`dependent${index}_birth_date`)">
							<label :for="`dependent${index}_birth_date`" class="form-label">Birth Date</label>
							<input type="date" :id="`dependent${index}_birth_date`" class="form-control form-control-sm" v-model.trim="dependent.birthDate" disabled="disabled">
						</div>
						<div class="col-18 col-md-12 mb-3" v-if="appContext_carrier(Carriers.BCBSM) || appContext_carrier(Carriers.BCN)" :class="hasError(`dependent${index}_relationship`)">
							<label :for="`dependent${index}_relationship`" class="required_field form-label">Relationship</label>
							<select :id="`dependent${index}_relationship`" class="form-select form-select-sm" v-model="dependent.relationship">
								<option></option>
								<option v-for="item in relationshipOptions" :value="item.id" :key="item.id">{{ item.value }}</option>
							</select>
							<span :id="`help_block_dependent${index}_relationship`" v-for="(msg, index) in validationErrors[`dependent${index}_relationship`]" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
					<div class="row gx-3">
						<div class="col-36 col-sm-18 mb-3" :class="hasError(`dependent${index}_ssn`)">
							<label :for="`dependent${index}_ssn`" class="required_field form-label">SSN / Personal Tax ID</label>
							<input type="text" :id="`dependent${index}_ssn`" class="form-control form-control-sm" v-maska="'###-##-####'" v-model.trim="dependent.ssn">
							<span :id="`help_block_dependent${index}_ssn`" v-for="(msg, index) in validationErrors[`dependent${index}_ssn`]" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<div class="col-18 mb-3" v-if="appContext_carrier(Carriers.BCBSM) || appContext_carrier(Carriers.BCN)" :class="hasError(`dependent${index}_us_citizen`)">
							<label :for="`dependent${index}_us_citizen`" class="required_field form-label">U.S. Citizen?</label>
							<br>
							<div class="form-check form-check-inline" v-for="item in noYesOptions" :key="item.id">
								<input class="form-check-input" type="radio" :id="`dependent${index}_us_citizen_${item.value}`" :name="`dependent${index}_us_citizen`" v-model="dependent.usCitizen" :value="item.id">
								<label class="form-check-label" :for="`dependent${index}_us_citizen_${item.value}`">{{ item.value }}</label>
							</div>
							<span :id="`help_block_dependent${index}_us_citizen`" v-for="(msg, index) in validationErrors[`dependent${index}_us_citizen`]" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-between">
				<router-link :to="{ name: 'review' }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>
				<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg" :disabled="! allowContinue">Continue <i class="fa fa-arrow-right"></i></button>
			</div>
		</div>
	</form>
</template>

<script>

import apiSubInfo from '../api/subinfo'
import displayHelper from '../helpers/displayHelper'
import apiGeography from '@/api/geography'
import RequiredFieldLegend from './snippets/RequiredFieldLegend'
import {useApplicationStore} from "@/stores/application"
import ValidationErrorMsg from './snippets/ValidationErrorMsg'
import appContext from "@/helpers/appContext"
import Carriers from "@/enums/carrier"
import {DateTime} from "luxon"
import Networks from "@/enums/network.js"

export default {
	inject: ['startLoading', 'stopLoading'],
	mixins: [ appContext, Carriers, Networks ],
	components: {
		RequiredFieldLegend,
		ValidationErrorMsg,
	},
	data() {
		return {
			validationErrors: {},
			errorMessage: '',
			allowContinue: true, // used for disabling continue while waiting on county lookup

			// Select options
			noYesOptions: displayHelper.getNoYesArray(),
			titleOptions: displayHelper.getTitleArray(),
			genderOptions: displayHelper.getGenderArray(),
			relationshipOptions: displayHelper.getRelationshipArray(),
			stateOptions: displayHelper.getStateAbbrevSelectOptions(),
			phoneTypeOptions: displayHelper.getPhoneTypeArray(),
			residentialCountyOptions: [], //needs to be filled in after filling in residential zip
			billingCountyOptions: [], //needs to be filled in after filling in billing zip

			// Subscriber
			subTitle: null,
			subFirstName: '',
			subMiddle: '',
			subLastName: '',
			subGender: null,
			subBirthDate: '',
			subTobacco: null,
			subSsn: '',
			subUsCitizen: null,

			// Contact
			email: '',
			primaryPhone: '',
			primaryPhoneTypeId: null,
			alternatePhone: '',
			alternatePhoneTypeId: null,
			address: '',
			city: '',

			// Address
			residentialStreet: '',
			residentialCity: '',
			residentialState: '',
			residentialZip: '',
			residentialCountyId: '',
			billingProvided: false,
			billingStreet: '',
			billingCity: '',
			billingState: '',
			billingZip: '',
			prevBillingZip: '',
			billingCountyId: '',

			// Spouse
			spouseFirstName: '',
			spouseMiddle: '',
			spouseLastName: '',
			spouseGender: null,
			spouseBirthDate: '2000-01-01',
			spouseTobacco: null,
			spouseSsn: '',
			spouseUsCitizen: null,

			// Dependents (fill in on created)
			dependents: [],
		};
	},

	mounted() {
	},

	created() {
		this.startLoading()
		useApplicationStore().init(true).then(async () => { // force initialization to ensure app data is current
			await this.loadForm()
			this.stopLoading()
		})
	},

	methods: {
		async updateForm() {
			this.validationErrors = {};
			this.errorMessage = null;

			this.startLoading();

			let nextPage = 'other'

			try {
				let formData = {
					'application_id': useApplicationStore().applicationId,
					'sub_title': this.subTitle,
					'sub_first_name': this.subFirstName,
					'sub_middle': this.subMiddle,
					'sub_last_name': this.subLastName,
					'sub_ssn': this.subSsn,
					'sub_us_citizen': this.subUsCitizen,
					'email': this.email,
					'primary_phone': this.primaryPhone,
					'primary_phone_type_id': this.primaryPhoneTypeId,
					'alternate_phone': this.alternatePhone,
					'alternate_phone_type_id': this.alternatePhoneTypeId,

					'residential_street': this.residentialStreet,
					'residential_city': this.residentialCity,
					'residential_state': this.residentialState,

					'billing_provided': this.billingProvided,
					'billing_street': this.billingStreet,
					'billing_city': this.billingCity,
					'billing_state': this.billingState,
					'billing_zip': this.billingZip,
					'billing_county_id': this.billingCountyId,

					'spouse_first_name': this.spouseFirstName,
					'spouse_middle': this.spouseMiddle,
					'spouse_last_name': this.spouseLastName,
					'spouse_ssn': this.spouseSsn,
					'spouse_us_citizen': this.spouseUsCitizen,

					'next_page': nextPage
				}

				this.dependents.forEach((dependent, index) => {
					formData['dependent' + index + '_first_name'] = dependent.firstName
					formData['dependent' + index + '_middle_initial'] = dependent.middle
					formData['dependent' + index + '_last_name'] = dependent.lastName
					formData['dependent' + index + '_relationship'] = dependent.relationship
					formData['dependent' + index + '_ssn'] = dependent.ssn
					formData['dependent' + index + '_us_citizen'] = dependent.usCitizen
				})

				await apiSubInfo.update(formData)
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrors = err.response.data.errors
			}

			this.stopLoading()
			if (Object.keys(this.validationErrors).length === 0) {
				// success
				this.$router.push({ name: nextPage })
			}
		},

		submitForm() {
			this.updateForm()
		},

		async loadForm() {
			try {
				const result = (await apiSubInfo.find()).data.data

				this.subTitle = result.subscriber.title
				this.subFirstName = result.subscriber.first_name
				this.subMiddle = result.subscriber.middle_initial
				this.subLastName = result.subscriber.last_name
				this.subGender = result.subscriber.gender
				this.subBirthDate = result.subscriber.birth_date
				this.subTobacco = result.subscriber.tobacco
				this.subSsn = result.subscriber.ssn
				this.subUsCitizen = result.subscriber.us_citizen
				this.email = result.subscriber.email
				this.primaryPhone = result.subscriber.primary_phone
				this.primaryPhoneTypeId = result.subscriber.primary_phone_type_id
				this.alternatePhone = result.subscriber.alternate_phone
				this.alternatePhoneTypeId = result.subscriber.alternate_phone_type_id

				this.residentialStreet = result.subscriber.residential_address.street
				this.residentialCity = result.subscriber.residential_address.city
				this.residentialState = result.subscriber.residential_address.state
				this.residentialZip = result.subscriber.residential_address.zip
				this.residentialCountyId = result.subscriber.residential_address.county_id

				if(result.subscriber.billing_address) {
					this.billingProvided = result.subscriber.billing_provided
					this.billingStreet = result.subscriber.billing_address.street
					this.billingCity = result.subscriber.billing_address.city
					this.billingState = result.subscriber.billing_address.state
					this.billingZip = result.subscriber.billing_address.zip
					this.billingCountyId = result.subscriber.billing_address.county_id
				}

				this.spouseFirstName = result.spouse.first_name
				this.spouseMiddle = result.spouse.middle_initial
				this.spouseLastName = result.spouse.last_name
				this.spouseGender = result.spouse.gender
				this.spouseBirthDate = result.spouse.birth_date
				this.spouseTobacco = result.spouse.tobacco
				this.spouseSsn = result.spouse.ssn
				this.spouseUsCitizen = result.spouse.us_citizen

				result.dependents.forEach((dependent, index) => {
					this.dependents.push(
						{
							id: index,
							firstName: dependent.first_name,
							middle: dependent.middle_initial,
							lastName: dependent.last_name,
							gender: dependent.gender,
							displayBirthDate: DateTime.fromISO(dependent.birth_date).toFormat('L/d/yyyy'),
							birthDate: dependent.birth_date,
							relationship: dependent.relationship,
							ssn: dependent.ssn,
							usCitizen: dependent.us_citizen
						}
					)
				})

				// dynamic options
				this.residentialCountyOptions = (await apiGeography.getCountySelectList(this.residentialZip)).data
				this.billingCountyOptions = (await apiGeography.getCountySelectList(this.billingZip)).data
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
			}
		},

		async updateBillingCountyList() {
			// only update if value changed and ZIP is 5 digits
			if (this.billingZip != this.prevbillingZip && this.billingZip.length === 5) {
				this.allowContinue = false
				this.prevbillingZip = this.billingZip
				this.billingCountyId = null

				this.billingCountyOptions = (await apiGeography.getCountySelectList(this.billingZip)).data
				this.allowContinue = true
				if (this.billingCountyOptions.length === 1) {
					this.billingCountyId = this.billingCountyOptions[0].id
				}
			}
		},


		handleError() {
			this.error = null
		},

		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},

		clearBilling() {
			if (this.billingProvided) {
				this.billingStreet = null;
				this.billingCity = null;
				this.billingState = null;
				this.billingZip = null;
				this.billingCountyId = null;
			}
		},
	},
	computed: {
		isBlueCrossApp() {
			return this.appContext_carrier(this.Carriers.BCBSM) || this.appContext_carrier(this.Carriers.BCN)
		},
		phoneTypeLimit() {
			if (this.appContext_carrier(this.Carriers.BCBSM) || this.appContext_carrier(this.Carriers.BCN)) {
				return this.phoneTypeOptions
			}

			// return only Home and Cell for Priority Health
			// todo: Ideally we would pass the carrier to getPhoneTypesArray() to make it more clear what's going on here
			// ran into issues using carrier constants in the helpers file so this will do for now. Sorry.
			return this.phoneTypeOptions.slice(0, 2);
		},
	},
};
</script>

<style>
</style>

<div class="vld-parent"></div>

<template>
<div class="card vld-parent">
	<loading v-model:active="isLoading" :is-full-page="false"></loading>
	<div class="card-header">
		<h3 class="h5 mb-0">Enrolled Medical Carriers YTD</h3>
	</div>
	<div class="card-body p-3">
		<Pie
			class="w-100 h-100"
			v-if="! isLoading && carrierCounts.length > 0"
			id="provider_graph"
			:options="chartOptions"
			:data="chartData"
		/>
		<div class="text-center" v-else-if="! isLoading">
			<b>Nothing to show yet</b>
		</div>
	</div>
</div>
</template>

<script>
import apiDashboard from "@/api/dashboard";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

export default {
	emits: [],
	props: {
	},
	components: {
		Loading,
		Pie,
	},

	data() {
		return {
			isLoading: true,
			carrierCounts: [],

			chartOptions: {
				responsive: true
			}
		}
	},

	mounted() {
		this.loadData()
	},

	created() {
	},

	computed: {
	},

	methods: {
		async loadData() {
			let options = null

			try {
				const result = (await apiDashboard.getCarrierShare(options)).data

				this.carrierCounts = Object.values(result.carrierCounts)

				let chartData = {
					labels: Object.values(result.carrierNames),
					datasets: [
						{
							data: Object.values(result.carrierCounts),
							backgroundColor: Object.values(result.carrierColors),
						}
					]
				}

				this.chartData = chartData
				this.isLoading = false
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
		}
	}
}
</script>

import { Tooltip } from 'bootstrap'

export const tooltip = {

	mounted(el, binding) {
		// text align left by invoking through v-tooltip:align.left
		// see: https://vuejs.org/guide/reusability/custom-directives.html#hook-arguments

		var alignClass = ''

		if (binding.arg == 'align' && binding.modifiers.left) {
			alignClass = 'text-start'
		}

		new Tooltip(el, {
			template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner ' + alignClass + '"></div></div>',
		})
	}

}

import apiHelper from '@/helpers/apiHelper';

export default {
	getLastStep() {
		return apiHelper.appGet('step');
	},

	getValidStep(stepId) {
		return apiHelper.appGet(`step/${stepId}/validStep`)
	},
};

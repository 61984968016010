<template>
	<form @submit.prevent="submitForm" class="">
		<div class="contents">
			<h1 class="page_title h2">Primary Care Physician (PCP) Selection</h1>

			<validation-error-msg v-if="Object.keys(validationErrors).length !== 0"></validation-error-msg>

			<p>Please select a primary care physician (PCP) for each family member on your plan. If you don&rsquo;t choose a PCP, we will pick one for you and your family members.</p>
			<p>If you don&rsquo;t know your physician&rsquo;s National Provider Identification (NPI) or other information, you can use the
				<a :href="fetchProviderDirectoryLink" target="_blank">provider directory</a>.</p>

			<div class="card mb-3">
				<div class="card-header h4">{{ subFirstName }}&rsquo;s Primary Care Physician
					<div class="dropdown d-inline-block ms-3">
						<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" v-if="hasSpouse || dependents.length > 0" :disabled="enteredPCPs.filter(pcp => pcp.id != 'sub').length == 0">
							Copy from
						</button>

						<ul class="dropdown-menu">
							<li><a class="dropdown-item" v-for="(item, index) in enteredPCPs.filter(pcp => pcp.id != 'sub')" :key="index" @click="setSubPCP(item.pcpFirst, item.pcpLast, item.pcpNPI)">{{ item.name }}</a></li>
						</ul>
					</div>
				</div>
				<div class="card-body">
					<div class="row g-3">
						<!-- Subscriber First Name -->
						<div class="col-sm-18 col-lg-9" :class="hasError('subscriber_pcp_first_name')">
							<label for="subscriber_pcp_first_name" class="form-label">First Name</label>
							<input type="text" id="subscriber_pcp_first_name" class="form-control form-control-sm" maxlength="30" v-model="subPCPFirstName">
							<span id="help_block_subscriber_pcp_first_name" v-for="(msg, index) in validationErrors['subscriber_pcp_first_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<!-- Subscriber Last Name -->
						<div class="col-sm-18 col-lg-9" :class="hasError('subscriber_pcp_last_name')">
							<label for="subscriber_pcp_last_name" class="form-label">Last Name</label>
							<input type="text" id="subscriber_pcp_last_name" class="form-control form-control-sm" maxlength="30" v-model="subPCPLastName">
							<span id="help_block_subscriber_pcp_last_name" v-for="(msg, index) in validationErrors['subscriber_pcp_last_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<!-- Subscriber NPI -->
						<div class="col-sm-18 col-lg-9" :class="hasError('subscriber_pcp_npi')">
							<label for="subscriber_pcp_npi" class="form-label">NPI</label>
							<input type="text" id="subscriber_pcp_npi" class="form-control form-control-sm" v-maska="'##########'" v-model="subPCPNPI">
							<span id="help_block_subscriber_pcp_npi" v-for="(msg, index) in validationErrors['subscriber_pcp_npi']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<!-- Subscriber Last Seen -->
						<div class="col-sm-18 col-lg-9" :class="hasError('subscriber_pcp_seen_in_last_year')">
							<label for="subscriber_pcp_seen" class="form-label">Seen in the last year?</label>
							<select id="subscriber_pcp_seen" class="form-select form-select-sm" value="" v-model="subPCPSeen">
								<option></option>
								<option v-for="item in noYesOptions" :value="item.id" :key="item.id">{{ item.value }}</option>
							</select>
							<span id="help_block_subscriber_pcp_seen" v-for="(msg, index) in validationErrors['subscriber_pcp_seen_in_last_year']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="card mb-3" v-if="hasSpouse">
				<div class="card-header h4">{{ spouseFirstName }}&rsquo;s Primary Care Physician
					<div class="dropdown d-inline-block ms-3">
						<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="enteredPCPs.filter(pcp => pcp.id != 'spouse').length == 0">
							Copy from
						</button>

						<ul class="dropdown-menu">
							<li><a class="dropdown-item" v-for="(item, index) in enteredPCPs.filter(pcp => pcp.id != 'spouse')" :key="index" @click="setSpousePCP(item.pcpFirst, item.pcpLast, item.pcpNPI)">{{ item.name }}</a></li>
						</ul>
					</div>
				</div>
				<div class="card-body">
					<div class="row g-3">
						<!-- Spouse First Name -->
						<div class="col-sm-18 col-lg-9" :class="hasError('spouse_pcp_first_name')">
							<label for="spouse_pcp_first_name" class="form-label">First Name</label>
							<input type="text" id="spouse_pcp_first_name" class="form-control form-control-sm" maxlength="30" v-model="spousePCPFirstName">
							<span id="help_block_spouse_pcp_first_name" v-for="(msg, index) in validationErrors['spouse_pcp_first_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<!-- Spouse Last Name -->
						<div class="col-sm-18 col-lg-9" :class="hasError('spouse_pcp_last_name')">
							<label for="spouse_pcp_last_name" class="form-label">Last Name</label>
							<input type="text" id="spouse_pcp_last_name" class="form-control form-control-sm" maxlength="30" v-model="spousePCPLastName">
							<span id="help_block_spouse_pcp_last_name" v-for="(msg, index) in validationErrors['spouse_pcp_last_name']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<!-- Spouse NPI -->
						<div class="col-sm-18 col-lg-9" :class="hasError('spouse_pcp_npi')">
							<label for="spouse_pcp_npi" class="form-label">NPI</label>
							<input type="text" id="spouse_pcp_npi" class="form-control form-control-sm" maxlength="30" v-maska="'##########'" v-model="spousePCPNPI">
							<span id="help_block_spouse_pcp_npi" v-for="(msg, index) in validationErrors['spouse_pcp_npi']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
						<!-- Spouse Last Seen -->
						<div class="col-sm-18 col-lg-9" :class="hasError('spouse_pcp_seen_in_last_year')">
							<label for="spouse_pcp_seen" class="form-label">Seen in the last year?</label>
							<select id="spouse_pcp_seen" class="form-select form-select-sm" value="" v-model="spousePCPSeen">
								<option></option>
								<option v-for="item in noYesOptions" :value="item.id" :key="item.id">{{ item.value }}</option>
							</select>
							<span id="help_block_spouse_pcp_seen" v-for="(msg, index) in validationErrors['spouse_pcp_seen_in_last_year']" :key="index" class="invalid-feedback">{{ msg }}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- Dependents List -->
			<div class="m-0" id="dependentSection" v-if="dependents.length > 0">
				<div class="card mb-3" v-for="(dep, index) in dependents" :key="index">
					<div class="card-header h4">{{ dep.depFirstName }}&rsquo;s Primary Care Physician
						<div class="dropdown d-inline-block ms-3">
							<button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" :disabled="enteredPCPs.filter(pcp => pcp.id != 'dep' + dep.depId).length == 0">
								Copy from
							</button>

							<ul class="dropdown-menu">
								<li><a class="dropdown-item" v-for="(item, pcpIndex) in enteredPCPs.filter(pcp => pcp.id != 'dep' + dep.depId)" :key="pcpIndex" @click="setDependentPCP(index, item.pcpFirst, item.pcpLast, item.pcpNPI)">{{ item.name }}</a></li>
							</ul>
						</div>
					</div>
					<div class="card-body">
						<div class="row g-3">
							<!-- Dependent First Name -->
							<div class="col-sm-18 col-lg-9" :class="hasError(`dependent_${dep.depId}_pcp_first_name`)">
								<label :for="`dependent_${dep.depId}_pcp_first_name`" class="form-label">First Name</label>
								<input type="text" :id="`dependent_${dep.depId}_pcp_first_name`" class="form-control form-control-sm" maxlength="30"  v-model="dep.depPCPFirstName">
								<span :id="`dependent_${dep.depId}_pcp_first_name`" v-for="(msg, index) in validationErrors[`dependent_${dep.depId}_pcp_first_name`]" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<!-- Dependent Last Name -->
							<div class="col-sm-18 col-lg-9" :class="hasError(`dependent_${dep.depId}_pcp_last_name`)">
								<label :for="`dependent_${dep.depId}_pcp_last_name`" class="form-label">Last Name</label>
								<input type="text" :id="`dependent_${dep.depId}_pcp_last_name`" class="form-control form-control-sm" maxlength="30"  v-model="dep.depPCPLastName">
								<span :id="`dependent_${dep.depId}_pcp_last_name`" v-for="(msg, index) in validationErrors[`dependent_${dep.depId}_pcp_last_name`]" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<!-- Dependent NPI -->
							<div class="col-sm-18 col-lg-9" :class="hasError(`dependent_${dep.depId}_pcp_npi`)">
								<label :for="`dependent_${dep.depId}_pcp_npi`" class="form-label">NPI</label>
								<input type="text" :id="`dependent_${dep.depId}_pcp_npi`" class="form-control form-control-sm" maxlength="30" v-maska="'##########'" v-model="dep.depPCPNPI">
								<span :id="`dependent_${dep.depId}_pcp_npi`" v-for="(msg, index) in validationErrors[`dependent_${dep.depId}_pcp_npi`]" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<!-- Dependent Last Seen -->
							<div class="col-sm-18 col-lg-9" :class="hasError(`dependent_${dep.depId}_pcp_seen_in_last_year`)">
								<label :for="`dependent_${dep.depId}_pcp_seen`" class="form-label">Seen in the last year?</label>
								<select :id="`dependent_${dep.depId}_pcp_seen`" class="form-select form-select-sm" value="" v-model="dep.depPCPSeen">
									<option></option>
									<option v-for="item in noYesOptions" :value="item.id" :key="item.id">{{ item.value }}</option>
								</select>
								<span :id="`dependent_${dep.depId}_pcp_seen`" v-for="(msg, index) in validationErrors[`dependent_${dep.depId}_pcp_seen_in_last_year`]" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="d-flex justify-content-between">
				<router-link :to="{ name: previousPage }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>
				<button type="submit" name="continue_btn" id="continue_btn" class="btn btn-primary btn-lg">Continue <i class="fa fa-arrow-right"></i></button>
			</div>
		</div>
	</form>
</template>

<script>
import apiPcp from "../api/pcp";
import {useApplicationStore} from "@/stores/application";
import displayHelper from '@/helpers/displayHelper'
import Carriers from "@/enums/carrier";
import PlanTypes from "@/enums/plantype";
import appContext from "@/helpers/appContext";
import ValidationErrorMsg from './snippets/ValidationErrorMsg'

export default {
	inject: ['startLoading', 'stopLoading'],
	mixins: [ Carriers, appContext, PlanTypes ],
	components: {
		ValidationErrorMsg,
	},
	data() {
		return {
			validationErrors: {},
			noYesOptions: displayHelper.getNoYesArray(),
			previousPage: 'other',

			subFirstName: "",
			subPCPFirstName: "",
			subPCPLastName: "",
			subPCPNPI: "",
			subPCPSeen: "",

			hasSpouse: false,
			spouseFirstName: "",
			spousePCPFirstName: "",
			spousePCPLastName: "",
			spousePCPNPI: "",
			spousePCPSeen: "",

			dependents: [],

			ddCount: 0,
		};
	},

	created() {
		this.startLoading()
		useApplicationStore().init().then(async () => {
			await this.loadForm()
			this.stopLoading()
		})
	},

	mounted() {
	},

	computed: {
		fetchProviderDirectoryLink() {
			if (this.appContext_carrierPlanType(this.Carriers.BCN, this.PlanTypes.MEDICAL)) {
				return 'https://www.bcbsm.com/index/find-a-doctor.html'
			}

			if (this.appContext_carrierPlanType(this.Carriers.PRIORITY, this.PlanTypes.MEDICAL)) {
				return 'https://web.healthsparq.com/healthsparq/public/#/one/insurerCode=PH_I&brandCode=PH'
			}

			return ''
		},
		showPriorityDentalCoverage() {
			return ! this.appContext_planType(this.PlanTypes.DENTAL) && this.appContext_carrierPlanType(this.Carriers.PRIORITY, this.PlanTypes.MEDICAL)
		},
		isBlueCrossApp() {
			return this.appContext_carrier(this.Carriers.BCBSM) || this.appContext_carrier(this.Carriers.BCN)
		},

		/* build an array of objects containing entered PCP values */
		enteredPCPs() {
			let copyOptions = []

			// just test truthiness of values to cover undefined and empty string

			if (this.subPCPFirstName && this.subPCPLastName && this.subPCPNPI) {
				copyOptions.push({ 'id' : 'sub', 'name' : this.subFirstName, 'pcpFirst' : this.subPCPFirstName, 'pcpLast' : this.subPCPLastName, 'pcpNPI' : this.subPCPNPI })
			}

			if (this.hasSpouse && this.spousePCPFirstName && this.spousePCPLastName && this.spousePCPNPI) {
				copyOptions.push({ 'id' : 'spouse', 'name' : this.spouseFirstName, 'pcpFirst' : this.spousePCPFirstName, 'pcpLast' : this.spousePCPLastName, 'pcpNPI' : this.spousePCPNPI })
			}

			for (const dep of this.dependents) {
				const depId = 'dep' + dep.depId

				if (dep.depPCPFirstName && dep.depPCPLastName && dep.depPCPNPI) {
					copyOptions.push({ 'id' : depId, 'name' : dep.depFirstName, 'pcpFirst' : dep.depPCPFirstName, 'pcpLast' : dep.depPCPLastName, 'pcpNPI' : dep.depPCPNPI })
				}
			}

			return copyOptions
		}
	},

	methods: {
		submitForm() {
			this.updateForm();
		},

		async loadForm() {
			this.startLoading();
			try {
				const result = (await apiPcp.find()).data.data;
				const dependents = result.dependents;

				this.subFirstName = result.subscriber.first_name;
				this.subPCPFirstName = result.subscriber.pcp_first_name;
				this.subPCPLastName = result.subscriber.pcp_last_name;
				this.subPCPNPI = result.subscriber.pcp_npi;
				this.subPCPSeen = result.subscriber.pcp_seen_in_last_year;

				const spouse = result.spouse;
				if (spouse) {
					this.hasSpouse = Object.keys(spouse).length > 0;
					this.spouseFirstName = spouse.first_name;
					this.spousePCPFirstName = spouse.pcp_first_name;
					this.spousePCPLastName = spouse.pcp_last_name;
					this.spousePCPNPI = spouse.pcp_npi;
					this.spousePCPSeen = spouse.pcp_seen_in_last_year;
				}

				if (Object.keys(dependents).length > 0) {
					for (const dep of dependents) {
						this.dependents.push({
							depId: dep.member_id,
							depFirstName: dep.first_name,
							depPCPFirstName: dep.pcp_first_name,
							depPCPLastName: dep.pcp_last_name,
							depPCPNPI: dep.pcp_npi,
							depPCPSeen: dep.pcp_seen_in_last_year,
						});
					}
				}
			} catch (error) {
				this.error = error.message || 'Something went wrong!';
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}
			this.stopLoading();
		},

		async updateForm() {
			this.validationErrors = {};

			this.startLoading();
			try {

				const deps = {};
				this.dependents.forEach((dep) => {
					deps['dependent_' + dep.depId + '_pcp_first_name'] = dep.depPCPFirstName;
					deps['dependent_' + dep.depId + '_pcp_last_name'] = dep.depPCPLastName;
					deps['dependent_' + dep.depId + '_pcp_npi'] = dep.depPCPNPI;
					deps['dependent_' + dep.depId + '_pcp_seen_in_last_year'] = dep.depPCPSeen;
				})

				const data = {
					'application_id': useApplicationStore().applicationId,
					'subscriber_pcp_first_name': this.subPCPFirstName,
					'subscriber_pcp_last_name': this.subPCPLastName,
					'subscriber_pcp_npi': this.subPCPNPI,
					'subscriber_pcp_seen_in_last_year': this.subPCPSeen,

					'spouse_pcp_first_name': this.spousePCPFirstName,
					'spouse_pcp_last_name': this.spousePCPLastName,
					'spouse_pcp_npi': this.spousePCPNPI,
					'spouse_pcp_seen_in_last_year': this.spousePCPSeen,
					...deps,
				}

				await apiPcp.update(data)
			} catch (err) {
				window.scrollTo(0, 0);
				this.validationErrors = err.response.data.errors;
			}
			this.stopLoading();

			if (Object.keys(this.validationErrors).length === 0) {
				// success
				this.$router.push({ name: 'rap' })
			}
		},

		setSubPCP(pcpFirst, pcpLast, pcpNPI)
		{
			this.subPCPFirstName = pcpFirst
			this.subPCPLastName = pcpLast
			this.subPCPNPI = pcpNPI
			this.subPCPSeen = "";
		},

		setSpousePCP(pcpFirst, pcpLast, pcpNPI)
		{
			this.spousePCPFirstName = pcpFirst
			this.spousePCPLastName = pcpLast
			this.spousePCPNPI = pcpNPI
			this.spousePCPSeen = "";
		},

		setDependentPCP(index, pcpFirst, pcpLast, pcpNPI)
		{
			this.dependents[index].depPCPFirstName = pcpFirst
			this.dependents[index].depPCPLastName = pcpLast
			this.dependents[index].depPCPNPI = pcpNPI
			this.dependents[index].depPCPSeen = "";
		},

		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},

	},
};
</script>

<template>
	<div id="share_modal" ref="shareModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="shareModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="shareModalLabel">Share your custom site</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
<!-- todo: Need to be able to switch subdomains if there are multiple
					<select id="subdomain" class="form-select mb-3" v-model="selectedSubdomain" >
						<option disabled value="">Please select one</option>
						<option v-for="(subdomain, index) in allSubdomains" :value="subdomain" :key="index">{{ subdomain }}</option>
					</select>
-->					
					<h4 class="h5 fw-bold">Site Address</h4>
					<div class="input-group mb-3 mt-2">
						<input ref="agentUrlInput" class="form-control" readonly :value="subdomainUrl" />
						<button type="button" class="btn btn-primary" ref="btnCopyUrl" @click="copyUrl" v-tooltip data-bs-toggle="tooltip" data-bs-trigger="click" data-bs-delay='{"show":0,"hide":2000}' title="Link copied to clipboard">
							<i class="d-none d-md-inline far fa-copy"></i> Copy
						</button>
					</div>
					<h4 class="h5 fw-bold">Facebook</h4>
					<a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u=' + subdomainUrl">
						<img src="/img/fb-share.png" class="w-25">
					</a>

				</div>
				<div class="modal-footer">
					<button id="share_modal_close" class="btn btn-outline-secondary float-left" data-bs-dismiss="modal" aria-hidden="true">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import { useAgentInfoStore } from '@/stores/agentInfo'
import { mapState } from 'pinia'

export default {
	props: {
	},
	components: {
	},
	data() {
		return {
			shareModal: null,
			urlClickCount: 0,
		}
	},
	created() {
	},
	mounted() {
		this.shareModal = new Modal(this.$refs.shareModal)
		this.token = this.$route.params.token
	},
	computed: {
		...mapState(useAgentInfoStore, ['allSubdomains']),

		subdomainUrl() {
			// allSubdomains will initially be null until it's set asyncronously in the store
			if (this.allSubdomains) {
				return 'https://' + this.allSubdomains[0] + '.coverageforone.com'
			}
			return ''
		}
	},
	expose: ['show'],
	methods: {
		show() {
			this.shareModal.show()
		},
		copyUrl() {
			//todo: Use the modern way to copy to the clipboard (navigator.clipboard), which requires HTTPS text envs
			this.$refs.agentUrlInput.select()
			document.execCommand('copy')
			document.getSelection().removeAllRanges()

			// the 'delay' functionality of BS tooltips when using the 'click' trigger only applies the show/hide on alternating clicks
			// therefore, to make the message hide we need to click the button twice
			// this handles the second click for the user, and requires a short delay between clicks for it to take effect
			if(this.urlClickCount === 1) {
				this.urlClickCount = 0
			} else {
				this.urlClickCount++
				setTimeout(function () { this.$refs.btnCopyUrl.click()}.bind(this), 100)
			}
		},
	}
}
</script>

<template>
	<h4>Priority Health Medical Plan Terms &amp; Conditions</h4>
	<hr>
	<p>You must provide information in every section in order for us to process your enrollment form.</p>
	<p>My signature below indicates that I have read and understand the contents of this enrollment form. I declare that the answers and information presented on this enrollment are complete and true for all enrollees to the best of my knowledge and belief. I understand that the enrollment form and any amendments become part of the insurance contract and that if any information and answers are incomplete, incorrect or untrue, Priority Health may have the right to rescind (cancel) coverage, adjust premium and/or reduce benefits.</p>
	<p>Enrollment is not guaranteed until eligibility is confirmed, and I understand that I should not cancel any current coverage until I receive written notice of approval from Priority Health.</p>
	<p>I understand that any person who, knowingly and with intent to defraud any insurance company or other person, files an enrollment form for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act when determined by a court of competent jurisdiction, and as such may be subject to criminal and civil penalties.</p>
	<p>I understand the coverage under the plan I am enrolling in will not take effect until issued by Priority Health.</p>
	<p>Priority Health requires proper handling of personal health information for applicants and members, and details of confidentiality policies and procedures are available to me upon my written request to Priority Health.</p>
	<p>I understand that this coverage is not an employer group health plan and is not intended to be an employer-sponsored health insurance plan. I certify that my employer will not contribute any funds toward the cost of this coverage.</p>
	<p>I agree that I, along with any dependents, will accept and receive member material online (via <a href="https://priorityhealth.com" target="_blank">priorityhealth.com</a>).</p>
</template>

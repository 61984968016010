<template>
	<BsSimpleModal :title="moreInfoName || 'Name: n/a'" sizeClass="" id="moreAppInfoModal" ref="moreAppInfoModal">
		<template #body>
			Phone: {{ moreInfoPhone || 'n/a' }}<br>
			Email: {{ moreInfoEmail || 'n/a' }}<br>
			<h3 class="h6 fw-bold mb-1 mt-3">Residential Address</h3>
			<template v-if="moreInfoResidentialStreet">
				{{ moreInfoResidentialStreet }}<br>
				<template v-if="moreInfoResidentialStreet2">{{ moreInfoResidentialStreet2 }}<br></template>
				{{ moreInfoResidentialCity }}, {{ moreInfoResidentialState }} {{ moreInfoResidentialZip }}
			</template>
			<template v-else>
				{{ moreInfoResidentialZip }}
			</template>
			<template v-if="moreInfoBillingProvided">
				<h3 class="h6 fw-bold mb-1 mt-3">Billing Address</h3>
				{{ moreInfoBillingStreet }}<br>
				<template v-if="moreInfoBillingStreet2">{{ moreInfoBillingStreet2 }}<br></template>
				{{ moreInfoBillingCity }}, {{ moreInfoBillingState }} {{ moreInfoBillingZip }}
			</template>
		</template>
	</BsSimpleModal>
</template>

<script>
import BsSimpleModal from '@/components/bootstrap/BsSimpleModal.vue';
import apiDashboard from "@/api/dashboard";
// import Loading from 'vue-loading-overlay';

export default {
	props: {
		appId: {
			required: true
		},
	},
	components: {
		BsSimpleModal,
		// Loading,
	},
	data() {
		return {
			isLoading: null,

			// data for Get Info modal
			moreInfoPhone: null,
			moreInfoName: null,
			moreInfoEmail: null,
			moreInfoResidentialStreet: null,
			moreInfoResidentialStreet2: null,
			moreInfoResidentialCity: null,
			moreInfoResidentialState: null,
			moreInfoResidentialZip: null,
			moreInfoBillingProvided: null,
			moreInfoBillingStreet: null,
			moreInfoBillingStreet2: null,
			moreInfoBillingCity: null,
			moreInfoBillingState: null,
			moreInfoBillingZip: null,
		}
	},
	mounted() {
	},
	expose: [],
	watch: {
		appId() {
			// With this data-driven approach, if we don't reset appId in parent component to null,
			// attempting to load the same ID again will not trigger the watch
			if (this.appId) {
				this.isLoading = true
				this.$refs.moreAppInfoModal.show()
				this.getInfo(this.appId)
				this.isLoading = false
				this.$emit('resetAppId')
			}
		}
	},
	methods: {
		async getInfo(id) {
			let options = null

			const result = (await apiDashboard.getAppInfo(id, options)).data

			this.moreInfoPhone = result.primary_phone
			this.moreInfoName = result.primary_name
			this.moreInfoEmail = result.email

			this.moreInfoResidentialStreet = result.residential_street
			this.moreInfoResidentialStreet2 = result.residential_street2
			this.moreInfoResidentialCity = result.residential_city
			this.moreInfoResidentialState = result.residential_state
			this.moreInfoResidentialZip = result.residential_zip

			this.moreInfoBillingProvided = result.billing_provided
			this.moreInfoBillingStreet = result.billing_street
			this.moreInfoBillingStreet2 = result.billing_street2
			this.moreInfoBillingCity = result.billing_city
			this.moreInfoBillingState = result.billing_state
			this.moreInfoBillingZip = result.billingl_zip
		}
	}
}
</script>

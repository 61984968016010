<template>
	<ul id="progress_nav" class="nav flex-column">
		<li class="nav-item" :class="{ nav_item_active: (this.$route.name == route || isChildRoute(navItem)) }" v-for="(navItem, route) in path" :key="'level1' + route">
			<router-link :to="{ name: route }" class="nav-link" :class="{ disabled: isDisabled(route) }" :aria-disabled="isDisabled(route) ? 'true' : 'false'">{{ navItem.desc }}</router-link>
			<ul class="ms-3 nav flex-column" v-if="isChildRoute(navItem)">
				<li class="nav-item" v-for="(childNavItem, route) in navItem.children" :key="'level2' + route">
					<router-link :to="{ name: route }" class="nav-link" :class="{ disabled: isDisabled(route) }" :aria-disabled="isDisabled(route) ? 'true' : 'false'">{{ childNavItem.desc }}</router-link>
				</li>
			</ul>
		</li>
	</ul>

	<div class="dropdown my-4 text-center" v-if="showPlayground">
		<button class="btn btn-outline-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
			Misc
		</button>
		<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
			<li><router-link to="/playground" class="dropdown-item">Playground</router-link></li>
		</ul>
	</div>
</template>

<script>
import { useStepStore } from '@/stores/step';
import { useApplicationStore } from "@/stores/application"
import { mapStores } from 'pinia';
import stepHelper from '../helpers/stepHelper.js';
import Networks from "@/enums/network.js";

export default {
	mixins: [ Networks ],
	components: {
	},
	data() {
		return {
			routeStepMap: stepHelper.getRouteStepMap(),
			showPlayground: false,
		}
	},
	computed: {
		...mapStores(useStepStore, useApplicationStore), // each store will be accessible as its id + 'Store'

		path() {
			let path = { // todo: reformat this to be an array of objects, so we can use splice to insert PCP below?
				'whyApplying' : {
					'desc' : 'Get Started',
				},
				'census' : {
					'desc' : 'Select & Quote Plans',
					'children' : {
						'census' : {
							'desc' : 'Basic Info',
						},
						'medical' : {
							'desc' : 'Medical',
						},
						'dental' : {
							'desc' : 'Dental',
						},
						'vision' : {
							'desc' : 'Vision',
						},
						'review' : {
							'desc' : 'Review Selections',
						},
					}
				},
				'subInfo' : {
					'desc' : 'Apply for Coverage',
					'children' : {
						'subInfo' : {
							'desc' : 'Subscriber Info',
						},
						'other' : {
							'desc' : 'Other Questions',
						},
						'rap' : {
							'desc' : 'Review and Purchase',
						},
					},
				},
			}

			if (useApplicationStore().medicalNetworkTypeId === this.Networks.HMO) {
				path.subInfo.children = {
					'subInfo' : {
						'desc' : 'Subscriber Info',
					},
					'other' : {
						'desc' : 'Other Questions',
					},
					'pcp' : {
						'desc' : 'PCP Selection',
					},
					'rap' : {
						'desc' : 'Review and Purchase',
					},
				}
			}

			return path
		}
	},
	methods: {
		isChildRoute(navItem) {
			// I wanted to do the following, but failed eslint rule no-prototype-builtins
			// return navItem.hasOwnProperty('children') && navItem['children'].hasOwnProperty(this.$route.name);
			return Object.prototype.hasOwnProperty.call(navItem, 'children') && Object.prototype.hasOwnProperty.call(navItem['children'], this.$route.name)
		},

		isDisabled(route) {
			if(useApplicationStore().skipMedical && route === 'medical'
					|| useApplicationStore().skipDental && route === 'dental'
					|| useApplicationStore().skipVision && route === 'vision'
					|| useApplicationStore().skipOther && route === 'other'
					|| useApplicationStore().skipPcp && route === 'pcp') {
				return true
			}

			return this.stepStore.lastStep < this.routeStepMap[route]
		}
	}
}
</script>


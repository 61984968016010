<template>
	<div class="row">
		<div class="col-18 col-md-12">
			<small>Individual Deductible: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="Carriers.BCBSM == plan.carrier ? ttDedTextIndBCBSM : ttDedTextIndPH"></i></small><h2 class="h5">{{ formatUSDwhole(plan.ded_ind) }}</h2>
			<small>Family Deductible: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" data-bs-html="true" :title="Carriers.BCBSM == plan.carrier ? ttDedTextFamBCBSM : ttDedTextFamPH"></i></small> <h2 class="h5">{{ formatUSDwhole(plan.ded_fam) }}</h2>
		</div>
		<div class="col-18 col-md-12">
			<small>Max Individual Out-of-pocket: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttMaxOopText"></i></small><h2 class="h5">{{ formatUSDwhole(plan.max_ind_oop) }}</h2>
			<small>Max Family Out-of-pocket: <i v-tooltip:align.left class="far fa-question-circle help_question_icon" data-bs-toggle="tooltip" :title="ttMaxOopText"></i></small><h2 class="h5">{{ formatUSDwhole(plan.max_fam_oop) }}</h2>
		</div>
		<hr class="d-block d-md-none my-3">
		<div class="col-36 col-md-12 d-flex flex-column align-items-end">
			<div class="mb-2"><span class="h2">{{ formatUSD(plan.rates.total) }}</span><div class="permo">per<br>month</div></div>
			<template v-if="allowSelect">
				<button @click="$emit('selectEvent')" class="btn btn-primary" v-if="selectedPlanId != plan.id">Select</button>
				<button class="btn btn-outline-primary" disabled="disabled" v-if="selectedPlanId == plan.id">Selected</button>
				<button @click="$emit('selectEvent')" class="mt-2 btn btn-primary" v-if="selectedPlanId == plan.id">Continue</button>
			</template>
			<template v-if="allowChange">
				<router-link :to="{ name: 'dental' }" id="change_plan_dental" class="btn btn-outline-primary">Change Plan</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import generalHelper from '@/helpers/generalHelper'
import Carriers from "@/enums/carrier";

export default {
	mixins: [ Carriers ],
	props: {
		plan: {
			required: true
		},
		allowSelect: {
			required: true
		},
		allowChange: {
			required: true
		},
		selectedPlanId: {
		},
	},
	data() {
		const BCBSMaddon = '<br><br>Class I services have no deductible. This deductible applies only to class II and III services. Please see Plan Details for more information.'
		const ttDedTextInd = 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>An individual deductible applies to each person covered by insurance.'
		const ttDedTextFam = 'A deductible is the amount you pay for covered services before your insurance begins to pay.<br><br>Each family member has an individual deductible that counts toward the family deductible.  If the family’s combined dental expenses meet the family deductible, insurance begins paying for any family member.'

		return {
			ttMaxOopText: 'Includes deductible, coinsurance and copays.',
			ttDedTextIndBCBSM: ttDedTextInd + BCBSMaddon,
			ttDedTextIndPH: ttDedTextInd,
			ttDedTextFamBCBSM: ttDedTextFam + BCBSMaddon,
			ttDedTextFamPH: ttDedTextFam,
		}
	},
	mounted() {
	},
	methods: {
		formatUSD: generalHelper.formatUSD,
		formatUSDwhole: generalHelper.formatUSDwhole,
	}
}
</script>

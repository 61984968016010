<template>
	<div id="login_as_agent_modal" ref="modalElem" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="loginAsAgentModalLabel" aria-hidden="true" data-bs-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content vld-parent">
				<div class="modal-header">
					<loading v-model:active="isLoading" :is-full-page="false"></loading>
					<h3 class="modal-title" id="loginAsAgentModalLabel">Log in as an agent</h3>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<agent-lookup @is-loading="(val) => isLoading = val" @success="modalObj.hide()" />
				</div>
				<div class="modal-footer">
					<button type="button" name="cancel_btn" id="cancel_btn" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import Loading from 'vue-loading-overlay'
import AgentLookup from '@/components/modules/admin/AgentLookup.vue'

export default {
	props: {
	},
	components: {
		AgentLookup,
		Loading,

	},
	data() {
		return {
			modalObj: null,
			isLoading: false,
		}
	},
	mounted() {
		this.modalObj = new Modal(this.$refs.modalElem)
	},
	expose: ['show'],
	methods: {
		show() {
			this.modalObj.show();
		},
	}
}
</script>

<template>
	<form @submit.prevent="submitForm" class="">
		<div class="contents">
			<h1 class="page_title h2">Review and Purchase</h1>

			<validation-error-msg v-if="Object.keys(validationErrors).length !== 0"></validation-error-msg>
			<p class="alert alert-danger mb-3 mt-3" v-show="submitError">{{ submitError }}</p>

			<p>Please review the information below for accuracy. You may go back and make changes if necessary. If all information is correct please complete your purchase by clicking the 'Purchase' button below.</p>

			<div class="card mb-3">
				<h2 class="card-header h4">Applicants</h2>
				<div class="card-body">
					<!-- Subscriber Column Headings -->
					<div class="row pb-1 border-bottom border-dark border-2">
						<div class="col-sm-15">
							<p class="m-0 fw-bold">Primary</p>
						</div>
						<div class="col">
							<p class="m-0 fw-bold">Birth Date</p>
						</div>
						<div class="col">
							<p class="m-0 fw-bold">Gender</p>
						</div>
						<div class="col">
							<p class="m-0 fw-bold">Tobacco</p>
						</div>
					</div>
					<!-- Subscriber Info -->
					<div class="row mb-4 py-1">
						<div class="col-sm-15">
							<p class="m-0 fw-bold">{{ subFirstName }} {{ subLastName }}</p>
						</div>
						<div class="col">
							<p class="m-0 fst-italic">{{ subBirthDate }}</p>
						</div>
						<div class="col">
							<p class="m-0 fst-italic">{{ subGender }}</p>
						</div>
						<div class="col">
							<p class="m-0 fst-italic" v-if="subTobacco === 1">Y</p>
							<p class="m-0 fst-italic" v-else>N</p>
						</div>
					</div>

					<div v-if="spouseID">
						<!-- Spouse Column Headings -->
						<div class="row pb-1 border-bottom border-dark border-2">
							<div class="col-sm-15">
								<p class="m-0 fw-bold">Spouse</p>
							</div>
						</div>
						<!-- Spouse Info -->
						<div class="row mb-4 py-1">
							<div class="col-sm-15">
								<p class="m-0 fw-bold">{{ spouseFirstName }} {{ spouseLastName }}</p>
							</div>
							<div class="col">
								<p class="m-0 fst-italic">{{ spouseBirthDate }}</p>
							</div>
							<div class="col">
								<p class="m-0 fst-italic">{{ spouseGender }}</p>
							</div>
							<div class="col">
								<p class="m-0 fst-italic" v-if="spouseTobacco === 1">Y</p>
								<p class="m-0 fst-italic" v-else>N</p>
							</div>
						</div>
					</div>

					<div v-if="Object.keys(dependents).length != 0">
						<!-- Dependent's Column Headings -->
						<div class="row pb-1 border-bottom border-dark border-2">
							<div class="col-sm-15">
								<p class="m-0 fw-bold">Dependent(s)</p>
							</div>
						</div>
						<!-- Dependent's Info -->
						<div class="row py-1" v-for="(dependent, index) in dependents" :key="index">
							<div class="col-sm-15">
								<p class="m-0 fw-bold" :class="index > 0 ? 'lh-1' : ''">{{ dependent.first_name }} {{ dependent.last_name }}</p>
							</div>
							<div class="col">
								<p class="m-0 fst-italic" :class="index > 0 ? 'lh-1' : ''">{{ displayDepBirthDate[index] }}</p>
							</div>
							<div class="col">
								<p class="m-0 fst-italic" :class="index > 0 ? 'lh-1' : ''">{{ dependent.gender }}</p>
							</div>
							<div class="col" :class="index > 0 ? 'lh-1' : ''">
								<p class="m-0 fst-italic" v-if="dependent.tobacco === 1">Y</p>
								<p class="m-0 fst-italic" v-else>N</p>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="card mb-3" v-if="Object.keys(sub).length != 0">
				<h2 class="card-header h4">Contact and Billing Information</h2>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-18 mb-3">
							<h3 class="h6 fw-bold mb-1">Residential Address</h3>
							{{ sub.residential_address.street }}<br>
							{{ sub.residential_address.city }}, {{ sub.residential_address.state }} {{ sub.residential_address.zip }}
						</div>
						<div class="col-sm-18 mb-3" v-if="sub.billing_provided">
							<h3 class="h6 fw-bold mb-1">Billing Address</h3>
							{{ sub.billing_address.street }}<br>
							{{ sub.billing_address.city }}, {{ sub.billing_address.state }} {{ sub.billing_address.zip }}
						</div>
						<div class="col-sm-18 mb-3" v-else>
							<h3 class="h6 fw-bold mb-1">Billing Address</h3>
							{{ sub.residential_address.street }}<br>
							{{ sub.residential_address.city }}, {{ sub.residential_address.state }} {{ sub.residential_address.zip }}
						</div>
						<div class="col-sm-18 mb-3 mb-md-0">
							<h3 class="h6 fw-bold mb-1">Contact Information</h3>
							{{ sub.email }}<br>
							{{ primaryPhoneType }}:&nbsp;<a :href="'tel:' + subPhoneLink">{{ sub.primary_phone }}</a><br>
							<span v-if="sub.alternate_phone != '' && sub.alternate_phone != null">{{alternatePhoneType }}:&nbsp;<a :href="'tel:' + subAltLink">{{ sub.alternate_phone }}</a></span>
						</div>
						<div class="col-sm-18">
							<h3 class="h6 fw-bold mb-1">Effective Date</h3>
							{{ effectiveDate }}
						</div>
					</div>
				</div>
			</div>

			<h2 class="h3 mt-5">Your Selections</h2>

			<div class="card mt-4" v-if="selectedMedicalPlan">
				<div class="card-header d-flex justify-content-between align-items-center">
					<h3 class="h4 m-0" v-html="selectedMedicalPlan.name"></h3>
				</div>

				<div class="card-body">
					<medical-plan :plan="selectedMedicalPlan" :allowSelect="false" :allowChange="true" />
				</div>
				<plan-footer :plan="selectedMedicalPlan" :showRx="true" />
			</div>
			<div class="card mt-4" v-if="selectedDentalPlan">
				<div class="card-header d-flex justify-content-between align-items-center">
					<h3 class="h4 m-0" v-html="selectedDentalPlan.name"></h3>
				</div>
				<div class="card-body">
					<dental-plan :plan="selectedDentalPlan" :allowSelect="false" :allowChange="true" />
				</div>
				<plan-footer :plan="selectedDentalPlan" :showRx="false" />
			</div>
			<div class="card mt-4" v-if="selectedVisionPlan">
				<div class="card-header d-flex justify-content-between align-items-center">
					<h3 class="h4 m-0" v-html="selectedVisionPlan.name"></h3>
				</div>
				<div class="card-body">
					<vision-plan :plan="selectedVisionPlan" :allowSelect="false" :allowChange="true" />
				</div>
				<plan-footer :plan="selectedVisionPlan" :showRx="false" />
			</div>

			<total-cost class="mt-4" :medicalPlan="selectedMedicalPlan" :dentalPlan="selectedDentalPlan" :visionPlan="selectedVisionPlan" :footer="false" />

			<div class="card mt-4" v-if="enrollmentType === 'qle'">
				<div class="card-body">
					<div class="alert alert-warning">
						If you have not uploaded supporting documentation at this time, or need to submit further documentation, submit it promptly to:
					</div>

					<div class="row g-3" :class="submitDocColumns">
						<div v-if="displayBCBSM">
							<div class="card">
								<h3 class="card-header h5" v-if="displayPriority">Blue Cross Blue Shield of Michigan</h3>
								<div class="card-body">
									<h4 class="h6 fw-bold mb-1">Email</h4>
									<a href="mailto:IBUenrollment@bcbsm.com?subject=QLE Supporting Documents" class="text-decoration-none">IBUenrollment@bcbsm.com</a><br>
									Make sure "QLE Supporting Documents" is in the subject line of your email. Your supporting documentation must include the primary applicant's first name, last name, date of birth, phone number, and application submission date.
									<h4 class="h6 fw-bold mt-3 mb-1">Fax</h4>
									1-877-486-2172<br>
									<h4 class="h6 fw-bold mt-3 mb-1">Mail</h4>
									<address class="mb-0">Individual Membership and Billing<br> Blue Cross Blue Shield of Michigan<br>600 E. Lafayette Blvd., Mail Code 610B<br>Detroit, MI 48226-2998</address>
								</div>
							</div>
						</div>
						<div v-if="displayPriority">
							<div class="card">
								<h3 class="card-header h5" v-if="displayBCBSM">Priority Health</h3>
								<div class="card-body">
									<h4 class="h6 fw-bold mb-1">Email</h4>
									<a href="mailto:mypriority@priorityhealth.com" class="text-decoration-none">mypriority@priorityhealth.com</a>
									<h4 class="h6 fw-bold mt-3 mb-1">Fax</h4>
									1-248-324-2973<br>
									Attention: MyPriority
									<h4 class="h6 fw-bold mt-3 mb-1">Mail</h4>
									<address class="mb-0">Priority Health<br>Individual Operations<br>27777 Franklin Road, Ste. 1300<br>Southfield, MI 48034</address>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card mt-4 mb-3">
				<h3 class="h4 card-header" v-if="spouseID">Signatures</h3>
				<h3 class="h4 card-header" v-else>Signature</h3>
				<div class="card-body">
					<div id="agree_subscriber_copy" class="row mb-4" v-if="whoIsApplying === 1" :class="hasError('agree_subscriber_applying')">
						<div class="col form_check_group">
							<div class="form-check">
								<label class="form-check-label fw-bold" for="agree_subscriber_applying">{{ agreeCopy[whoIsApplying].display }}</label>
								<input id="agree_subscriber_applying" name="agree_subscriber_applying" class="form-check-input" type="checkbox" value="" v-model="agreeSubscriberApplying">
							</div>
						</div>
						<span id="help_block_subscriber_applying" v-for="(msg, index) in validationErrors['agree_subscriber_applying']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>
					<div id="agree_agent_copy" class="row mb-4" v-else-if="whoIsApplying === 2" :class="hasError('agree_agent_applying')">
						<div class="col form_check_group">
							<div class="form-check">
								<label class="form-check-label fw-bold" for="agree_agent_applying">{{ agreeCopy[whoIsApplying].display }}</label>
								<input id="agree_agent_applying" name="agree_agent_applying" class="form-check-input" type="checkbox" value="" v-model="agreeAgentApplying">
							</div>
						</div>
						<span id="help_block_agent_applying" v-for="(msg, index) in validationErrors['agree_agent_applying']" :key="index" class="invalid-feedback">{{ msg }}</span>
					</div>

					<div id="subscriber_signature_group" v-if="whoIsApplying === 1">
						<h4 class="h4">{{ subFirstName }} {{ subLastName }}&rsquo;s Signature</h4>
						<div class="row">
							<div class="col-md-12" :class="hasError('subscriber_signature')">
								<label for="subscriber_signature" class="required_field form-label">Name (as shown above)</label>
								<input type="text" id="subscriber_signature" name="subscriber_signature" class="form-control form-control-sm" v-model="subscriberSignature">
								<span id="help_block_subscriber_signature" v-for="(msg, index) in validationErrors['subscriber_signature']" :key="index" class="invalid-feedback">{{ msg }}</span>

							</div>
							<div class="col-md-12" :class="hasError('subscriber_signature_confirm')">
								<label for="subscriber_signature_confirm" class="required_field form-label">Type name again</label>
								<input type="text" id="subscriber_signature_confirm" name="subscriber_signature_confirm" class="form-control form-control-sm" v-model="subscriberSignatureConfirm">
								<span id="help_block_subscriber_signature_confirm" v-for="(msg, index) in validationErrors['subscriber_signature_confirm']" :key="index" class="invalid-feedback">{{ msg }}</span>

							</div>
							<div class="col-md-12" :class="hasError('subscriber_signature_date')">
								<label for="subscriber_signature_date" class="required_field form-label">Date</label>
								<input type="date" id="subscriber_signature_date" name="subscriber_signature_date" class="form-control form-control-sm" v-model="subscriberSignatureDate">
								<span id="help_block_subscriber_signature_date" v-for="(msg, index) in validationErrors['subscriber_signature_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
					</div>
					<div id="spouse_signature_group" class="mt-4" v-if="spouseID && whoIsApplying === 1">
						<h4 class="h4">{{ spouseFirstName }} {{ spouseLastName }}&rsquo;s Signature</h4>
						<div class="row">
							<div class="col-md-12" :class="hasError('spouse_signature')">
								<label for="spouse_signature" class="required_field form-label">Name (as shown above)</label>
								<input type="text" id="spouse_signature" name="spouse_signature" class="form-control form-control-sm" v-model="spouseSignature">
								<span id="help_block_spouse_signature" v-for="(msg, index) in validationErrors['spouse_signature']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-12" :class="hasError('spouse_signature_confirm')">
								<label for="spouse_signature_confirm" class="required_field form-label">Type name again</label>
								<input type="text" id="spouse_signature_confirm" name="spouse_signature_confirm" class="form-control form-control-sm" v-model="spouseSignatureConfirm">
								<span id="help_block_spouse_signature_confirm" v-for="(msg, index) in validationErrors['spouse_signature_confirm']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-12" :class="hasError('spouse_signature_date')">
								<label for="spouse_signature_date" class="required_field form-label">Date</label>
								<input type="date" id="spouse_signature_date" name="spouse_signature_date" class="form-control form-control-sm" v-model="spouseSignatureDate">
								<span id="help_block_spouse_signature_date" v-for="(msg, index) in validationErrors['spouse_signature_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
					</div>
					<div id="agent_signature_group" v-if="whoIsApplying === 2">
						<h4 class="h4">{{ agentFullName }}'s Signature</h4>
						<div class="row">
							<div class="col-md-12" :class="hasError('agent_signature')">
								<label for="agent_signature" class="required_field form-label">Name (as shown above)</label>
								<input type="text" id="agent_signature" name="agent_signature" class="form-control form-control-sm" v-model="agentSignature">
								<span id="help_block_agent_signature" v-for="(msg, index) in validationErrors['agent_signature']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-12" :class="hasError('agent_signature_confirm')">
								<label for="agent_signature_confirm" class="required_field form-label">Type name again</label>
								<input type="text" id="agent_signature_confirm" name="agent_signature_confirm" class="form-control form-control-sm" v-model="agentSignatureConfirm">
								<span id="help_block_agent_signature_confirm" v-for="(msg, index) in validationErrors['agent_signature_confirm']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
							<div class="col-md-12" :class="hasError('agent_signature_date')">
								<label for="agent_signature_date" class="required_field form-label">Date</label>
								<input type="date" id="agent_signature_date" name="agent_signature_date" class="form-control form-control-sm" v-model="agentSignatureDate">
								<span id="help_block_agent_signature_date" v-for="(msg, index) in validationErrors['agent_signature_date']" :key="index" class="invalid-feedback">{{ msg }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="d-flex justify-content-between align-items-start">
				<router-link :to="{ name: previousPage }" class="btn btn-primary btn-lg"><span class="fas fa-arrow-left"></span> Previous</router-link>

				<div class="d-flex flex-column flex-md-row ms-2">
					<button type="button" name="view_btn" id="view_btn" class="btn btn-primary btn-lg mb-2 mb-md-0" data-bs-toggle="modal" data-bs-target="#tc_modal">View Terms &amp; Conditions</button>
					<button type="submit" name="purchase_btn" id="purchase_btn" class="btn btn-primary btn-lg ms-md-2" :disabled="! agreeTc">Purchase <i class="fa fa-arrow-right"></i></button>
				</div>
			</div>
		</div>
	</form>

	<div id="tc_modal" ref="tsModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="tcModalLabel" aria-hidden="true">
		<div class="modal-lg modal-dialog modal-dialog-scrollable" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="h3 modal-title" id="tcModalLabel">Terms &amp; Conditions</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
						<span class="d-none" aria-hidden="true"><i class="fas fa-times"></i></span>
					</button>
				</div>
				<div class="modal-body">
					<bc-med-dental-vision-tc v-if="appContext_carrier(Carriers.BCBSM) || appContext_carrier(Carriers.BCN)" />
					<ph-med-tc v-if="appContext_carrierPlanType(Carriers.PRIORITY, PlanTypes.MEDICAL)" />
					<ph-dental-vision-tc v-if="appContext_carrierPlanType(Carriers.PRIORITY, PlanTypes.DENTAL) || appContext_carrierPlanType(Carriers.PRIORITY, PlanTypes.VISION)" />
				</div>
				<div class="modal-footer justify-content-between">
					<button id="tc_modal_cancel" class="btn btn-lg btn-secondary" data-bs-dismiss="modal" aria-hidden="true">Cancel</button>
					<button type="button" @click="agreeTc = true" id="tc_modal_agree" class="btn btn-lg btn-primary" data-bs-dismiss="modal" aria-hidden="true">Agree</button>
				</div>
			</div>
		</div>
	</div>

	<div id="purchaseSuccessModal" ref="purchaseSuccessModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="purchaseSuccessModalLabel" aria-hidden="true" data-bs-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="h3 modal-title" id="purchaseSuccessModalLabel">Thank you!</h1>
				</div>
				<div class="modal-body">
					<span v-if="!isAuthenticated">
						Thanks for choosing <b>CoverageForOne&reg;</b>.<br><br>
						Your enrollment application was received and you will receive further information about your coverage soon.<br><br>

						If you have any questions you may always contact your agent.<br>

						<div class="row mt-3">
							<div class="mt-3">
								<strong>{{ agentFullName }}</strong><br>
								<span v-if="agencyName">
									<strong>{{ agencyName }}</strong><br>
								</span>
								<span v-if="agentAddress" v-html="agentAddress"></span>
								<br v-if="agentAddress">
								<span v-if="agentPhone">Phone: <a :href="`tel:${agentPhone}`">{{ agentPhone }}</a><br></span>
								<span v-if="agentFax">Fax: <a :href="`tel:${agentFax}`">{{ agentFax }}</a><br></span>
								<span v-if="agentEmail">Email: <a :href="`mailto:${agentEmail}}`">{{ agentEmail }}</a><br></span>
							</div>
						</div>
					</span>
					<span v-else>
						Your customer’s enrollment application was received and they will receive further information about their coverage soon. If you have any questions you may always contact your account manager or call <a :href="`tel:(833) 754-2163`">(833) 754-2163</a>.
					</span>
				</div>
				<div class="modal-footer">
					<button id="go-home-btn" type="button" class="btn btn-primary btn-lg mx-auto" @click="restartApplication()">Return Home</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiRaP from "../api/rap"
import generalHelper from '../helpers/generalHelper'
import planHelper from '../helpers/planHelper'
import {mapState} from "pinia/dist/pinia"
import { Modal } from 'bootstrap'
import { DateTime } from "luxon"
import { useAgentInfoStore } from "@/stores/agentInfo"
import { useApplicationStore } from "@/stores/application"
import ValidationErrorMsg from './snippets/ValidationErrorMsg'
import BcMedDentalVisionTc from './snippets/BcMedDentalVisionTc'
import PhMedTc from './snippets/PhMedTc'
import PhDentalVisionTc from './snippets/PhDentalVisionTc'
import MedicalPlan from './modules/MedicalPlan'
import DentalPlan from './modules/DentalPlan'
import VisionPlan from './modules/VisionPlan'
import TotalCost from './modules/TotalCost'
import PlanFooter from './modules/PlanFooter'
import Carriers from "@/enums/carrier"
import Networks from "@/enums/network.js"
import PlanTypes from '@/enums/plantype'
import appContext from "@/helpers/appContext"
import apiHelper from '@/helpers/apiHelper'
import displayHelper from "@/helpers/displayHelper"

export default {
	inject: ['startLoading', 'stopLoading'],
	mixins: [ Carriers, Networks, appContext, PlanTypes ],
	components: {
		ValidationErrorMsg,
		MedicalPlan,
		DentalPlan,
		VisionPlan,
		TotalCost,
		PlanFooter,
		BcMedDentalVisionTc,
		PhMedTc,
		PhDentalVisionTc,
	},

	data() {
		return {
			purchaseSuccessModal: null,
			previousPage: 'other',

			phoneTypeOptions: displayHelper.getPhoneTypeArray(),
			primaryPhoneType: "",
			alternatePhoneType: "",

			whoIsApplying: "",
			agreeSubscriberApplying: false,
			agreeAgentApplying: false,
			subscriberSignature: "",
			subscriberSignatureConfirm: "",
			subscriberSignatureDate:"",
			spouseSignature: "",
			spouseSignatureConfirm: "",
			spouseSignatureDate: "",
			agentSignature: "",
			agentSignatureConfirm: "",
			agentSignatureDate: "",

			plans: [],

			agreeTc: false,

			sub: {},
			subPhoneLink: "",
			subAltLink: "",
			dependents: [],
			displayDepBirthDate: [],
			selectedMedicalPlan: null,
			selectedDentalPlan: null,
			selectedVisionPlan: null,
			medicalCarrierId: 0,
			dentalCarrierId: 0,
			visionCarrierId: 0,
			effectiveDate: "",
			enrollmentType: '',
			displayBCBSM: false,
			displayPriority: false,

			validationErrors: {},
			submitError: null,
			subID: "",
			subFirstName: "",
			subLastName: "",
			subBirthDate: "",
			subGender: "",
			subTobacco: "",
			spouseID: "",
			spouseFirstName: "",
			spouseLastName: "",
			spouseBirthDate: "",
			spouseGender: "",
			spouseTobacco: "",

			agreeCopy: displayHelper.getAgreeArray(),
		}
	},

	created() {
		this.startLoading()
		useApplicationStore().init(true).then(async () => {
			await this.loadForm()
			this.stopLoading()
		})
	},

	mounted() {
		this.purchaseSuccessModal = new Modal(this.$refs.purchaseSuccessModal)
	},

	methods: {
		submitForm() {
			this.updateForm()
		},

		hasError(field) {
			return {
				'has-error': this.validationErrors[field]
			}
		},

		async updateForm() {
			this.validationErrors = {}
			this.submitError = null

			if(this.whoIsApplying === '1') {
				this.agreeAgentApplying = false
				this.agentSignature = null
				this.agentSignatureConfirm = null
				this.agentSignatureDate = null
			} else if(this.whoIsApplying === '2') {
				this.agreeSubscriberApplying = false
				this.subscriberSignature = null
				this.subscriberSignatureConfirm = null
				this.subscriberSignatureDate = null
			}

			if(!this.spouseID) {
				this.spouseSignature = null
				this.spouseSignatureConfirm = null
				this.spouseSignatureDate = null
			}

			this.startLoading()
			try {
				let formData = {
					'application_id': useApplicationStore().applicationId,
					'agree_tc': this.agreeTc,
					'who_is_applying': this.whoIsApplying,
					'agree_subscriber_applying': this.agreeSubscriberApplying,
					'agree_agent_applying': this.agreeAgentApplying,
					'subscriber_signature': this.subscriberSignature,
					'subscriber_signature_confirm': this.subscriberSignatureConfirm,
					'subscriber_signature_date': this.subscriberSignatureDate,
					'spouse_signature': this.spouseSignature,
					'spouse_signature_confirm': this.spouseSignatureConfirm,
					'spouse_signature_date': this.spouseSignatureDate,
					'agent_signature': this.agentSignature,
					'agent_signature_confirm': this.agentSignatureConfirm,
					'agent_signature_date': this.agentSignatureDate,
					'agent_full_name': this.agentFullName
				}

				let response = await apiRaP.update(formData)

				if(response.data.submit_error) {
					window.scrollTo(0, 0)
					this.submitError = response.data.submit_error
				}
			} catch (err) {
				window.scrollTo(0, 0)
				this.validationErrors = err.response.data.errors
			}

			this.stopLoading()
			if (Object.keys(this.validationErrors).length === 0 && !this.submitError) {
				this.purchaseSuccessModal.show()
			}
		},

		async loadForm() {
			this.startLoading()
			try {
				const result = (await apiRaP.find()).data.data

				this.sub = result.subscriber
				this.subID = result.subscriber.member_id
				this.subFirstName = result.subscriber.first_name
				this.subLastName = result.subscriber.last_name
				this.subBirthDate = DateTime.fromISO(result.subscriber.birth_date).toFormat('L/d/yyyy')
				this.subGender = result.subscriber.gender
				this.subTobacco = result.subscriber.tobacco
				this.subPhoneLink = generalHelper.formatClickableContactNumber(this.sub.primary_phone)
				this.subAltLink = generalHelper.formatClickableContactNumber(this.sub.alternate_phone)
				this.whoIsApplying = (this.isAuthenticated) ? 2 : 1

				this.primaryPhoneType = generalHelper.getType(this.phoneTypeOptions, result.subscriber.primary_phone_type_id)
				this.alternatePhoneType = generalHelper.getType(this.phoneTypeOptions, result.subscriber.alternate_phone_type_id)

				if(generalHelper.checkApplicationCompletion(result.submission_time)) {
					this.purchaseSuccessModal.show()
				}

				if(result.spouse) {
					this.spouseID = result.spouse.member_id
					this.spouseFirstName = result.spouse.first_name
					this.spouseLastName = result.spouse.last_name
					this.spouseBirthDate = DateTime.fromISO(result.spouse.birth_date).toFormat('L/d/yyyy')
					this.spouseGender = result.spouse.gender
					this.spouseTobacco = result.spouse.tobacco
				}

				this.dependents = result.dependents
				this.dependents.forEach((dependent) => {
					this.displayDepBirthDate.push(DateTime.fromISO(dependent.birth_date).toFormat('L/d/yyyy'))
				})

				this.effectiveDate = DateTime.fromISO(result.effectiveDate).toFormat('L/d/yyyy')
				this.enrollmentType = result.enrollmentType
				this.displayBCBSM = result.displayBCBSM
				this.displayPriority = result.displayPriority

				this.selectedMedicalPlan = result.selectedMedicalPlan
				this.selectedDentalPlan = result.selectedDentalPlan
				this.selectedVisionPlan = result.selectedVisionPlan

				this.medicalCarrierId = result.medicalCarrierId
				this.dentalCarrierId = result.dentalCarrierId
				this.visionCarrierId = result.visionCarrierId

				if(this.selectedMedicalPlan) {
					if(this.selectedMedicalPlan.network === this.Networks.HMO) {
						this.previousPage = 'pcp'
					} else {
						this.previousPage = 'other'
					}
				}
			} catch (error) {
				this.error = error.message || 'Something went wrong!'
				alert("An error occurred, please try again. If it continues, contact your agent.")
			}

			this.stopLoading()
		},
		async restartApplication() {
			await apiHelper.get('application/active', {
				params: {
					'reset': true
				}
			})
			useApplicationStore().init(true)

			this.purchaseSuccessModal.hide()

			if (this.isAuthenticated) {
				this.$router.push({ name: 'dashboard-home' })
			} else {
				this.$router.push({ name: 'root' })
			}
		},

		formatUSD: generalHelper.formatUSD,
		formatUSDwhole: generalHelper.formatUSDwhole,
		//these function need to be called out specifically here in order to access this component's data
		loadPlans: planHelper.loadPlans
	},
	computed: {
		showPriorityDentalCoverage() {
			return ! this.appContext_planType(this.PlanTypes.DENTAL) && this.appContext_carrierPlanType(this.Carriers.PRIORITY, this.PlanTypes.MEDICAL)
		},
		isBlueCrossApp() {
			return this.appContext_carrier(this.Carriers.BCBSM) || this.appContext_carrier(this.Carriers.BCN)
		},
		...mapState(useAgentInfoStore, {
			agentFullName: 'fullName',
			isAuthenticated: 'isAuthenticated',
			agencyName: 'agentcyName',
			agentAddress: 'address',
			agentPhone: 'phone',
			agentFax: 'fax',
			agentEmail: 'email',
		}),
		submitDocColumns() {
			return (this.displayBCBSM && this.displayPriority) ? "row-cols-lg-2" : "row-cols-lg-1"
		},
	}
}
</script>
